<template>

    <v-card variant="outlined" width="100%" :height="computed_display" class="card_class mb-5"
        style="background-color: #fff;">

        <div :class="card_class">
            <div class="card-with-circle">
                <div class="card-header-with-circle">
                    <div class="circle-green">
                        <span class="circle-text"></span>
                    </div>
                    <div class="header-content" @click="openModalApontamentoAbertoHead">
                        <p class="card-title">
                            {{ apontamento.ordem_de_producao.name }} -
                            {{ frappe.format(apontamento.ordem_de_producao.dt_entrega, {
                                "fieldtype": "Date"
                            })
                            }}
                        </p>
                    </div>
                    <div class="header-btn-plus" @click="openModalApontamentoAbertoHead">
                        <input class="btn-plus" type="button" value="+" />
                    </div>
                </div>
                <ModalApontamentoAbertoHead />
                <div class="card-body" @click="openModalApontamentoAbertoBody">
                    <!-- Container de imagens -->
                    <div v-if="display != 'xs'" class="image-container mt-5 pa-0">
                        <img v-if="display != 'xs'"
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhRzlrO-Cuoj3tPndDM_f5JL5nOUVSYu5Qdg&s"
                            alt="Logo NXLite Operador" class="round-image mb-2 pa-0">
                        <img v-if="display != 'xs'" :src="'https://cdn-icons-png.flaticon.com/512/7425/7425445.png'"
                            alt="Icone Base64" class="round-image mb-1 pa-0 ">
                    </div>


                    <v-row class="ma-2 mt-4">
                        <v-col cols="6" sm="12" :class="display == 'xs' ? 'pt-0 pb-1 ' : 'py-1'">
                            <!-- d-none d-sm-flex -->
                            <v-card height="100%" class="border rounded-lg pa-2 d-flex justify-space-between" flat
                                color="#f0f0f0">
                                <v-icon v-if="display == 'xs'">mdi-account-hard-hat</v-icon>
                                <strong v-else>Operador:</strong>
                                <span class="text-truncate"
                                    style="max-width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{
                                    apontamento.operador.nome || " -" }}</span>
                                <!-- <span>{{ apontamento.operador.nome || " -" }}</span> -->
                            </v-card>
                        </v-col>
                        <v-col cols="6" sm="12" :class="display == 'xs' ? 'pt-0 pb-1' : 'py-1'">
                            <v-card height="100%" max-width=""
                                class="border rounded-lg pa-2 d-flex justify-space-between" flat color="#f0f0f0">
                                <v-icon v-if="display == 'xs'" size="20px" class=" pl-0">mdi-cog</v-icon>
                                <strong v-else class="mr-2 ">Recurso:</strong>
                                <span class="text-truncate"
                                    style="display: inline-block; max-width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{
                                    apontamento.recurso.nome || " -" }}</span>
                            </v-card>
                        </v-col>

                        <v-col cols="6" sm="12" v-if="display != 'xs'" class="py-1">
                            <v-card height="100%" class="border rounded-lg pa-2 d-flex justify-space-between" flat
                                color="#f0f0f0">
                                <strong class="mr-2">Apontamento:</strong>
                                <span>{{ apontamento.name || " -" }}</span>
                            </v-card>
                        </v-col>

                        <v-col cols="6" sm="12" :class="display == 'xs' ? 'py-0 mt-0 mb-0' : 'py-1'">
                            <v-card :height="display == 'xs' ? '70%' : '100%'"
                                class="border rounded-lg pa-2 d-flex justify-space-between" flat color="#f0f0f0">
                                <v-icon v-if="display == 'xs'" size="25px" class="mb-3">mdi-numeric</v-icon>
                                <strong v-else class="mr-2">Quantidade:</strong>
                                <span :class="display == 'xs' ? 'mb-2' : ''">{{
                                    apontamento.ordem_de_producao.quantidade || " -" }}</span>
                            </v-card>
                        </v-col>

                        <v-col cols="6" sm="12" :class="display == 'xs' ? 'py-0 mt-0 mb-0' : 'py-1'">
                            <v-card :height="display == 'xs' ? '70%' : '100%'"
                                class="border rounded-lg pa-2 d-flex justify-space-between align-center" flat
                                color="#f0f0f0">
                                <v-icon v-if="display == 'xs'">mdi-clock</v-icon>
                                <strong v-else class="mr-2">Tempo:</strong>
                                <span>{{ (duracao.split(':')[0] > 24 ? `${(duracao.split(':')[0] / 24).toFixed()} dias`
                                    : duracao) || ' -' }}</span>
                            </v-card>
                        </v-col>
                    </v-row>
                    <div v-if="appStore.Modal_Apontamento_Aberto_Body" style="height: 0px;">
                        <ModalApontamentoAbertoBody />
                    </div>
                </div>
            </div>
        </div>

    </v-card>

</template>


<style scoped>
.div-principal {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px;
    width: 278px;

}

.card_class {
    border-radius: 15px;
    margin-bottom: 20px;
    /* margin: 10px; */
    height: 100%;
    min-height: 200px;
    width: 100%;
    border: 3px solid #BEBEBE;
}

.normal_card {
    text-decoration: none;
    color: inherit;
    margin: 0;
    padding: 0;
    border: none;
}

.card-with-circle {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
}

.card-header-with-circle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 9px;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 15px 15px 0 0;
    background-color: #f5f5f5;
}

.circle-green {
    width: 20px;
    height: 20px;
    background-color: #28a745;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.circle-text {
    color: white;
    font-size: 12px;
}

.header-content {
    flex: 1;
    text-align: center;
}

.card-title {
    margin: 0;
    font-weight: bold;
}

.btn-plus {
    color: 6c757d;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 25px;
    cursor: pointer;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    background-color: white;
    margin-top: 5px;
    margin: 0;
    padding: 0;
    border: none;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#card {
    display: flex;
    justify-content: center;
}

.round-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@media (max-width: 767px) {
    /* Ajustes para dispositivos móveis */

    .div-principal {
        flex-direction: column;
        /* Organiza os itens em uma única coluna */
        gap: 10px;
        padding: 10px;
        width: 100%;
        /* Ocupa toda a largura da tela */
    }

    .card_class {
        max-width: 100%;
        /* Ocupa 100% da largura disponível */
        flex: 1 0 auto;
        /* Permite flexibilidade no tamanho */
        /* margin: 10px auto; */
        /* Centraliza o card horizontalmente */
        min-height: auto;
        /* Remove a restrição de altura mínima */
    }

    .card-with-circle {
        flex-direction: flex;
        /* Mantém a organização em coluna */
        width: 100%;
    }

    .card-header-with-circle {
        flex-direction: wrap;
        text-align: center;
        padding: 12px;
    }

    .circle-green {
        width: 20px;
        height: 20px;
        margin: 0 auto;
    }

    .circle-text {
        font-size: 14px;
    }

    .header-content {
        margin-top: 5px;
        text-align: center;
    }

    .btn-plus {
        font-size: 20px;
        width: 25px;
        height: 25px;
    }

    .card-body {

        text-align: center;
        border-radius: 10px;

        width: 100%;
        /* Alinha com a largura do card */
    }

    .image-container {
        display: flex;
        /* Alinha as imagens lado a lado */
        justify-content: center;
        /* Centraliza o conteúdo horizontalmente */
        align-items: center;
        /* Centraliza o conteúdo verticalmente */
        flex-direction: row;
        gap: 10px;
        /* Espaçamento entre as imagens */
        flex-wrap: nowrap;
        /* Impede que as imagens quebrem para outra linha */
        overflow-x: auto;
        /* Permite rolagem horizontal caso o conteúdo ultrapasse o contêiner */
    }

    #card {
        flex-direction: column;
    }

    .round-image {
        width: 60px;
        /* Tamanho maior para melhor visualização */
        height: 60px;
        object-fit: cover;
        /* Garante que a imagem se ajuste sem distorcer */
        border-radius: 50%;
    }
    
}
</style>

<script scoped>
import { useAppStore } from "../../stores/app";
import ModalApontamentoAbertoHead from '../components/modals/modal_apontamento_aberto_head.vue'
import ModalApontamentoAbertoBody from '../components/modals/modal_apontamento_aberto_body.vue'
import { useDisplay } from 'vuetify'

export default {
    name: "appCardApontamentoAberto",
    components: {
        ModalApontamentoAbertoHead,
        ModalApontamentoAbertoBody
    },
    props: {
        apontamento: Object
    },
    data() {
        console.log("Apontamento Aberto: ", this.apontamento)
        const duracao = this.calcularDuracaoDtInicio(this.apontamento.dt_inicio);

        return {
            display: useDisplay().name,
            logoImage: '/images/logo-nxlite-operador.png',
            iconImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAA...',
            interval: setInterval(this.adicionarUmSegundo, 1000),
            duracao,
            image: this.apontamento.ordem_de_producao.croqui_imagem
                || this.apontamento.ordem_de_producao.item ? this.apontamento.ordem_de_producao.item.imagem_produto : null
                || this.apontamento.operador.foto
                || this.apontamento.recurso.imagem || '/assets/nxlite/images/img-operador.png',
            card_class: `${moment(this.apontamento.ordem_de_producao.dt_entrega) > moment() ? 'normal_card' : 'urgente_card'} parent`,
            apontamento: this.apontamento,
            selected_recurso: null,
            selected_apontamento: null,
            selected_operador: null,
            selected_ordem_de_producao: null,
            selected_motivo_de_parada: null,
            isModalOpen: false
        };
    },
    computed: {
        // apontamento: this.apontamento,
        appStore() {
            return useAppStore();
        },

        computed_display() {
            switch (this.display) {
                case 'sm':
                    return '100%'
                case 'xs':
                    return '23vh'
                default:
                    return '95%'
            }
        }
    },
    unmounted() {
        clearInterval(this.interval);
    },
    methods: {
        handleClick() {
            this.appStore.openModalApontamentoAbertoBody();
            this.selected_apontamento = this.apontamento;
        },
        adicionarUmSegundo() {
            let [horas, minutos, segundos] = this.duracao.split(':').map(Number);
            segundos += 1;
            if (segundos === 60) {
                segundos = 0;
                minutos += 1;
            }
            if (minutos === 60) {
                minutos = 0;
                horas += 1;
            }
            const horasFormatadas = horas.toString().padStart(2, '0');
            const minutosFormatados = minutos.toString().padStart(2, '0');
            const segundosFormatados = segundos.toString().padStart(2, '0');
            this.duracao = `${horasFormatadas}:${minutosFormatados}:${segundosFormatados}`;
        },
        calcularDuracaoDtInicio(dataStr) {
            const dataInicial = moment(dataStr, 'YYYY-MM-DD HH:mm:ss');
            const agora = moment();
            const duracao = moment.duration(agora.diff(dataInicial));
            const horas = Math.floor(duracao.asHours()).toString().padStart(2, '0');
            const minutos = duracao.minutes().toString().padStart(2, '0');
            const segundos = duracao.seconds().toString().padStart(2, '0');

            // const days_or_hours = duracao.days() === 0 ? `${horas}:${minutos}:${segundos}` : duracao.days() + " " + "dias"
            return `${horas}:${minutos}:${segundos}`
        },
        async openModalApontamentoAbertoHead() {
            this.appStore.selected_apontamento = this.apontamento;
            this.appStore.openModalApontamentoAbertoHead();
        },
        closeModalApontamentoAbertoHead() {
            this.appStore.selectItem("apontamento", null);
            this.appStore.closeModalApontamentoAbertoHead();
        },
        openModalApontamentoAbertoBody() {
            this.appStore.selectItem("apontamento", this.apontamento);
            this.appStore.openModalApontamentoAbertoBody();
            console.log(this.appStore.selected_apontamento)
        },
        closeModalApontamentoAbertoBody() {
            this.appStore.selectItem("apontamento", null);
            this.appStore.closeModalApontamentoAbertoBody();
        },
        openModalFinalizarOP() {
            this.appStore.openModalFinalizarOP();
        },
        closeModalFinalizarOP() {
            this.appStore.closeModalFinalizarOP();
        },

    },
};
</script>