import {
  Chart,
  LineSeries,
  Category,
  Tooltip,
  Legend,
  DataLabel,
} from "@syncfusion/ej2-charts";
import { registerLicense } from "@syncfusion/ej2-base";
import { Data } from "@syncfusion/ej2-charts/src/common/model/data";

Chart.Inject(LineSeries, Category, Tooltip, Legend, DataLabel);

class BurndownChart {
  constructor({ wrapper, data, title = "Gráfico de Burndown", data_inicial, data2 }) {
    registerLicense(
      "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sdk1iWXtbcndcQmNc"
    );
    console.log("Licença Syncfusion registrada com sucesso.");

    try {
      if (!wrapper) {
        throw new Error("É necessário fornecer um wrapper para inicializar o Gráfico.");
      }
      // test
      this.$wrapper = $(wrapper);

      console.log("data", data)
      console.log("data2", data2)
      let ProgressoInicial = 100
      const serieIdeal = data.map((data) => {
        console.log("Progresso Inicial",ProgressoInicial)
        ProgressoInicial -= data.Esforco
        ProgressoInicial = Math.round(ProgressoInicial * 1e10) / 1e10;
        console.log("Math.abs(ProgressoInicial)",Math.abs(ProgressoInicial))

        console.log("Progresso Inicial Depois", Math.abs(ProgressoInicial));
        const progresso = ProgressoInicial < 0 ? 0 : Math.abs(ProgressoInicial)
        console.log("ProgressoInicial < 0 ? 0 : Math.abs(ProgressoInicial)" ,progresso)
        return {
          Data: data.Data,
          Ideal: parseFloat(progresso).toFixed(2)
        }
      })

console.log("serieIdeal", serieIdeal)


let ProgressoInicialRealizado = 100
const serieRealizado = data2.map((data) => {
  ProgressoInicialRealizado -= data.Esforco
  ProgressoInicialRealizado = Math.round(ProgressoInicialRealizado * 1e10) / 1e10;
  const progresso = Math.abs(ProgressoInicialRealizado) < 0 ? 0 : Math.abs(ProgressoInicialRealizado)
  return {
    Data: data.Data,
    Realizado: parseFloat(progresso).toFixed(2)
  }
})
console.log("serieRealizada", serieRealizado)

console.log("daqui pra cima ta ok temos os datas e os progresso de ideal e realizado")


const bigdata = [...serieIdeal, ...serieRealizado]
console.log("bigdata", bigdata)

const sortedData = bigdata.sort((a, b) => new Date(a.Data) - new Date(b.Data));

sortedData.unshift({ Data: data_inicial, Ideal: 100, Realizado: 100 });

console.log("sortedData big", sortedData)


// vinicius 1304
const result = sortedData.forEach((linha,index) =>{
  if(index > 0){
    const anterior = sortedData[index -1]
    if(linha.Ideal) {
      if(linha.Ideal < anterior.Ideal){
        linha.Ideal = linha.Ideal
      } else {
        linha.Ideal =anterior.Ideal
      }
    } else {
      linha.Ideal = anterior.Ideal
    }

    if(linha.Realizado){
      if(linha.Realizado < anterior.Realizado){
        linha.Realizado = linha.Realizado
      } else {
        linha.Realizado =anterior.Realizado
      }
    } else {
      linha.Realizado = anterior.Realizado
    }
    
  }

})

console.log("Resultvinicius", sortedData)
//


const consolidatedData = sortedData.reduce((acc, current) => {
  const lastItem = acc[acc.length - 1] || { Ideal: 100, Realizado: 100 }; // Valor inicial padrão

  // Garantir que Ideal e Realizado sejam válidos
  const currentIdeal = current.Ideal !== undefined ? parseFloat(current.Ideal) : lastItem.Ideal;
  const currentRealizado = current.Realizado !== undefined ? parseFloat(current.Realizado) : lastItem.Realizado;

  acc.push({
    Data: current.Data,
    Ideal: parseFloat(currentIdeal.toFixed(2)) < parseFloat(lastItem.Ideal.toFixed(2)) ? parseFloat(currentIdeal.toFixed(2)) : parseFloat(lastItem.Ideal.toFixed(2)),
    Realizado: parseFloat(currentRealizado.toFixed(2)),
  });

  return acc;
}, []);

console.log(consolidatedData);

const uniqueDatesData = consolidatedData.reduce((acc, current) => {
  // Verifica se já existe um objeto para a mesma data
  const existing = acc.find(item => item.Data === current.Data);

  if (existing) {
    // Atualiza apenas se o objeto atual for mais próximo de zero
    const isCurrentCloserToZero =
      Math.abs(current.Ideal) + Math.abs(current.Realizado) <
      Math.abs(existing.Ideal) + Math.abs(existing.Realizado);

    if (isCurrentCloserToZero) {
      existing.Ideal = (current.Ideal);
      existing.Realizado = (current.Realizado);
    }
  } else {
    // Adiciona ao acumulador caso não exista
    acc.push({ ...current });
  }

  return acc;
}, []);

console.log("uniqueDatesData", uniqueDatesData)

const finaly= uniqueDatesData.map((item) => {
  return {
    Data: item.Data,
    Ideal: parseFloat(item.Ideal.toFixed(2)),
    Realizado: parseFloat(item.Realizado.toFixed(2))
  }
})

console.log("finally", finaly)

const processedData = consolidatedData.map((item, index, arr) => {
  const previous = index < 1 ? {Ideal: 100, Realizado: 100}: arr[index - 1] // Elemento anterior ou vazio

  console.log("previous do index 0", "index",index, previous)

  return {
    Data: item.Data,
    Ideal: item.Ideal !== undefined ? (item.Ideal) : previous.Ideal,
    Realizado: item.Realizado !== undefined ? item.Realizado : previous.Realizado,
  };
});

console.log("Processed Data:", processedData);


let idealZeroFound = false;

finaly.forEach((item, index) => {
  if (item.Ideal === 0 && !idealZeroFound) {
    idealZeroFound = true;
} else if (idealZeroFound) {
    delete item.Ideal;
}
});

console.log("consolidatedata com remocao",consolidatedData);

console.log("finallyyyyyyyyyy", finaly)


      // Criando o gráfico de Burndown
      const lineGrid = new Chart({
        primaryXAxis: {
          title: "Data de Entrega",
          valueType: "Category",
          interval: 1,
          labelPlacement: "OnTicks",
          
        },
        primaryYAxis: {
          title: "Progresso (%)",
          minimum: 0,
          maximum: 100,
          interval: 20,
        },
        series: [
          {
            dataSource: finaly,
            xName: "Data",
            yName: "Ideal",
            name: "Ideal",
            type: "Line",
            marker: { visible: true },
          },{
            dataSource: finaly,
            xName: "Data",
            yName: "Realizado",
            name: "Realizado",
            type: "Line",
            marker: { visible: true },
          },
        ],
        title,
        tooltip: { enable: true },
        legendSettings: { visible: true },
      });

      // Renderizando o gráfico no elemento
      lineGrid.appendTo(this.$wrapper[0]);
      console.log("Gráfico de Burndown renderizado com sucesso.");
    } catch (e) {
      console.error("Erro ao inicializar o Gráfico de Burndown:", e);
    }
  }
}

frappe.provide("frappe.burndown");
frappe.burndown.BurndownChart = BurndownChart;

export default BurndownChart;
