import { createApp } from "vue";
import { createVuetify } from "vuetify";

import { createPinia } from "pinia";

import piniaPersist from "pinia-plugin-persist";

// import router from '../vue/apontamento-iot/routes/index'

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import App from "../vue/operadores-x-recurso/app.vue";
import { useAppStore } from "../vue/operadores-x-recurso/stores/app";
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(
        "Ngo9BigBOggjHTQxAR8/V1NCaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUX9acH1XR2BZVEZ1Xw=="
      );
var vuetify;
class OperadorXRecurso {
    constructor({ wrapper, app_apontamento }) {
      try {
        this.$wrapper = $(wrapper);
        const app = createApp(App);
        const Lighttheme = {
          dark: false,
          variables: {},
          colors: {
            primary: "#121935",
            secondary: "#397BBE",
            cards: "#EEEEEE",
            bcinputs: "#FFFFFF",
          },
        };
        vuetify = createVuetify({
          components,
          directives,
          theme: {
            themes: {
              light: Lighttheme,
            },
          },
        });
        const pinia = createPinia();
        pinia.use(piniaPersist);
        SetVueGlobals(app);
        app.use(vuetify).use(pinia).mount(this.$wrapper.get(0));
      } catch (e) {
        console.error(e);
      }
    }
  }
  
  
  frappe.provide("frappe.apontamento");
  frappe.apontamento.OperadorXRecurso = OperadorXRecurso;


export default OperadorXRecurso;