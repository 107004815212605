frappe.provide("frappe.nxlite.utils.dialog");

const dialog = (config) => {
	if (!config || (typeof config !== "object" && !Array.isArray(config))) {
		config = {};
	}

	const accepted_types = Object.keys(dialog_types);
	if (!config.type || !accepted_types.includes(config.type)) {
		config.type = "dialog";
	}

	const prompt = dialog_types[config.type](config);
	prompt.show();
	return prompt;
};

const dialog_types = {
	dialog: ({
		title = "Preencha os dados abaixo",
		size = "large",
		fields = [],
		primary_action_label = null,
		primary_action = null,
		secondary_action_label = null,
		secondary_action = null,
		custom_action_label = null,
		custom_action = null,
		message = null,
	}) => {
		const prompt = new frappe.ui.Dialog({
			title,
			size,
			fields,
			primary_action_label,
			primary_action,
			secondary_action_label,
			secondary_action,
		});

		if (message) {
			prompt.$body.append(`<p>${message}</p>`);
		}

		if (custom_action_label && custom_action) {
			prompt.add_custom_action(custom_action_label, () =>
				custom_action(prompt.get_values())
			);
		}

		return prompt;
	},

	dialog_tab_setters: ({
		title = "Selecione os dados",
		size = "extra-large",
		fields = [],
		ignore_column_breaks = false,
		filters = null,
		extra_filters = [],
		table = null,
		doctype = "",
		primary_action_label = "Selecionar",
		primary_action = null,
		secondary_action_label = null,
		secondary_action = null,
		custom_action_label = null,
		custom_action = null,
	}) => {
		// Instruções sobre essa função utils:
		// Para o funcionamento básico é necessário informar os fields do dialog, o doctype que será filtrado/selecionado e a primary action
		// Os dados passados no campo fields precisam ter a propriedade fieldname igual ao fieldname do campo que será usado para filtrar o doctype relacionado

		// Prepara os separadores para organizar os campos no dialog
		const column_break = { fieldtype: "Column Break" };
		const section_break = { fieldtype: "Section Break" };

		// Adiciona separadores de acordo com o número de campos a ser exibido
		let sorted_with_break_columns = [];
		if (!ignore_column_breaks) {
			for (let i = 0; i < fields.length; i += 1) {
				if (fields.length === 1) {
					sorted_with_break_columns = [...fields];
					break;
				}

				if (fields.length === 4) {
					if (i === 1) {
						sorted_with_break_columns.push(fields[i], column_break);
					} else {
						sorted_with_break_columns.push(fields[i]);
					}
					continue;
				}

				if ((i + 1) % 3 === 0 && i !== fields.length - 1) {
					sorted_with_break_columns.push(fields[i], column_break);
				} else {
					sorted_with_break_columns.push(fields[i]);
				}
			}
		} else {
			sorted_with_break_columns = [...fields];
		}

		if (typeof table === "object" && Array.isArray(table)) {
			table = {
				fieldtype: "Table",
				fieldname: "table",
				cannot_add_rows: 1,
				cannot_delete_rows: 1,
				read_only: 1,
				fields: table,
			};
		}

		// Caso não tenha sido passado uma tabela personalizada, cria uma padrão
		if (!table) {
			table = {
				fieldtype: "Table",
				fieldname: "table",
				cannot_add_rows: 1,
				cannot_delete_rows: 1,
				read_only: 1,
				fields: fields.map((f) => ({
					...f,
					in_list_view: 1,
					read_only: 1,
				})),
			};
		}

		// Termina de organizar os fields para a exibição
		sorted_with_break_columns.push(section_break);
		sorted_with_break_columns.push(table);

		console.log("dialog fields:", sorted_with_break_columns);

		// Cria o prompt do dialog
		const prompt = new frappe.ui.Dialog({
			title,
			size,
			fields: sorted_with_break_columns,
			primary_action_label,
			primary_action: async () => {
				console.log("Selecionando...");

				if (primary_action) {
					const selected_rows = prompt.get_value("table").filter((r) => r.__checked);
					await primary_action(selected_rows, prompt.get_values());
				}
			},
		});

		if (custom_action_label && custom_action) {
			prompt.add_custom_action(custom_action_label, () =>
				custom_action(prompt.get_values())
			);
		}

		// Caso não tenha sido passado uma secondary_action_label personalizada, usa a padrão
		if (!secondary_action_label) {
			prompt.set_secondary_action_label("Pesquisar");
		} else {
			prompt.set_secondary_action_label(secondary_action_label);
		}

		const update_table_data = (data) => {
			prompt.fields_dict[table.fieldname].grid.df.data = data;
			prompt.fields_dict[table.fieldname].grid.refresh();
		};

		if (!secondary_action) {
			prompt.set_secondary_action(async () => {
				const fieldnames =
					table.fields && table.fields.length
						? table.fields.map((f) => f.fieldname)
						: ["*"];
				console.log("fieldnames", fieldnames);
				const values = prompt.get_values();
				const formatted_filters = filters
					? filters
					: fields.reduce((acc, curr) => {
							if (values[curr.fieldname]) {
								acc.push([curr.fieldname, "like", `%${values[curr.fieldname]}%`]);
							}
							return acc;
					  }, []);
				const all_filters = extra_filters
					? [...formatted_filters, ...extra_filters]
					: formatted_filters;
				console.log("formatted_filters", formatted_filters);
				const data = await frappe.db.get_list(doctype, {
					fields: fieldnames,
					filters: all_filters,
					limit: null,
				});
				console.log("data", data);
				update_table_data(data);
			});
		} else {
			prompt.set_secondary_action(async () => {
				secondary_action(prompt.get_values(), update_table_data);
			});
		}

		console.log("Prompt", prompt);

		return prompt;
	},

	confirm: ({
		title = "Confirmar",
		message = "Você tem certeza que deseja continuar?",
		confirm_label = "Sim",
		confirm_action = null,
		cancel_label = "Não",
		cancel_action = null,
		size = "small",
	}) => {
		const prompt = new frappe.ui.Dialog({
			title,
			size,
			primary_action_label: confirm_label,
			primary_action: () => {
				if (confirm_action) {
					confirm_action();
				} else {
					console.log("Confirmou!");
					prompt.hide();
				}
			},
			secondary_action_label: cancel_label,
			secondary_action: () => {
				if (cancel_action) {
					cancel_action();
				} else {
					console.log("Cancelou!");
					prompt.hide();
				}
			},
		});
		prompt.$body.append(`<p class="frappe-confirm-message">${message}</p>`);
		return prompt;
	},
};

frappe.nxlite.utils.dialog = dialog;
