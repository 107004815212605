class ParetoChart {
    constructor({ wrapper }) {
        if (!wrapper) {
            throw new Error("Wrapper is required to initialize ParetoChart.");
        }
        this.wrapper = wrapper;
        this.selectedRecurso = null;
        this.selectedCentroCusto = null;
        this.selectedPeriod = null;
        this.startDate = null;
        this.endDate = null;
        this.paretoChart = null;
    }

    async initSyncfusionCharts() {
        const syncfusionScript = document.createElement('script');
        syncfusionScript.src = 'https://cdn.syncfusion.com/ej2/20.2.36/dist/ej2.min.js';
        document.head.appendChild(syncfusionScript);

        const syncfusionStyles = document.createElement('link');
        syncfusionStyles.rel = 'stylesheet';
        syncfusionStyles.href = 'https://cdn.syncfusion.com/ej2/20.2.36/material.css';
        document.head.appendChild(syncfusionStyles);

        syncfusionScript.onload = async () => {
            ej.base.registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXd0dgWX9WdXJXQGFUWEM=');

            const paretoPanelId = 'panel-' + Math.random().toString(36).substr(2, 9);
            const paretoPanelContent = document.createElement('div');
            paretoPanelContent.id = `${paretoPanelId}-content`;

            const chartHeader = document.createElement('div');
            chartHeader.className = 'chart-header';
            chartHeader.innerText = 'PARETO DAS PARADAS';
            this.wrapper.appendChild(chartHeader);

            const filterContainer = document.createElement('div');
            filterContainer.className = 'filter-container';
            filterContainer.innerHTML = `
                <select id="recursoDropdown">
                    <option value="">Selecionar Recurso</option>
                </select>
                <select id="centroCustoDropdown">
                    <option value="">Selecionar Centro de Custo</option>
                </select>
                <select id="period_filter">
                    <option value="1_month">Último Mês</option>
                    <option value="3_months">Últimos 3 Meses</option>
                    <option value="6_months">Últimos 6 Meses</option>
                    <option value="1_year">Último Ano</option>
                    <option value="custom">Período Customizado</option>
                </select>
                <div id="custom_period_controls" style="display: none;">
                    <input type="text" id="date_range" placeholder="Selecione o período">
                </div>
                <button id="applyFiltersButton">
                    <img src="https://img.icons8.com/ios-filled/50/ffffff/search.png" alt="Pesquisar" style="width: 16px; height: 16px;">
                </button>
            `;
            chartHeader.appendChild(filterContainer);

            const style = document.createElement('style');
            style.textContent = `
                select {
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    background-color: #fff;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                    appearance: none;
                    outline: none;
                    cursor: pointer;
                }

                select:focus {
                    border-color: #0078d4;
                    box-shadow: 0 0 3px #0078d4;
                }

                select::-ms-expand {
                    display: none;
                }

                .filter-container {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    gap: 10px;
                    width: calc(100% - 10px);
                }

                .filter-container select, 
                .filter-container button {
                    flex: 0 1 auto;
                }

                .filter-container button {
                    padding: 5px 10px;
                    font-size: 14px;
                    border: none;
                    border-radius: 4px;
                    background-color: #0078d4;
                    color: white;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .filter-container button:hover {
                    background-color: #005a9e;
                }

                #paretoPanel {
                    overflow: hidden;
                }

                #paretoPanel-content {
                    max-width: 100%;
                    height: auto;
                }
            `;
            document.head.appendChild(style);

            this.wrapper.appendChild(paretoPanelContent);

            document.getElementById('period_filter').addEventListener('change', (event) => {
                const customPeriodControls = document.getElementById('custom_period_controls');
                if (event.target.value === 'custom') {
                    customPeriodControls.style.display = 'inline-block';
                } else {
                    customPeriodControls.style.display = 'none';
                }
            });

            // Initialize date range picker
            const dateRangePicker = new ej.calendars.DateRangePicker({
                placeholder: 'Selecione o período',
                format: 'dd/MM/yyyy',
                change: (args) => {
                    this.startDate = args.startDate;
                    this.endDate = args.endDate;
                }
            });
            dateRangePicker.appendTo('#date_range');

            document.getElementById('applyFiltersButton').addEventListener('click', () => {
                this.selectedRecurso = document.getElementById('recursoDropdown').value;
                this.selectedCentroCusto = document.getElementById('centroCustoDropdown').value;
                this.selectedPeriod = document.getElementById('period_filter').value;
                this.updateParetoChart();
            });

            await this.populateDropdowns();
            await this.renderParetoChart(paretoPanelId);

            this.handleResize(paretoPanelId);
            window.addEventListener('resize', () => this.handleResize(paretoPanelId));
        };
    }

    async populateDropdowns() {
        const apontamento = await frappe.call({
            method: "nxlite.nx_producao.page.minha_producao.controller.pareto_parada"
        });

        const recursos = [...new Set(apontamento.message.map(item => item.recurso))];
        const centroCustos = [...new Set(apontamento.message.map(item => item.centro_custo))];

        const recursoDropdown = document.getElementById('recursoDropdown');
        recursos.forEach(recurso => {
            const option = document.createElement('option');
            option.value = recurso;
            option.text = recurso;
            recursoDropdown.appendChild(option);
        });

        const centroCustoDropdown = document.getElementById('centroCustoDropdown');
        centroCustos.forEach(centroCusto => {
            const option = document.createElement('option');
            option.value = centroCusto;
            option.text = centroCusto;
            centroCustoDropdown.appendChild(option);
        });
    }

    handleResize(panelId) {
        if (this.paretoChart) {
            const panelContent = document.getElementById(`${panelId}-content`);
            const parentWidth = panelContent.offsetWidth;

            // Adjust size based on parent
            this.paretoChart.width = `${Math.max(300, parentWidth)}px`;
            this.paretoChart.height = panelContent.offsetHeight + 'px';
            this.paretoChart.refresh();
        }
    }

    async renderParetoChart(panelId) {
        const apontamento = await frappe.call({
            method: "nxlite.nx_producao.page.minha_producao.controller.pareto_parada"
        });
        // console.log('Apontamento', apontamento.message);

        let paretoData = apontamento.message
            .filter(item => item.tipo === 'Parada')
            .filter(item => !this.selectedRecurso || item.recurso === this.selectedRecurso)
            .filter(item => !this.selectedCentroCusto || item.centro_custo === this.selectedCentroCusto)
            .filter(item => {
                const itemDate = new Date(item.dt_inicio);
                return (!this.startDate || itemDate >= this.startDate) && (!this.endDate || itemDate <= this.endDate);
            })
            .map(item => ({
                tipo_parada: item.desc_motivo_parada,
                horas: item.total_hr,
                cumulative_percentage: 0
            }));

        // Sort paretoData in descending order based on total_hr
        paretoData.sort((a, b) => b.horas - a.horas);

        let cumulativeSum = 0;
        const totalHours = paretoData.reduce((sum, item) => sum + item.horas, 0);
        paretoData.forEach(item => {
            cumulativeSum += item.horas;
            item.cumulative_percentage = (cumulativeSum / totalHours) * 100;
        });

        this.paretoChart = new ej.charts.Chart({
            primaryXAxis: { valueType: 'Category', title: '' },
            primaryYAxis: { title: 'Horas' },
            series: [
                {
                    dataSource: paretoData,
                    xName: 'tipo_parada',
                    yName: 'horas',
                    type: 'Column',
                    name: 'Horas'
                },
                {
                    type: 'Line',
                    dataSource: paretoData,
                    xName: 'tipo_parada',
                    yName: 'cumulative_percentage',
                    yAxisName: 'secondary',
                    name: 'Percentual',
                    marker: { visible: true }
                }
            ],
            axes: [
                {
                    name: 'secondary',
                    opposedPosition: true,
                    title: 'Percentual',
                    labelFormat: '{value}%',
                    // maximum: 100
                }
            ],
            tooltip: { enable: true },
            legendSettings: { visible: false, position: 'Bottom'},
            width: '100%',
            height: '100%'
        });

        this.paretoChart.appendTo(`#${panelId}-content`);
    }

    async updateParetoChart() {
        if (this.paretoChart) {
            const apontamento = await frappe.call({
                method: "nxlite.nx_producao.page.minha_producao.controller.pareto_parada",
                args: {
                    period: this.selectedPeriod,
                    start_date: this.startDate,
                    end_date: this.endDate
                }
            });

            let paretoData = apontamento.message
                .filter(item => item.tipo === 'Parada')
                .filter(item => !this.selectedRecurso || item.recurso === this.selectedRecurso)
                .filter(item => !this.selectedCentroCusto || item.centro_custo === this.selectedCentroCusto)
                .filter(item => {
                    const itemDate = new Date(item.dt_inicio);
                    return (!this.startDate || itemDate >= this.startDate) && (!this.endDate || itemDate <= this.endDate);
                })
                .map(item => ({
                    tipo_parada: item.desc_motivo_parada,
                    horas: item.total_hr,
                    cumulative_percentage: 0 // This will be calculated later
                }));

            // Sort paretoData in descending order based on total_hr
            paretoData.sort((a, b) => b.horas - a.horas);

            let cumulativeSum = 0;
            const totalHours = paretoData.reduce((sum, item) => sum + item.horas, 0);
            paretoData.forEach(item => {
                cumulativeSum += item.horas;
                item.cumulative_percentage = (cumulativeSum / totalHours) * 100;
            });

            this.paretoChart.series[0].dataSource = paretoData;
            this.paretoChart.series[1].dataSource = paretoData;
            this.paretoChart.refresh();
        }
    }
}

frappe.provide("frappe.producao");
frappe.producao.ParetoChart = ParetoChart;

export default ParetoChart;
