<template>

  <v-card class="card-recurso" width="100%" @click="selectRecurso(recurso)" :height="display != 'xs' ? '243' : '130'">
    <a class=" normal_card" id="recursos">
      <div class="card-recurso-with-circle">
        <!-- Cabeçalho do card -->
        <div v-if="display != 'xs'" class="card-header-with-circle">
          <!-- Bolinha indicativa (Status) -->
          <div v-if="apontamento && apontamento.tipo == 'Produção'" class="circle-green-xl"></div>
          <div v-else class="circle-red-xl"></div>
          <v-card-title class="card-title-name-recurso text-truncate"
            :style="display === 'sm' ?  'display: inline-block; max-width: 130px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; text-align: center;' : ''">
            {{ recurso.nome }}
          </v-card-title>

        </div>

        <!-- Corpo do card -->
        <div :class="display != 'xs' ? 'recurso-card-body pa-0 mt-3' : ''">
          <div v-if="display != 'xs'" style="display: flex; justify-content: center; align-items: center;">
            <img v-if="display != 'xs'" :height="display === 'xs' ? '70' : '150'"
              :width="display === 'xs' ? '70' : '150'" :class="display == 'xs' ? 'image mr-4' : 'image'"
              :src="recurso.imagem || '/assets/nxlite/images/img-maquina.jpg'" alt="Imagem do recurso">
          </div>
          <v-row class="mt-6">
            <div class="posicionamentoImg ml-5" v-if="display === 'xs'">
              <img :height="display === 'xs' ? '70' : '150'" :width="display === 'xs' ? '70' : '150'" class="image mr-4"
                :src="recurso.imagem || '/assets/nxlite/images/img-maquina.jpg'" alt="Imagem do recurso">
            </div>
            <v-card v-if="display === 'xs'" style="background-color: #f5f5f5; width: 60%;"
              class="rounded-lg mx-auto mt-3" height="10%">
              <div class="d-flex align-center justify-center">
                <div v-if="apontamento && apontamento.tipo == 'Produção'" class="circle-green-xl mr-1 ml-2"></div>
                <div v-else class="circle-red-xl mr-1 ml-2"></div>
                <v-card-text
                  style="font-weight: bold; max-width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                  class="text-truncate text-center text-bold">
                  {{ recurso.nome }}
                </v-card-text>
              </div>
            </v-card>

          </v-row>

        </div>
      </div>

    </a>
  </v-card>

</template>

<style scoped>
/* .div-principal {
  display: flex;
  justify-content: flex-start; 
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  padding-left: 10px; 
  width: 268px;
  height: 243px
  
} */

.card-recurso {
  border-radius: 15px;
  max-width: 450px;
  margin-bottom: 20px;
  border: 3px solid #BEBEBE;
  width: 250px;
  height: 243px
}

.normal_card {
  text-decoration: none;
  color: inherit;
}

.card-recurso-with-circle {
  display: flex;
  flex-direction: column;
  height: auto;
}

.card-header-with-circle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  border-radius: 15px 15px 0 0;
}

.circle-green-xl {
  width: 15px;
  height: 15px;
  background-color: #28a745;
  border-radius: 50%;
}

.circle-red-xl {
  width: 15px;
  height: 15px;
  background-color: #dc3545;
  border-radius: 50%;
}

.card-title-name-recurso {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  flex-grow: 1;
}

.recurso-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  border-radius: 0 0 15px 15px;
}

.image-container-recurso {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
</style>

<script>
import { useAppStore } from "../../stores/app";
import { useDisplay } from 'vuetify'

export default {
  name: "appCardRecurso",
  props: {
    recurso: Object
  },
  data() {
    return {
      selectedImage: null,  // Armazena a imagem selecionada
      apontamento: this.recurso.apontamentos.length !== 0 ? this.recurso.apontamentos[0] : null,
      selectedText: '',
      display: useDisplay().name
    };
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
  methods: {
    selectRecurso(recurso) {
      console.log("Recurso: ", recurso)
      this.appStore.selectItem("recurso", recurso);
      this.appStore.create_apontamento()
      this.selectedImage = recurso.image;
    },
  },

}
</script>