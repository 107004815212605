<template>
  <div class="text-center pa-4">
    <v-dialog class="modal" v-model="appStore.Modal_finalizarOP">
      <v-card class="modal-card" max-width="900">

        <div class="fecharModal">
          <v-icon class="close-btn" @click="closeModalFinalizarOP">mdi-close</v-icon>
        </div>
        <div class="div-title">
          <h3 class="titulo">Finalizar Ordem de Produção</h3>
          <hr class="linha">
        </div>

        <div id="paragrafo" class="modal-body">
          <p>Esta ação vai alterar o estágio da {{ appStore.selected_apontamento.ordem_de_producao.name }} para
            "Finalizado". Todos</p>
          <p>os apontamentos abertos para esta OP também serão fechados.</p>
          <p>Deseja continuar?</p>
        </div>

        <v-card-actions class="modal-actions">
          <v-btn @click="close_ordem_de_producao(appStore.selected_apontamento.ordem_de_producao.name)"
            class="confirm-btn">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>

      <ModalFecharApontamentoAberto />
    </v-dialog>
  </div>
</template>

<style scoped>
.modal {
  width: 95%;
  max-width: 900px;
}

.confirm-btn {
  width: 200px;
  background-color: #2D7B32 !important;
  color: #fff !important;
}

.v-dialog .v-btn.confirm-btn {
  background-color: #2D7B32 !important;
  color: #fff !important;
  width: 200px;
}

.modal-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  min-height: 200px;
  height: auto;
  border-radius: 16px;
  border: 1px solid black;
}

.modal-header {
  text-align: center;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-btn .v-icon {
  font-size: 24px;
  /* Ajustando o tamanho do ícone */
}

.modal-body {
  font-size: 16px;
  text-align: center;
  margin: 15px 0;
  color: #333;
}

.modal-actions {
  display: flex;
  justify-content: center;
  width: 100%;
}

.div-title{
  text-align: center;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
}

.titulo {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
}

.linha {
  width: 100%;
  margin: 15px 0;
  border-top: 2px solid #ccc;
}

button:focus {
  outline: none;
  /* Remove o contorno */
  background-color: initial;
  /* Restaura a cor de fundo padrão */
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .modal {
    width: 95%;
    max-width: 100%;
  }

  .confirm-btn {
  width: auto; /* Ajusta a largura para o tamanho do conteúdo */
  padding: 8px 16px; /* Reduz o padding horizontal e vertical */
  font-size: 14px; /* Diminui o tamanho da fonte */
}

  .modal-card {
    padding: 15px;
    border-radius: 10px;
  }

  .modal-header {
    font-size: 16px;
  }

  .modal-body {
    font-size: 14px;
  }

  .titulo {
    font-size: 1.5rem;
  }

  .linha {
    margin: 10px 0;
  }
}
</style>

<script>
import { useAppStore } from '../../../stores/app';
import ModalFecharApontamentoAberto from './modal_fechar_apontamento_aberto.vue'

export default {
  name: "ModalFinalizarOP",
  computed: {
    appStore() {
      return useAppStore();
    },
  },
  methods: {
    openModalFinalizarOP() {
      this.appStore.openModalFinalizarOP();
    },
    closeModalFinalizarOP() {
      this.appStore.closeModalFinalizarOP();
    },
    openModalFecharApontamentoAberto() {
      this.appStore.openModalFecharApontamentoAberto();
    },
    // closeModalFecharApontamentoAberto() {
    //   this.Modal_fechar_apontamento_aberto = false;

    // },
    async close_ordem_de_producao(name) {
      await this.appStore.close_ordem_de_producao(name).then(data => {
        this.closeModalFinalizarOP()
        this.appStore.closeModalApontamentoAbertoBody()

      })
    },
    toggleModals(selecionado) {
      this.appStore.toggleModals(selecionado);
    }
  },
};
</script>