<template>
    <div>
        <v-app>
            <v-main>
                <stacked_column/>
            </v-main>
        </v-app>
    </div>
</template>

<script>
import stacked_column from './app/components/stacked_column.vue'
export default {
    components: {
        stacked_column
    },
    name: 'meuFaturamentoApp',
    data() {
    
        return {
            count: 0
        }
    }
    
}
</script>
<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap5.css";
@import '../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
@import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/bootstrap5.css";
@import '../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/bootstrap5.css';
@import "../../node_modules/@syncfusion/ej2-vue-pivotview/styles/bootstrap5.css";
</style>