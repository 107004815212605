frappe.provide('frappe.nxlite.utils');


const utils = {
    /**
     * Retorna uma string contendo apenas os números da string recebida por parâmetro.
     * @param {string} str - A string a ser processada.
     * @returns {string} - A string contendo apenas os números.
     */
    gantt_project: async (data) => {
    },
    get_string_numbers: (str = '') => str.replace(/[^\d]/g, ''),

    create_print_button: (frm = cur_frm, callback) => {
        // console.log('Executando a função create_print_button');
        // console.log('A função foi chamada com os parâmetros:');
        // console.log('frm', frm);
        // console.log('callback', callback);

        // Esconde os botões de print padrão do frappe
        frm.$wrapper.find('button[data-original-title="Print"]').hide();
        frm.$wrapper.find('button[data-original-title="Impressão"]').hide();
        frm.$wrapper.find('[data-label="Print"]').parent().hide();
        frm.$wrapper.find('[data-label="Impressão"]').parent().hide();

        // Cria o botão de print customizado
        const printer_svg = `<svg class="icon icon-sm" style aria-hidden="true"><use class href="#icon-printer"></use></svg>`;
        frm.add_custom_button(printer_svg, async () => {
            console.log('Clicou no Botão');
            utils.throw_error_if_unsaved();

            if (callback) {
                const res = await callback();
                console.log('A callback retornou', res);
                if (!res) {
                    return;
                }
            }

            const { doctype, docname } = frm;

            let endpoint = frappe.router.make_url([
                'print-nx',
                doctype,
                docname,
            ]);

            const formats = await frappe.db.get_list('Print Format', {
                fields: ['name', 'doc_type', 'standard'],
                filters: [
                    ['doc_type', '=', doctype],
                    ['standard', '=', 'Yes'],
                ],
            });
            if (formats.length) {
                const query_params = `?print-format=${formats[0].name
                    .split(' ')
                    .join('-')}`;
                endpoint += query_params;
            }

            console.log('Endpoint a ser redirecionado:', endpoint);

            window.open(endpoint, '_blank');
        });
    },

	fetch_impostos_from_natop: async (natop) => {
		let response = await frappe.db.get_value("Natureza da Operacao", natop, [
			"aliquota_issqn",
			"reter_issqn",
			"percentual_retencao_issqn",

			"aliquota_irpj_servicos",
			"reter_irpj",
			"percentual_retencao_irpj",

			"aliquota_csll_servicos",
			"reter_csll",
			"percentual_retencao_csll",

			"aliquota_pis",
			"reter_pis",
			"percentual_retencao_pis",

			"aliquota_cofins",
			"reter_cofins",
			"percentual_retencao_cofins",

			"aliquota_inss",
			"reter_inss",
			"percentual_retencao_inss"
		]);

		if (!response.message) {
			console.error("Falha ao importar valores da Natureza de Operação. Erro: " + response.status)

			return;
		}

		let imposto = response.message;

		let mapa = {
			"percentual_retencao_issqn": "percentual_retencao_iss",
			"aliquota_issqn": "aliquota",
			"reter_issqn": "iss_retido",

			"aliquota_irpj_servicos" : "aliquota_ir",

			"aliquota_csll_servicos" : "aliquota_csll"
		}

		for (let [key, value] of Object.entries(imposto)) {

			let mapped = Object.keys(mapa).includes(key) ? mapa[key] : key;
			imposto[mapped] = value;
		}

		return imposto;
	},

    extract_number: (str, type = 'number') => {
        const number = str.split('-').shift().trim();
        return type === 'number' ? Number(number) : number;
    },

    throw_error_if_unsaved: (frm = cur_frm) => {
        if (frm.is_dirty()) {
            throw frappe.throw({
                title: 'Atenção',
                message: 'O documento possui dados não salvos.',
                indicator: 'red',
            });
        }
    },

    /**
     * Função para exibir uma mensagem de toast ou lançar um erro se o item estiver inativo.
     *
     * @param {Object|Object[]} data - Pode ser um objeto ou um array de objetos com a seguinte estrutura:
     * @param {Number} [data.idx] - Índice do item.
     * @param {String} data.item - Nome do item.
     * @param {String} [param2] - Caso o parâmetro 'data' seja um objeto, deve ser um dos seguintes valores:
     *                            'throw' | 'toast'.
     *                            Caso o parâmetro 'data' seja um array de objetos, deve ser o nome da tabela que contém os itens ou undefined.
     *
     * @returns {Promise<void>}
     */
    toast_or_throw_if_item_inativo: async (data, param2) => {
        if (typeof data === 'object' && !Array.isArray(data)) {
            if (data.item) {
                const { message } = await frappe.db.get_value(
                    'Item',
                    data.item,
                    ['status', 'codigo', 'descricao'],
                );
                if (message.status === 'Inativo') {
                    if (!param2 || param2 === 'toast') {
                        return frappe.toast({
                            message: `O Item${
                                data.idx ? ' ' + data.idx : ''
                            }: ${message.codigo} - ${
                                message.descricao
                            } está inativo.`,
                            indicator: 'yellow',
                        });
                    } else if (param2 === 'throw') {
                        return frappe.throw({
                            title: 'Atenção',
                            message: `O Item: ${message.codigo} - ${message.descricao} está inativo.`,
                            indicator: 'red',
                        });
                    }
                }
            }
        } else if (typeof data === 'object' && Array.isArray(data)) {
            const itens_inativos = [];

            if (data.length) {
                for (const row of data) {
                    if (row.item) {
                        const item_doc = await frappe.db.get_doc(
                            'Item',
                            row.item,
                        );
                        if (item_doc.status === 'Inativo') {
                            const item_data = {
                                idx: row.idx,
                                descricao: item_doc.descricao,
                                codigo: item_doc.codigo,
                            };
                            if (!itens_inativos.includes(item_data)) {
                                itens_inativos.push(item_data);
                            }
                        }
                    }
                }
            }

            if (itens_inativos.length) {
                return frappe.throw({
                    title: 'Atenção',
                    message: `${
                        itens_inativos.length > 1
                            ? `Os seguintes itens${
                                  param2 ? ` da tabela ${param2}` : ''
                              } estão inativos:`
                            : `O seguinte item${
                                  param2 ? ` da tabela ${param2}` : ''
                              } está inativo:`
                    }<br/><br/>${itens_inativos
                        .map(
                            (i) =>
                                `Item ${i.idx}: ${i.codigo} - ${i.descricao}`,
                        )
                        .join('<br/>')}`,
                });
            }
        }
    },

	get_parcelas_by_condicao_e_data: async (condicao_de_pagamento, data_base_para_pagamento) => {
		let tipo_conta =
			cur_frm.doctype === 'Ordem de Compra' ||
			cur_frm.doctype === 'Recebimento'
				? 'Pagar'
				: 'Receber';

		const condicao_de_pagamento_doc = await frappe.db.get_doc(
			'Condicao de Pagamento',
			condicao_de_pagamento,
		);

		const parcela_condicao =
			condicao_de_pagamento_doc &&
			condicao_de_pagamento_doc.parcela_condicao
				? condicao_de_pagamento_doc.parcela_condicao
				: null;

		if (!parcela_condicao) {
			return null;
		}

		let date;

		if(data_base_para_pagamento) {
			date = data_base_para_pagamento;
		} else {
			date = frappe.datetime.nowdate();
		}

		const parcelas = parcela_condicao.map((p) => ({
			data_de_lancamento: date,
			data_de_vencimento: date,
			percentual: p.percentual,
			dias: p.dias,
			tipo_de_lancamento: tipo_conta,
			meio_de_pagamento: p.meio_de_pagamento,
		}));

		// console.log('to em parcelas de utils', parcelas);

		return parcelas;
	},

    get_parcelas_by_condicao_de_pagamento: async (condicao_de_pagamento) => {
        let tipo_conta =
            cur_frm.doctype === 'Ordem de Compra' ||
            cur_frm.doctype === 'Recebimento'
                ? 'Pagar'
                : 'Receber';

        const condicao_de_pagamento_doc = await frappe.db.get_doc(
            'Condicao de Pagamento',
            condicao_de_pagamento,
        );

        const parcela_condicao =
            condicao_de_pagamento_doc &&
            condicao_de_pagamento_doc.parcela_condicao
                ? condicao_de_pagamento_doc.parcela_condicao
                : null;

        if (!parcela_condicao) {
            return null;
        }

        const today = frappe.datetime.nowdate();

        const parcelas = parcela_condicao.map((p) => ({
            data_de_lancamento: today,
            data_de_vencimento: today,
            percentual: p.percentual,
            dias: p.dias,
            tipo_de_lancamento: tipo_conta,
            meio_de_pagamento: p.meio_de_pagamento,
        }));

        // console.log('to em parcelas de utils', parcelas);

        return parcelas;
    },

    condicao_de_pagamento: async (
        condicao_de_pagamento,
        data_prevista_de_entrega,
        parcela,
    ) => {

        if(!data_prevista_de_entrega || !parcela){
            return ""
        }

        let vencimento_obj = frappe.datetime.str_to_obj(
            frappe.datetime.add_days(data_prevista_de_entrega, parcela.dias),
        );

        if (!condicao_de_pagamento) {
            return frappe.datetime.obj_to_str(vencimento_obj);
        }

        const condicao_de_pagamento_doc = await frappe.db.get_doc(
            'Condicao de Pagamento',
            condicao_de_pagamento,
        );

        const vencimento_date = vencimento_obj.getDate();
        const vencimento_month = vencimento_obj.getMonth() + 1; // (janeiro = 0)
        const vencimento_year = vencimento_obj.getFullYear();

        if (condicao_de_pagamento_doc.chk_data_fixa == 1) {
            const data_fixa_list = condicao_de_pagamento_doc.data_fixa
                .map((date) => date.data_fixa)
                .sort((a, b) => a - b);
            const nearest_data_vencimento = data_fixa_list.find(
                (date) => date >= vencimento_date,
            );

            if (nearest_data_vencimento) {
                vencimento_obj = frappe.datetime.str_to_obj(
                    `${vencimento_year}-${vencimento_month}-${nearest_data_vencimento}`,
                );
            } else {
                const first_date = data_fixa_list[0];
                const next_month =
                    vencimento_month == 12 ? 1 : vencimento_month + 1;
                const year =
                    vencimento_month == 12
                        ? vencimento_year + 1
                        : vencimento_year;
                vencimento_obj = frappe.datetime.str_to_obj(
                    `${year}-${next_month}-${first_date}`,
                );
            }

            const day = vencimento_obj.getDay();
            const is_weekend = [0, 6].includes(day);
            if (is_weekend) {
                const antecipar_or_postergar =
                    condicao_de_pagamento_doc.o_que_fazer_no_vencimento_aos_fins_de_semana_e_feriados;
                let days_to_move = 0;
                if (antecipar_or_postergar === 'Antecipar') {
                    days_to_move = day === 6 ? -1 : -2;
                } else if (antecipar_or_postergar === 'Postergar') {
                    days_to_move = day === 6 ? 2 : 1;
                }
                vencimento_obj = frappe.datetime.str_to_obj(
                    frappe.datetime.add_days(vencimento_obj, days_to_move),
                );
            }
        } else if (condicao_de_pagamento_doc.chk_dia_da_semana == 1) {
            const sorted_dia_da_semana_array =
                condicao_de_pagamento_doc.dia_da_semana
                    .map((dia) => {
                        if (dia.dia_da_semana == 'Domingo') {
                            return 0;
                        }
                        if (dia.dia_da_semana == 'Segunda') {
                            return 1;
                        }
                        if (dia.dia_da_semana == 'Terca') {
                            return 2;
                        }
                        if (dia.dia_da_semana == 'Quarta') {
                            return 3;
                        }
                        if (dia.dia_da_semana == 'Quinta') {
                            return 4;
                        }
                        if (dia.dia_da_semana == 'Sexta') {
                            return 5;
                        }
                        if (dia.dia_da_semana == 'Sabado') {
                            return 6;
                        }
                    })
                    .sort((a, b) => a - b);

            const vencimento_day = vencimento_obj.getDay();
            const dia_da_semana = sorted_dia_da_semana_array.find((dia) => {
                return dia >= vencimento_day;
            });

            if (dia_da_semana) {
                const number_of_days = dia_da_semana - vencimento_day;
                vencimento_obj = frappe.datetime.str_to_obj(
                    frappe.datetime.add_days(vencimento_obj, number_of_days),
                );
            } else {
                const first_day = sorted_dia_da_semana_array[0];
                const days_number = 7 - (vencimento_day - first_day);
                vencimento_obj = frappe.datetime.str_to_obj(
                    frappe.datetime.add_days(vencimento_obj, days_number),
                );
            }
        } else {
            const day = vencimento_obj.getDay();
            const is_weekend = [0, 6].includes(day);
            if (is_weekend) {
                const antecipar_or_postergar =
                    condicao_de_pagamento_doc.o_que_fazer_no_vencimento_aos_fins_de_semana_e_feriados;
                let days_to_move = 0;
                if (antecipar_or_postergar === 'Antecipar') {
                    days_to_move = day === 6 ? -1 : -2;
                } else if (antecipar_or_postergar === 'Postergar') {
                    days_to_move = day === 6 ? 2 : 1;
                }
                vencimento_obj = frappe.datetime.str_to_obj(
                    frappe.datetime.add_days(vencimento_obj, days_to_move),
                );
            }
        }

        return frappe.datetime.obj_to_str(vencimento_obj);
    },

    validate: {
        cnpj: (cnpj = '') => {
            const cnpj_numbers = utils.get_string_numbers(cnpj);
            if (cnpj_numbers === '') {
                return {
                    status: 400,
                    code: 101,
                    message: 'Necessário digitar CNPJ!',
                    cnpj: cnpj,
                };
            }

            if (cnpj_numbers.length !== 14) {
                return {
                    status: 400,
                    code: 102,
                    message: 'O CNPJ deve conter 14 dígitos!',
                    cnpj: cnpj,
                };
            }

            const regex = /^(\d)\1+$/; // Regex para verificar se há apenas dígitos repetidos
            if (
                regex.test(cnpj_numbers) ||
                !utils.validate.cnpj_dv(cnpj_numbers)
            ) {
                return {
                    status: 400,
                    code: 103,
                    message: 'CNPJ inválido!',
                    cnpj: cnpj,
                };
            }

            return {
                status: 200,
                code: 0,
                message: 'CNPJ válido!',
                cnpj: cnpj,
            };
        },
        cnpj_dv: (cnpj = '') => {
            cnpj = utils.get_string_numbers(cnpj);
            const size = cnpj.length - 2;
            const nums = cnpj.substring(0, size);
            const digits = cnpj.substring(size);

            // Mudança começa aqui
            // Verificação do primeiro dígito verificador
            let sum = 0;
            let pos = size - 7;
            for (let i = size; i >= 1; i--) {
                sum += parseInt(nums.charAt(size - i)) * pos--;
                if (pos < 2) pos = 9;
            }

            let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (result != parseInt(digits.charAt(0))) {
                return false;
            }

            // Verificação do segundo dígito verificador
            sum = 0;
            pos = size - 6;
            for (let i = size + 1; i >= 1; i--) {
                sum += parseInt(cnpj.charAt(size + 1 - i)) * pos--;
                if (pos < 2) pos = 9;
            }

            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (result != parseInt(digits.charAt(1))) {
                return false;
            }
            // Mudança termina aqui

            return true;
        },

        // cnpj_dv: (cnpj = '') => {
        //     cnpj = utils.get_string_numbers(cnpj);
        //     const size = cnpj.length - 2;
        //     const nums = cnpj.substring(0, size);
        //     const digits = cnpj.substring(size);
        //     let sum = 0;
        //     let pos = size - 7;

        //     for (let i = size; i >= 1; i--) {
        //         sum += parseInt(nums.charAt(size - i)) * pos--;
        //         if (pos < 2) pos = 9;
        //     }

        //     let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        //     if (result != digits.charAt(0) && result != digits.charAt(1)) {
        //         return false;
        //     }
        //     return true;
        // },
    },

    format: {
        /**
         * Recebe um CNPJ e o retorna formatado corretamente (XX.XXX.XXX/XXXX-XX).
         * @param {string} cnpj - O CNPJ a ser formatado.
         * @returns {string} - O CNPJ formatado.
         */
        cnpj: (cnpj = '') =>
            utils
                .get_string_numbers(cnpj)
                .replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
                    '$1.$2.$3/$4-$5',
                ),

        /**
         * Recebe um CPF e o retorna formatado corretamente (XXX.XXX.XXX-XX).
         * @param {string} cpf - O CPF a ser formatado.
         * @returns {string} - O CPF formatado.
         */
        cpf: (cpf = '') =>
            utils
                .get_string_numbers(cpf)
                .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4'),

        /**
         * Recebe um CEP e o retorna formatado corretamente (XXXXX-XXX).
         * @param {string} cep - O CEP a ser formatado.
         * @returns {string} - O CEP formatado.
         */
        cep: (cep = '') =>
            utils.get_string_numbers(cep).replace(/^(\d{5})(\d{3})$/, '$1-$2'),

        /**
         * Recebe uma string e a retorna sem acentos e com traços no lugar de espaços.
         * @param {string} city - O nome da cidade a ser formatado.
         * @returns {string} - O nome da cidade formatado.
         */
        city: (city = '') =>
            city
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/\s/g, '-'),
    },

    empresa: {
        fetch_api_cnpja: async (cnpj) => {
            const url_cnpj = `https://api.cnpja.com/office/${utils.get_string_numbers(
                cnpj,
            )}?strategy=CACHE_IF_ERROR`;
            const url_ccc = `https://api.cnpja.com/ccc?taxId=${utils.get_string_numbers(
                cnpj,
            )}&states=BR&strategy=CACHE_IF_ERROR`;
            const { api_chave_cnpja } = await frappe.db.get_doc('Site Config');

            const res_cnpj = fetch(url_cnpj, {
                method: 'GET',
                headers: { Authorization: `${api_chave_cnpja}` },
            });
            const res_ccc = fetch(url_ccc, {
                method: 'GET',
                headers: { Authorization: `${api_chave_cnpja}` },
            });
            const [data_cnpj, data_ccc] = await Promise.all([
                res_cnpj,
                res_ccc,
            ]);

            if (data_cnpj.status == 401) {
                return frappe.throw(
                    'Chave de autorização da API não autorizada, contate o seu provedor',
                );
            }

            const data_cnpj_json = await data_cnpj.json();
            const data_ccc_json = await data_ccc.json();
            data_cnpj_json.cnpj_input = cnpj;
            data_cnpj_json.registrations = data_ccc_json.registrations || [];
            console.log('RESPONSE CNPJÁ', data_cnpj_json);
            return data_cnpj_json;
        },

        fetch_api_cnpja_cep: async (cep = '') => {
            const formatted_cep = utils.get_string_numbers(cep);
            const { api_chave_cnpja } = await frappe.db.get_doc('Site Config');

            const res_cep = await fetch(
                `https://api.cnpja.com/zip/${formatted_cep}`,
                {
                    method: 'GET',
                    headers: { Authorization: `${api_chave_cnpja}` },
                },
            );

            return await res_cep.json();
        },

        fetch_api_ibge_city: async (city = '') => {
            const formatted_city = utils.format.city(city);
            const jsonResponse = await fetch(
                `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${formatted_city}`,
            );
            return await jsonResponse.json();
        },

        insert_entidade: async (entity) => {
            const {
                company = {},
                address = {},
                emails = [],
                founded,
                statusDate,
                status = {},
                reason = {},
                head,
                specialDate,
                special = {},
                mainActivity = {},
                sideActivities = [],
                updated,
                registrations = [],
                cliente = 0,
                fornecedor = 0,
                empresa_gerenciada = 0,
                transportadora = 0
            } = entity;

            const city_exists_on_db = await frappe.db.exists(
                'Cidades',
                `${address.city}-${address.state}`,
            );
            if (!city_exists_on_db) {
                // TODO - IMPLEMENTAR LÓGICA, CASO A CIDADE NÃO EXISTA, INSERIR NO BANCO
            }

            const ie_table = registrations.map((r) => ({
                ativo: r.enabled,
                estado: r.state,
                numero: r.number,
            }));

            const entity_doc = {
                doctype: 'Entidade',
                nome: entity.alias || company.name || '',
                cnpj: utils.format.cnpj(entity.cnpj_input),
                razao_social: company.name || '',
                capital_social: company.equity || '',
                cliente,
                fornecedor,
                empresa_gerenciada,
                transportadora: transportadora, //
                matriz: head == true ? 'Sim' : 'Não',
                email: emails.length ? emails[0].address : '',
                site_empresa: emails.length ? emails[0].domain : '',
                porte_da_empresa:
                    `${company.size.id}-${company.size.text}` || '',
                data_ultima_atualizacao_consulta: updated || '',
                situacao: `${status.id}-${status.text}` || '',
                motivo_da_situacao: reason.text || '',
                data_da_situacao: statusDate || undefined,
                data_da_especial: specialDate || undefined,
                situacao_especial: special.text || '',
                data_de_abertura: founded || undefined,
                quadro_socios_administradores: company.members.map(
                    (member) => ({
                        data_de_entrada: member.since,
                        nome: member.person.name,
                        cpf_cnpj: member.person.taxId,
                        idade: member.person.age,
                        qualificacao: member.role.text,
                    }),
                ),
                atividade_economica_principal:
                    `${mainActivity.id}-${mainActivity.text}` || '',
                atividades_economicas_secundarias: sideActivities.map(
                    (side_activity) => ({
                        código_da_atividade_econômica_conforme_tabela_do_ibge:
                            side_activity.id,
                        descrição_da_atividade_econômica: side_activity.text,
                    }),
                ),
                cep_faturamento: address.zip || '',
                estado_faturamento: address.state || '',
                cidade_faturamento: `${address.city}-${address.state}` || '',
                bairro_faturamento: address.district || '',
                endereco_faturamento: address.street || '',
                numero_faturamento: address.number || '',
                complemento_faturamento: address.details || '',
                pais_faturamento: address.country.name || '',
                cep_cobranca: address.zip || '',
                estado_cobranca: address.state || '',
                cidade_cobranca: `${address.city}-${address.state}` || '',
                bairro_cobranca: address.district || '',
                endereco_cobranca: address.street || '',
                numero_cobranca: address.number || '',
                complemento_cobranca: address.details || '',
                pais_cobrança: address.country.name || '',
                cep_entrega: address.zip || '',
                estado_entrega: address.state || '',
                cidade_entrega: `${address.city}-${address.state}` || '',
                bairro_entrega: address.district || '',
                endereco_entrega: address.street || '',
                numero_entrega: address.number || '',
                complemento_entrega: address.details || '',
                pais_entrega: address.country.name || '',
                ie: ie_table,
            };
            return await frappe.db.insert(entity_doc);
        },
    },
};

frappe.nxlite.utils.utils = utils;
