document.addEventListener('DOMContentLoaded', async () => {
    const syncfusionScript = document.createElement('script');
    syncfusionScript.src = 'https://cdn.syncfusion.com/ej2/20.2.36/dist/ej2.min.js';
    document.head.appendChild(syncfusionScript);

    const syncfusionStyles = document.createElement('link');
    syncfusionStyles.rel = 'stylesheet';
    syncfusionStyles.href = 'https://cdn.syncfusion.com/ej2/20.2.36/material.css';
    document.head.appendChild(syncfusionStyles);

    syncfusionScript.onload = () => {
        // ej.base.registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXd0dgWX9WdXJXQGFUWEM=');

        // // Ensure the necessary libraries are loaded
        // if (typeof ej.circulargauge.CircularGauge === 'undefined') {
        //     console.error('CircularGauge library is not loaded.');
        //     return;
        // }

        // // Delay to ensure the DOM is fully loaded
        // setTimeout(() => {
        //     // Check if the DOM elements for the gauges are available
        //     const gauge1Element = document.getElementById('gauge1');
        //     const gauge2Element = document.getElementById('gauge2');
        //     if (!gauge1Element || !gauge2Element) {
        //         return;
        //     }

        //     // Gráfico 1
        //     const gauge1 = new ej.circulargauge.CircularGauge({
        //         title: 'Tempo Produtivo',
        //         titleStyle: { size: '15px' },
        //         axes: [{
        //             lineStyle: { width: 0 },
        //             labelStyle: { font: { size: '12px' } },
        //             majorTicks: { width: 0 },
        //             minorTicks: { width: 0 },
        //             startAngle: 270,
        //             endAngle: 90,
        //             radius: '80%',
        //             minimum: 0,
        //             maximum: 100,
        //             pointers: [{
        //                 value: 77,
        //                 radius: '75%',
        //                 color: '#FF4081',
        //                 pointerWidth: 10,
        //                 cap: { radius: 8, color: '#FF4081' },
        //                 animation: { enable: true }
        //             }],
        //             ranges: [{
        //                 start: 0,
        //                 end: 77,
        //                 radius: '90%',
        //                 color: '#FF4081',
        //                 startWidth: 10,
        //                 endWidth: 10
        //             }]
        //         }]
        //     });
        //     gauge1.appendTo('#gauge1');

        //     // Gráfico 2
        //     const gauge2 = new ej.circulargauge.CircularGauge({
        //         title: 'Tempo Ocioso',
        //         titleStyle: { size: '15px' },
        //         axes: [{
        //             lineStyle: { width: 0 },
        //             labelStyle: { font: { size: '12px' } },
        //             majorTicks: { width: 0 },
        //             minorTicks: { width: 0 },
        //             startAngle: 270,
        //             endAngle: 90,
        //             radius: '80%',
        //             minimum: 0,
        //             maximum: 100,
        //             pointers: [{
        //                 value: 61,
        //                 radius: '75%',
        //                 color: '#7C4DFF',
        //                 pointerWidth: 10,
        //                 cap: { radius: 8, color: '#7C4DFF' },
        //                 animation: { enable: true }
        //             }],
        //             ranges: [{
        //                 start: 0,
        //                 end: 61,
        //                 radius: '90%',
        //                 color: '#7C4DFF',
        //                 startWidth: 10,
        //                 endWidth: 10
        //             }]
        //         }]
        //     });
        //     gauge2.appendTo('#gauge2');
        // }, 1000);
    };
});
