import {
	Gantt,
	Selection,
	VirtualScroll,
	Filter,
	Sort,
	ColumnMenu,
	Edit,
	UndoRedo,
	Toolbar,
} from "@syncfusion/ej2-gantt";
import { registerLicense } from "@syncfusion/ej2-base";
import moment from "moment";
import {} from "./es5-datasource.js";

import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-gantt/styles/material.css";

Gantt.Inject(Gantt, Selection, VirtualScroll, Filter, Sort, ColumnMenu, Edit, UndoRedo, Toolbar);

let debounceTimer;
let lastUpdatedData;
class SyncFusionGantt {
	constructor({ wrapper, element, data, initialDate, endDate }) {
		registerLicense(
			"ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sdk1iWXtbcndcQmNc"
		);
		console.log("Registrando licença Syncfusion...");
		try {
			if (!wrapper) {
				throw new Error("Wrapper is required to initialize SyncFusionGantt.");
			}

			this.$wrapper = $(wrapper);
			this.element = element || "#Gantt";

			const GanttData = Array.isArray(data)
				? data.map((task) => {
						return {
							TaskID: task.TaskID,
							TaskName: task.TaskName,
							TaskDescription: task.TaskDescription,
							StartDate: new Date(task.StartDate),
							EndDate: new Date(task.EndDate),
							subtasks: task.subtasks
								? task.subtasks.map((subtask) => ({
										TaskID: subtask.TaskID,
										TaskName: subtask.TaskName,
										TaskDescription: subtask.TaskDescription,
										StartDate: new Date(subtask.StartDate),
										Duration: subtask.Duration || 0,
										Progress: subtask.Progress || 0,
										Predecessor: subtask.Predecessor || null,
								  }))
								: [],
						};
				  })
				: [];

			const currentYear = new Date().getFullYear();
			const ganttChart = new Gantt({
				dataSource: GanttData,
				timelineSettings: {
					showTooltip: true,
					dateFormat: "dd",
					topTier: {
						unit: "Month", 
					},
					bottomTier: {
						unit: "Day", 
						format: "dd",
					},
				},
				height: "500px",
				dateFormat: "dd/MM/yyyy",
				highlightWeekends: true,
				showColumnMenu: true,

				queryTaskbarInfo: function (args) {
					args.progressBarBgColor = "lightgreen";
					args.taskbarBgColor = "lightblue";
				},
				actionComplete: async function (args) {
          if (args.action === "HorizontalScroll") {
						return;
					}
					if (args.requestType === "save") {
						lastUpdatedData = args.data;
						console.log("Atualizou");
						console.log("Atualizou args", args);
						console.log("OP alterada", lastUpdatedData.TaskName);

						let formato = moment(lastUpdatedData.EndDate).format("DD/MM/YYYY");
						const data_inicio = moment(lastUpdatedData.StartDate).format("YYYY-MM-DD");
						const data_entrega = moment(lastUpdatedData.EndDate).format("YYYY-MM-DD");
						const predecessor = lastUpdatedData.Predecessor;
						console.log("Data de inicio", data_inicio);
						console.log("Data de entrega", data_entrega);
						console.log("Predecessor", predecessor);
						await frappe.db.set_value(
							"Ordem de Producao",
							lastUpdatedData.TaskName,
							"dt_inicio",
							data_inicio
						);
						await frappe.db.set_value(
							"Ordem de Producao",
							lastUpdatedData.TaskName,
							"dt_entrega",
							data_entrega
						);
						await frappe.db.set_value(
							"Ordem de Producao",
							lastUpdatedData.TaskName,
							"predecessor",
							predecessor
						);
					}
				},
				allowFiltering: true,
				enableUndoRedo: true,
				allowSorting: true,
				allowResizing: true,
				allowReordering: true,
				allowSelection: true,
				selectionSettings: { mode: "Row", type: "Single" },
				filterSettings: { type: "Menu" },
				showTodayMarker: true,
				enableVirtualization: true,
				taskFields: {
					id: "TaskID",
					name: "TaskName",
					description: "TaskDescription",
					startDate: "StartDate",
					endDate: "EndDate",
					duration: "Duration",
					progress: "Progress",
					dependency: "Predecessor",
					child: "subtasks",
				},
				editSettings: {
					allowAdding: true,
					allowEditing: true,
					allowDeleting: true,
					allowFiltering: true,
					allowTaskbarEditing: true,
					showDeleteConfirmDialog: true,
					fields: {
            Status: {
              type: "Dropdown",
							params: {
								dataSource: ["Not Started", "In Progress", "Completed", "On Hold"],
								placeholder: "Selecione o Status",
								allowFiltering: true,
							},
						},
					},
				},
				splitterSettings: {
          position: "40%",
					columnIndex: 2,
				},
				columns: [
					{ field: "TaskID", headerText: "ID", width: 50 },
					{ field: "TaskName", headerText: "Nome", width: 190 },
					{ field: "TaskDescription", headerText: "Descrição", width: 190 },
					{
            field: "StartDate",
						headerText: "Início",
						format: "dd/MM/yyyy",
						type: "date",
						allowEditing: true,
					},
					{
						field: "EndDate",
						headerText: "Data de Entrega",
						format: "dd/MM/yyyy",
						type: "date",
						allowEditing: true,
					},
					{ field: "Duration", headerText: "Duração", width: 120 },
					{ field: "Progress", headerText: "Progresso (%)", width: 150 },
					{ field: "Predecessor", headerText: "Dependência", allowEditing: true },
				],
				toolbar: ["Edit", "ExpandAll", "CollapseAll"],
				undoRedoActions: [
          "Sorting",
					"Add",
					"ColumnState",
					"Delete",
					"Edit",
					"Filtering",
					"Indent",
					"Outdent",
					"NextTimeSpan",
					"PreviousTimeSpan",
					"RowDragAndDrop",
					"Search",
				],
				treeColumnIndex: 1,
				labelSettings: {
          leftLabel: "TaskName",
					rightLabel: "Progress",
				},
        projectStartDate: initialDate,
        projectEndDate: endDate
        // teste

        //
				// enableContextMenu: true,

				// gridLines: "Both",
				// holidays: [
          //   { from: `${currentYear}-01-02`, label: "Início do Novo" },
				//   { from: `${currentYear + 1}-01-01`, label: "Fim do Ano" },
				// ],
				// resources: ProjectResources
        //   taskbarEdited: function (args) {
        //     // console.log("taskbar edited")
        //     // console.log('Updated data:', args.data);
        //     let formato = moment(args.data.EndDate).format('DD/MM/YYYY');
        //     // console.log("formato", formato)
        
        // },
			});

			ganttChart.appendTo(this.$wrapper[0]);
			console.log("ganttchart rendered successfully.");
		} catch (e) {
			console.error("Error initializing SyncFusionGantt:", e);
		}
	}
}

frappe.provide("frappe.gantt_chart");
frappe.gantt_chart.SyncFusionGantt = SyncFusionGantt;

export default SyncFusionGantt;
