import {
	TreeGrid,

	Page as PageTreeGrid,
	Sort as SortTreeGrid,
	Filter as FilterTreeGrid,
	Edit as EditTreeGrid,
	Toolbar as ToolbarTreeGrid,
	VirtualScroll as VirtualScrollTreeGrid,
	InfiniteScroll as InfiniteScrollTreeGrid,
} from "@syncfusion/ej2-treegrid";
import { registerLicense } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-treegrid/styles/material.css";

TreeGrid.Inject(PageTreeGrid, SortTreeGrid, FilterTreeGrid, EditTreeGrid, ToolbarTreeGrid, VirtualScrollTreeGrid, InfiniteScrollTreeGrid);

frappe.provide("frappe.syncfusion");
class SyncFusionTreeGrid {
	constructor({ wrapper, element, data }) {
		registerLicense(
			"ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sdk1iWXtbcndcQmNc"
		);
		console.log("Registrando licença Syncfusion para TreeGrid...");

		try {
			if (!wrapper) {
				throw new Error("Wrapper is required to initialize SyncFusionTreeGrid.");
			}

			this.$wrapper = $(wrapper);
			this.element = element || "#TreeGrid";

			const treeGridData = Array.isArray(data)
				? data.map((item) => ({
						ElementEstrutura: item.ElementEstrutura,
						CustoEstrutura: item.CustoEstrutura.toLocaleString("pt-BR", {
							style: "decimal",
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}),
						Children: item.Children || [],
				  }))
				: [];

			const treeGrid = new TreeGrid({
				dataSource: treeGridData,
				childMapping: "Children",
				parentIdMapping: "ParentId",
				height: "1000px",
				idMapping: "EstruturaId",
				InfiniteScrolling: true,
				pageSettings: {
					pageSize: 1000,
					pageCount: 5,
				},
				treeColumnIndex: 0,
				allowPaging: true,
				allowSorting: true,
				allowFiltering: true,
				allowEditing: true,
				editSettings: {
					allowAdding: true,
					allowEditing: true,
					allowDeleting: true,
					mode: "Row",
				},
				toolbar: ["Search", "ExpandAll", "CollapseAll"],
				columns: [
					{
						field: "ElementEstrutura",
						headerText: "Descrição",
						isPrimaryKey: true,
						width: 30,
						textAlign: "Left",
					},
					{ field: "CustoEstrutura", headerText: "Custo", width: 30, textAlign: "Left" },
				],
				childIndent: 80,

				queryCellInfo: function (args) {
					if (args.column.field === "ElementEstrutura") {
						console.log("argsssss", args);
						console.log("argsssssdata", args.data);

						let levelClass = args.cell.classList.value.match(/level(\d+)/);
						if (levelClass) {
							let level = parseInt(levelClass[1]);
							if (level < 2) {
							}
							args.cell.style.paddingLeft = `${level * 10}px`;
						}
					}
				},
			});

			treeGrid.appendTo(this.$wrapper[0]);
			console.log("TreeGrid rendered successfully.");
		} catch (e) {
			console.error("Error initializing SyncFusionTreeGrid:", e);
		}
	}
}


import {
    Chart,
    LineSeries as LineSeriesChart,
    Category as CategoryChart,
    Tooltip as TooltipChart,
    Legend as LegendChart,
    DataLabel as DataLabelChart,
  } from "@syncfusion/ej2-charts";
  
  Chart.Inject(LineSeriesChart, CategoryChart, TooltipChart, LegendChart, DataLabelChart);
  
  class BurndownChart {
    constructor({ wrapper, data, title = "Gráfico de Burndown", data_inicial, data2 }) {
      registerLicense(
        "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sdk1iWXtbcndcQmNc"
      );
      console.log("Licença Syncfusion registrada com sucesso.");
  
      try {
        if (!wrapper) {
          throw new Error("É necessário fornecer um wrapper para inicializar o Gráfico.");
        }
        // test
        this.$wrapper = $(wrapper);
  
        console.log("data", data)
        console.log("data2", data2)
  
        let ProgressoInicial = 100
        const serieIdeal = data.map((data) => {
          ProgressoInicial -= data.Esforco
          return {
            Data: data.Data,
            Ideal: Math.abs(ProgressoInicial) < 1e-10 ? 0 : ProgressoInicial
          }
        })
  
  console.log("serieIdeal", serieIdeal)
  
  
  let ProgressoInicialRealizado = 100
  const serieRealizado = data2.map((data) => {
    ProgressoInicialRealizado -= data.Esforco
    return {
      Data: data.Data,
      Realizado: Math.abs(ProgressoInicialRealizado) < 1e-10 ? 0 : ProgressoInicialRealizado
    }
  })
  console.log("serieRealizada", serieRealizado)
  
  console.log("daqui pra cima ta ok temos os datas e os progresso de ideal e realizado")
  
  
  const bigdata = [...serieIdeal, ...serieRealizado]
  console.log("bigdata", bigdata)
  
  const sortedData = bigdata.sort((a, b) => new Date(a.Data) - new Date(b.Data));
  
  sortedData.unshift({ Data: data_inicial, Ideal: 100, Realizado: 100 });
  
  console.log("sortedData big", sortedData)
  
  
  const consolidatedData = sortedData.reduce((acc, current) => {
    const lastItem = acc[acc.length - 1] || { Ideal: 100, Realizado: 100 }; // Valor inicial padrão
  
    acc.push({
      Data: current.Data,
      Ideal: current.Ideal !== undefined ? current.Ideal : lastItem.Ideal,
      Realizado: current.Realizado !== undefined ? current.Realizado : lastItem.Realizado,
    });
  
    return acc;
  }, []);
  
  
  console.log(consolidatedData);
  
  const uniqueDatesData = consolidatedData.reduce((acc, current) => {
    // Verifica se já existe um objeto para a mesma data
    const existing = acc.find(item => item.Data === current.Data);
  
    if (existing) {
      // Atualiza apenas se o objeto atual for mais próximo de zero
      const isCurrentCloserToZero =
        Math.abs(current.Ideal) + Math.abs(current.Realizado) <
        Math.abs(existing.Ideal) + Math.abs(existing.Realizado);
  
      if (isCurrentCloserToZero) {
        existing.Ideal = current.Ideal;
        existing.Realizado = current.Realizado;
      }
    } else {
      // Adiciona ao acumulador caso não exista
      acc.push({ ...current });
    }
  
    return acc;
  }, []);
  
  console.log("uniqueDatesData", uniqueDatesData)
  
  const processedData = consolidatedData.map((item, index, arr) => {
    const previous = arr[index - 1] || {Ideal: 100, Realizado: 100}; // Elemento anterior ou vazio
  
    console.log("previous do index 0", "index",index, previous)
  
    return {
      Data: item.Data,
      Ideal: item.Ideal !== undefined ? item.Ideal : previous.Ideal,
      Realizado: item.Realizado !== undefined ? item.Realizado : previous.Realizado,
    };
  });
  
  console.log("Processed Data:", processedData);
  
        // Criando o gráfico de Burndown
        const lineGrid = new Chart({
          primaryXAxis: {
            title: "Data de Entrega",
            valueType: "Category",
            interval: 1,
            labelPlacement: "OnTicks",
            
          },
          primaryYAxis: {
            title: "Progresso (%)",
            minimum: 0,
            maximum: 100,
            interval: 20,
          },
          series: [
            {
              dataSource: uniqueDatesData,
              xName: "Data",
              yName: "Ideal",
              name: "Ideal",
              type: "Line",
              marker: { visible: true },
            },{
              dataSource: uniqueDatesData,
              xName: "Data",
              yName: "Realizado",
              name: "Realizado",
              type: "Line",
              marker: { visible: true },
            },
          ],
          title,
          tooltip: { enable: true },
          legendSettings: { visible: true },
        });
  
        // Renderizando o gráfico no elemento
        lineGrid.appendTo(this.$wrapper[0]);
        console.log("Gráfico de Burndown renderizado com sucesso.");
      } catch (e) {
        console.error("Erro ao inicializar o Gráfico de Burndown:", e);
      }
    }
  }
  
 

class CargaMaquina {
	constructor({ wrapper }) {
		if (!wrapper) {
			throw new Error("Wrapper is required to initialize CargaMaquina.");
		}
		this.wrapper = wrapper;
		this.elementId = "progress-bar-container";
		this.selectedPeriod = parseInt(localStorage.getItem("selectedPeriod")) || 30;
		this.updateInterval = null;
		this.selectedButton = null;
	}

	async initSyncfusionCharts() {
		const syncfusionScript = document.createElement("script");
		syncfusionScript.src = "https://cdn.syncfusion.com/ej2/20.2.36/dist/ej2.min.js";
		document.head.appendChild(syncfusionScript);

		const syncfusionStyles = document.createElement("link");
		syncfusionStyles.rel = "stylesheet";
		syncfusionStyles.href = "https://cdn.syncfusion.com/ej2/20.2.36/material.css";
		document.head.appendChild(syncfusionStyles);

		syncfusionScript.onload = async () => {
			ej.base.registerLicense(
				"ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXd0dgWX9WdXJXQGFUWEM="
			);

			const machineLoadPanelId = "panel-" + Math.random().toString(36).substr(2, 9);

			this.wrapper.innerHTML = `
            <div class="chart-header">CARGA MÁQUINA</div>
            <div class="button-container" style="display: flex; justify-content: center; margin-bottom: 0px;">
            <div class="dias-text" style="font-weight: bold; margin-right: 10px; background-color: white;"></div>
            </div>
            <div class="chart-container" style="display: flex; align-items: flex-start; width: 100%; height: 100%;">
                    <!-- Gráfico -->
                    <div id="${machineLoadPanelId}-content" class="chart-box" style="flex: 1; height: calc(100% - 40px);"></div>
                    </div>
            `;

			const buttonContainer = this.wrapper.querySelector(".button-container");

			const periods = [7, 15, 30, 60, 90, 180];
			periods.forEach((period) => {
				const button = document.createElement("div");
				button.innerText = `${period}`;
				button.style.display = "flex";
				button.style.alignItems = "center";
				button.style.justifyContent = "center";
				button.style.width = "80px";
				button.style.height = "30px";
				button.style.marginBottom = "10px";
				button.style.borderRadius = "0px 0px 0px 0px";
				button.style.boxShadow = "0px 2px 5px rgba(0, 0, 0, 0.1)";
				button.style.backgroundColor = "#f5f2f2";
				button.style.fontSize = "18px";
				button.style.fontWeight = "bold";
				button.style.color = "#999999";
				button.style.cursor = "pointer";
				button.style.transition = "all 0.4s ease";

				button.onclick = () => {
					this.selectedPeriod = period;
					localStorage.setItem("selectedPeriod", period);

					Array.from(buttonContainer.children).forEach((child) => {
						if (child.tagName === "DIV" && !child.classList.contains("dias-text")) {
							child.style.color = "#999999";
							child.style.backgroundColor = "#f5f2f2";
							child.style.boxShadow = "0px 2px 5px rgba(0, 0, 0, 0.1)";
						}
					});

					button.style.color = "#397bbe";
					button.style.backgroundColor = "#f4f8fc";
					button.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)";
					this.selectedButton = button;

					this.renderMachineLoadChart(period, true);
				};

				if (period === this.selectedPeriod) {
					button.style.color = "#397bbe";
					button.style.backgroundColor = "#f4f8fc";
					button.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)";
					this.selectedButton = button;
				}

				buttonContainer.appendChild(button);
			});

			await this.renderMachineLoadChart(this.selectedPeriod);
		};
	}

	async renderMachineLoadChart(days, includeDelayed = true) {
		const machineLoadPanelId = this.wrapper.querySelector(".chart-box").id;
		const availableHoursByCentroCusto = await this.calculateAvailableHours();
		const machineLoadChartData = await this.fetchMachineLoadData(
			days,
			availableHoursByCentroCusto,
			includeDelayed
		);
		// console.log('Horas por centro_custo:', availableHoursByCentroCusto);
		// console.log('CARGA MÁQUINA:', machineLoadChartData);

		const chartData = machineLoadChartData.map((data) => ({
			x: data.centro_custo,
			y: data.horas_vendidas,
			Rate: data.horas_disponiveis,
			text: `${data.horas_vendidas}`,
		}));

		const machineLoadChart = new ej.charts.Chart({
			primaryXAxis: {
				valueType: "Category",
				interval: 1,
				majorGridLines: { width: 0 },
				majorTickLines: { width: 0 },
				minorTickLines: { width: 0 },
				labelPosition: "Outside",
				labelRotation: ej.base.Browser.isDevice ? -45 : 0,
				labelIntersectAction: ej.base.Browser.isDevice ? "None" : "Rotate45",
			},
			chartArea: { border: { width: 0 } },
			enableSideBySidePlacement: false,
			primaryYAxis: {
				minimum: 0,
				// maximum: 720,
				labelFormat: "{value}",
				majorTickLines: { width: 0 },
				lineStyle: { width: 0 },
			},
			tooltip: { enable: true },
			legendSettings: {
				visible: true,
				position: "Bottom",
				alignment: "Center",
				height: "10%",
			},
			series: [
				{
					type: "Column",
					xName: "x",
					width: 2,
					yName: "Rate",
					dataSource: chartData,
					name: "Disponível",
					enableTooltip: true,
					columnWidth: 0.8,
					opacity: 0.5,
					cornerRadius: { bottomLeft: 12, bottomRight: 12, topLeft: 12, topRight: 12 },
					fill: "#bfbfbf",
				},
				{
					type: "Column",
					xName: "x",
					width: 2,
					yName: "y",
					dataSource: chartData,
					name: "Vendidas",
					columnWidth: 0.8,
					cornerRadius: { bottomLeft: 12, bottomRight: 12, topLeft: 12, topRight: 12 },
					fill: "#397bbe",
					marker: {
						dataLabel: {
							visible: true,
							name: "text",
							position: "Top",
							font: { fontWeight: "800", color: "#f0f0f0", size: "13px" },
						},
					},
				},
			],
			// highlightColor: 'transparent',
			load: (args) => {
				let selectedTheme = location.hash.split("/")[1];
				selectedTheme = selectedTheme ? selectedTheme : "Fluent2";
				args.chart.theme =
					selectedTheme.charAt(0).toUpperCase() +
					selectedTheme
						.slice(1)
						.replace(/-dark/i, "Dark")
						.replace(/contrast/i, "Contrast")
						.replace(/-highContrast/i, "HighContrast");
			},
			width: "100%",
		});

		machineLoadChart.appendTo(`#${machineLoadPanelId}`);

		this.startRealTimeUpdates(machineLoadChart, days);
	}

	startRealTimeUpdates(chart, days) {
		if (this.updateInterval) {
			clearInterval(this.updateInterval);
		}
		// this.updateInterval = setInterval(async () => {
		//     const availableHoursByCentroCusto = await this.calculateAvailableHours();
		//     const machineLoadChartData = await this.fetchMachineLoadData(days, availableHoursByCentroCusto, true);

		//     chart.series[0].dataSource = machineLoadChartData;
		//     chart.series[1].dataSource = machineLoadChartData;
		//     chart.refresh();
		// }, 500);
	}

	onPanelResize(args) {
		if (args.element && args.element.querySelector(".chart-box")) {
			const chart = args.element.querySelector(".chart-box").ej2_instances[0];
			if (chart) {
				chart.width = "100%";
				chart.height = "100%";
				chart.refresh();
			}
		}
	}

	async calculateAvailableHours() {
		const resources = await frappe
			.call({
				method: "nxlite.nx_producao.page.minha_producao.controller.carga_maquina",
				args: { request_type: "fetchFrappe" },
			})
			.then((response) => response.message);

		// console.log('disp_rec:', resources);

		const recursos = await frappe
			.call({
				method: "nxlite.nx_producao.page.minha_producao.controller.carga_maquina",
				args: { request_type: "fetchRecurso" },
			})
			.then((response) => response.message);

		// console.log('Recursos:', recursos);

		let availableHoursByCentroCusto = {};
		let parentDurations = {};

		resources.forEach((resource) => {
			if (resource.tipo === "Disponível" && typeof resource.duracao === "string") {
				const horas_disponiveis =
					resource.duracao
						.split(":")
						.reverse()
						.reduce((acc, time, i) => acc + parseInt(time) * Math.pow(60, i), 0) /
					3600;
				if (!parentDurations[resource.parent]) {
					parentDurations[resource.parent] = 0;
				}
				parentDurations[resource.parent] += horas_disponiveis;
			}
		});

		for (const recurso of recursos) {
			const parent = recurso.disponibilidade;
			const centro_de_custo = recurso.centro_de_custo;
			if (centro_de_custo) {
				if (!availableHoursByCentroCusto[centro_de_custo]) {
					availableHoursByCentroCusto[centro_de_custo] = 0;
				}
				if (parent && parentDurations[parent]) {
					availableHoursByCentroCusto[centro_de_custo] +=
						parentDurations[parent] * this.selectedPeriod;
				} else {
					availableHoursByCentroCusto[centro_de_custo] += 24 * this.selectedPeriod;
				}
			}
		}

		return availableHoursByCentroCusto;
	}

	async fetchMachineLoadData(days, available_hours, includeDelayed) {
		const machineLoadData = await frappe.call({
			method: "nxlite.nx_producao.page.minha_producao.controller.carga_maquina",
			args: {
				request_type: "get_machine_load_data",
				days: days,
				available_hours: JSON.stringify(available_hours),
				include_delayed: includeDelayed,
			},
		});

		// console.log('Machine Load Data:', machineLoadData.message);

		return machineLoadData.message;
	}
}

import {
	Gantt,

	Selection as SelectionGantt,
	VirtualScroll as VirtualScrollGantt,
	Filter as FilterGantt,
	Sort as SortGantt,
	ColumnMenu as ColumnMenuGantt,
	Edit as EditGantt,
	UndoRedo as UndoRedoGantt,
	Toolbar as ToolbarGantt,
} from "@syncfusion/ej2-gantt";
import moment from "moment";

import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-gantt/styles/material.css";


Gantt.Inject(Gantt, SelectionGantt, VirtualScrollGantt, FilterGantt, SortGantt, ColumnMenuGantt, EditGantt, UndoRedoGantt, ToolbarGantt);

let debounceTimer;
let lastUpdatedData;
class SyncFusionGantt {
	constructor({ wrapper, element, data, initialDate, endDate }) {
		registerLicense(
			"ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sdk1iWXtbcndcQmNc"
		);
		console.log("Registrando licença Syncfusion...");
		try {
			if (!wrapper) {
				throw new Error("Wrapper is required to initialize SyncFusionGantt.");
			}

			this.$wrapper = $(wrapper);
			this.element = element || "#Gantt";

			const GanttData = Array.isArray(data)
				? data.map((task) => {
						return {
							TaskID: task.TaskID,
							TaskName: task.TaskName,
							TaskDescription: task.TaskDescription,
							StartDate: new Date(task.StartDate),
							EndDate: new Date(task.EndDate),
							subtasks: task.subtasks
								? task.subtasks.map((subtask) => ({
										TaskID: subtask.TaskID,
										TaskName: subtask.TaskName,
										TaskDescription: subtask.TaskDescription,
										StartDate: new Date(subtask.StartDate),
										Duration: subtask.Duration || 0,
										Progress: subtask.Progress || 0,
										Predecessor: subtask.Predecessor || null,
								  }))
								: [],
						};
				  })
				: [];

			const currentYear = new Date().getFullYear();
			const ganttChart = new Gantt({
				dataSource: GanttData,
				timelineSettings: {
					showTooltip: true,
					dateFormat: "dd",
					topTier: {
						unit: "Month",
					},
					bottomTier: {
						unit: "Day",
						format: "dd",
					},
				},
				height: "500px",
				dateFormat: "dd/MM/yyyy",
				highlightWeekends: true,
				showColumnMenu: true,

				queryTaskbarInfo: function (args) {
					args.progressBarBgColor = "lightgreen";
					args.taskbarBgColor = "lightblue";
				},
				actionComplete: async function (args) {
					if (args.action === "HorizontalScroll") {
						return;
					}
					if (args.requestType === "save") {
						lastUpdatedData = args.data;
						console.log("Atualizou");
						console.log("Atualizou args", args);
						console.log("OP alterada", lastUpdatedData.TaskName);

						let formato = moment(lastUpdatedData.EndDate).format("DD/MM/YYYY");
						const data_inicio = moment(lastUpdatedData.StartDate).format("YYYY-MM-DD");
						const data_entrega = moment(lastUpdatedData.EndDate).format("YYYY-MM-DD");
						const predecessor = lastUpdatedData.Predecessor;
						console.log("Data de inicio", data_inicio);
						console.log("Data de entrega", data_entrega);
						console.log("Predecessor", predecessor);
						await frappe.db.set_value(
							"Ordem de Producao",
							lastUpdatedData.TaskName,
							"dt_inicio",
							data_inicio
						);
						await frappe.db.set_value(
							"Ordem de Producao",
							lastUpdatedData.TaskName,
							"dt_entrega",
							data_entrega
						);
						await frappe.db.set_value(
							"Ordem de Producao",
							lastUpdatedData.TaskName,
							"predecessor",
							predecessor
						);
					}
				},
				allowFiltering: true,
				enableUndoRedo: true,
				allowSorting: true,
				allowResizing: true,
				allowReordering: true,
				allowSelection: true,
				selectionSettings: { mode: "Row", type: "Single" },
				filterSettings: { type: "Menu" },
				showTodayMarker: true,
				enableVirtualization: true,
				taskFields: {
					id: "TaskID",
					name: "TaskName",
					description: "TaskDescription",
					startDate: "StartDate",
					endDate: "EndDate",
					duration: "Duration",
					progress: "Progress",
					dependency: "Predecessor",
					child: "subtasks",
				},
				editSettings: {
					allowAdding: true,
					allowEditing: true,
					allowDeleting: true,
					allowFiltering: true,
					allowTaskbarEditing: true,
					showDeleteConfirmDialog: true,
					fields: {
						Status: {
							type: "Dropdown",
							params: {
								dataSource: ["Not Started", "In Progress", "Completed"],
								placeholder: "Selecione o Status",
								allowFiltering: true,
							},
						},
					},
				},
				splitterSettings: {
					position: "40%",
					columnIndex: 2,
				},
				columns: [
					{ field: "TaskID", headerText: "ID", width: 50 },
					{ field: "TaskName", headerText: "Nome", width: 190 },
					{ field: "TaskDescription", headerText: "Descrição", width: 190 },
					{
						field: "StartDate",
						headerText: "Início",
						format: "dd/MM/yyyy",
						type: "date",
						allowEditing: true,
					},
					{
						field: "EndDate",
						headerText: "Data de Entrega",
						format: "dd/MM/yyyy",
						type: "date",
						allowEditing: true,
					},
					{ field: "Duration", headerText: "Duração", width: 120 },
					{ field: "Progress", headerText: "Progresso (%)", width: 150 },
					{ field: "Predecessor", headerText: "Dependência", allowEditing: true },
				],
				toolbar: ["Edit", "ExpandAll", "CollapseAll"],
				undoRedoActions: [
					"Sorting",
					"Add",
					"ColumnState",
					"Delete",
					"Edit",
					"Filtering",
					"Indent",
					"Outdent",
					"NextTimeSpan",
					"PreviousTimeSpan",
					"RowDragAndDrop",
					"Search",
				],
				treeColumnIndex: 1,
				labelSettings: {
					leftLabel: "TaskName",
					rightLabel: "Progress",
				},
				projectStartDate: initialDate,
				projectEndDate: endDate,
				// teste

				//
				// enableContextMenu: true,

				// gridLines: "Both",
				// holidays: [
				//   { from: `${currentYear}-01-02`, label: "Início do Novo" },
				//   { from: `${currentYear + 1}-01-01`, label: "Fim do Ano" },
				// ],
				// resources: ProjectResources
				//   taskbarEdited: function (args) {
				//     // console.log("taskbar edited")
				//     // console.log('Updated data:', args.data);
				//     let formato = moment(args.data.EndDate).format('DD/MM/YYYY');
				//     // console.log("formato", formato)

				// },
			});

			ganttChart.appendTo(this.$wrapper[0]);
			console.log("ganttchart rendered successfully.");
		} catch (e) {
			console.error("Error initializing SyncFusionGantt:", e);
		}
	}
}


import { createApp } from "vue";
import { createVuetify } from "vuetify";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import meuFaturamentoApp from "../vue/meu-faturamento/app.vue";

class MeuFaturamento {
	constructor({ wrapper }) {
		try {
			registerLicense("Sua_licenca_aqui");
			this.$wrapper = $(wrapper);
			// Use o wrapper diretamente para passar ao Vue
			const app = createApp(meuFaturamentoApp);

			// Criação e configuração do Pinia (State Management)
			const pinia = createPinia();
			pinia.use(piniaPersist);

			// Definindo as configurações globais do Vue
			SetVueGlobals(app);

			// Use Vuetify e monte a aplicação
			app.use(createVuetify()).use(pinia).mount(this.$wrapper.get(0)); // Monta no container passado como parâmetro
		} catch (e) {
			console.error("Erro ao montar a aplicação:", e);
		}
	}
}

import { Kanban } from "@syncfusion/ej2-kanban";
import { DropDownList } from "@syncfusion/ej2-dropdowns"; // Importar DropDownList

var kanbanObj = null;

class SyncFusionKanban {
	constructor({ wrapper, element, dataSource }) {
		try {
			if (!wrapper) {
				throw new Error("Wrapper is required to initialize SyncFusionKanban.");
			}
			this.$wrapper = $(wrapper);
			this.element = element || "#Kanban";

			console.log("Wrapper initialized:", wrapper);

			registerLicense(
				"Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH1fdnVURWVeVEB1VkI="
			);
			const empresas = dataSource
				.map((item) => item.nome_empresa)
				.filter((value, index, self) => self.indexOf(value) === index);
			// Adicionar DropDownList
			const dropDownList = new DropDownList({
				dataSource: empresas,
				placeholder: "Selecione uma empresa",
				change: (args) => {
					const filteredData = dataSource.filter(
						(item) => item.nome_empresa === args.value
					);
					kanbanObj.dataSource = filteredData;
					kanbanObj.refresh();
				},
			});
			dropDownList.appendTo("#dropdownElement");

			kanbanObj = new Kanban({
				dataSource: dataSource,
				keyField: "doctype",
				allowDragAndDrop: false,
				columns: [
					{
						headerText: "SOLICITAÇÃO",
						keyField: "Solicitacao",
						template:
							'<div class="kanban-column">' +
							'<div class="kanban-header">' +
							'<i class="fas fa-paper-plane icon-solicitacao"></i>' +
							"<span>Solicitação</span>" +
							"</div>" +
							`<a href="${window.location.origin}/app/solicitacao/new-solicitacao-jmyrfanpir#solciita%C3%A7%C3%A3o_tab" target="_blank">` +
							'<button class="kanban-button">Nova Solicitação</button>' +
							"</a>" +
							"</div>",
					},
					{
						headerText: "COTAÇÃO",
						keyField: "Cotacao",
						template:
							'<div class="kanban-column">' +
							'<div class="kanban-header">' +
							'<i class="fas fa-chart-bar icon-cotacao"></i>' +
							"<span>Cotação</span>" +
							"</div>" +
							`<a href="${window.location.origin}/app/cotacao/new-cotacao-amonfprpar#principal_tab" target="_blank">` +
							'<button class="kanban-button">Nova Cotação</button>' +
							"</a>" +
							"</div>",
					},
					{
						headerText: "ORDEM DE COMPRA",
						keyField: "Ordem de Compra",
						template:
							'<div class="kanban-column">' +
							'<div class="kanban-header">' +
							'<i class="fas fa-cart-arrow-down icon-ordem-compra"></i>' +
							"<span>Ordem de Compra</span>" +
							"</div>" +
							`<a href="${window.location.origin}/app/ordem-de-compra/new-ordem-de-compra-ppcatlbgmz#principal_tab" target="_blank">` +
							'<button class="kanban-button">Nova Ordem de Compra</button>' +
							"</a>" +
							"</div>",
					},
				],

				cardSettings: {
					contentField: "observacao",
					headerField: "name",
					allowEditing: false,
					template: function (data) {
						if (data.doctype == "Solicitacao") {
							// const emissionColor = getExpirationColor(data.data_solicitacao);
							const necessityColor = getExpirationColor(data.data_da_necessidade);
							return `
                <div class="e-card-grid trello-card">
                  <div class="e-card-header">
                      <strong>
                          <a href="${window.location.origin}/app/solicitacao/${
								data.name
							}#principal_tab" target="_blank">
                              <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${
									data.name || "N/A"
								}
                          </a>
                      </strong>
                  </div>
                  <div class="e-card-content">
                    <div class="e-card-item bordered-item">
                      <strong><i class="fas fa-cogs icon-item"></i> Item:</strong> ${
							data.codigo_item
								? `${data.codigo_item} - ${data.item_descricao || ""} (${
										data.quantidade
								  } ${data.unidade || ""}) `
								: "N/A"
						}
                    </div>
                      <div class="e-card-solicitante bordered-solicitante">
                      <strong>Solicitante:</strong> ${data.solicitante || "N/A"}
                    </div>
                     <div class="e-card-data-emissao bordered-data-emissao">
                      <strong>Data de Emissão:</strong> ${
							formatarData(data.data_solicitacao) || "N/A"
						}
                    </div>
                    <div class="e-card-data-necessidade bordered-data-necessidade" style="background-color: ${necessityColor};">
                      <strong>Data Necessidade:</strong> ${
							formatarData(data.data_da_necessidade) || "N/A"
						}
                    </div>
                  </div>
                </div> 
                `;
						} else if (data.doctype == "Ordem de Compra") {
							const deliveryColor = getExpirationColor(
								data.data_prevista_de_entrega
							);
							return `
                  <div class="e-card-grid trello-card">
                    <div class="e-card-header">
                        <strong>
                           <a href="${window.location.origin}/app/ordem-de-compra/${
								data.name
							}#principal_tab" target="_blank">
                              <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${
									data.name || "N/A"
								} 
                          </a>
                      </strong>
                  </div>
                <div>
                  <div class="e-card-content">
                    <div class="e-card-fornecedor bordered-fornecedor">
                      <strong>Fornecedor:</strong> ${data.descricao_fornecedor || "N/A"}
                    </div>
                    <div class="e-card-itens bordered-itens clickable" onclick="openModal('${
						data.name
					}')">
                      <strong><i class="fas fa-cogs icon-itens"></i> Itens (${
							data.itens.length
						})</strong>
                    </div>
                    <div class="e-card-data-emissao bordered-data-emissao">
                      <strong>Data de Emissão:</strong> ${
							formatarData(data.data_de_emissão) || "N/A"
						}
                    </div>
                    <div class="e-card-data-prevista bordered-data-prevista" style="background-color: ${deliveryColor};">
                      <strong>Data Prevista Entrega:</strong> ${
							formatarData(data.data_prevista_de_entrega) || "N/A"
						}
                    </div>
                    <div class="e-card-valor-total bordered-valor-total">
                      <strong>Valor Total:</strong> ${data.total}
                    </div>
                    <div class="e-card-estagio bordered-estagio">
                      <strong>Estágio:</strong> ${data.estágio_ordem_de_compra || "N/A"}
                  </div>
                  </div>
                </div>
                `;
						} else if (data.doctype == "Cotacao") {
							const expirationColor = getExpirationColor(data.data_expiracao);
							return `
            <div class="e-card-grid trello-card">
              <div class="e-card-header">
                <strong>
                  <a href="${window.location.origin}/app/cotacao/${
								data.name
							}#principal_tab" target="_blank">
                      <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${
							data.name || "N/A"
						}
                  </a>
                </strong>
              </div>
              <div>
                <div class="e-card-content">
                  <div class="e-card-fornecedor bordered-fornecedor">
                    <strong>Fornecedor 1:</strong> ${
						data.fornecedor_cotacao[0]?.descricao_fornecedor || "N/A"
					}
                  </div>
                  <div class="e-card-fornecedor bordered-fornecedor">
                    <strong>Fornecedor 2:</strong> ${
						data.fornecedor_cotacao[1]?.descricao_fornecedor || "N/A"
					}
                  </div>
                  <div class="e-card-fornecedor bordered-fornecedor">
                    <strong>Fornecedor 3:</strong> ${
						data.fornecedor_cotacao[2]?.descricao_fornecedor || "N/A"
					}
                  </div>
                  <div class="e-card-itens bordered-itens clickable" onclick="openModal('${
						data.name
					}')">
                    <strong><i class="fas fa-cogs icon-itens"></i> Itens (${
						data.itens.length
					})</strong>
                  </div>
                  <div class="e-card-data-emissao bordered-data-emissao">
                    <strong>Data de Emissão:</strong> ${formatarData(data.creation || "N/A")}
                  </div>
                  <div class="e-card-data-expiracao bordered-data-expiracao" style="background-color: ${expirationColor};">
                    <strong>Data de Expiração:</strong> ${formatarData(
						data.data_expiracao || "N/A"
					)}
                  </div>
                  <div class="e-card-estagio bordered-estagio">
                    <strong>Estágio:</strong> ${data.estagio_da_cotacao || "N/A"}
                  </div>
                </div>
              </div>
            </div>
              `;
						}
					},
				},
				dialogOpen: "dialogOpen",
				// Responsividade
				width: "100%",
				height: "auto",
				minWidth: "300px",
				minHeight: "400px",
			});
			real_time();
			kanbanObj.appendTo(this.element);
		} catch (e) {
			console.error("Error initializing SyncFusionKanban:", e);
		}
	}

	refreshKanban() {
		kanbanObj.refresh();
	}
}

function sortByDate(data, dateField) {
	return data.sort((a, b) => new Date(a[dateField]) - new Date(b[dateField]));
}

const real_time = () => {
	frappe.realtime.on("dashboard", async (data) => {
		const get_dados = await frappe.call(
			"nxlite.nx_estoque.page.kanban_de_compras.controller.get_dados"
		);
		setTimeout(() => {
			const sortedData = sortByDate(get_dados.message, "data_da_necessidade");
			kanbanObj.dataSource = sortedData;
			updateModalContent();
			updateDropDownList(sortedData); // Atualizar DropDownList
		}, 1500);
		const sortedData = sortByDate(get_dados.message, "data_da_necessidade");
		kanbanObj.dataSource = sortedData;
		kanbanObj.refresh();
		updateModalContent();
		updateDropDownList(sortedData); // Atualizar DropDownList
	});
};

// Função para atualizar o DropDownList em tempo real
function updateDropDownList(dataSource) {
	const empresas = dataSource
		.map((item) => item.nome_empresa)
		.filter((value, index, self) => self.indexOf(value) === index);
	const dropDownList = document.querySelector("#dropdownElement").ej2_instances[0];
	dropDownList.dataSource = empresas;
	dropDownList.refresh();
}

// Função para atualizar o conteúdo do modal em tempo real
function updateModalContent() {
	const modal = document.querySelector(".modal");
	if (modal) {
		const cotacaoName = modal.querySelector("h2").textContent.split(": ")[1];
		const cotacao = kanbanObj.dataSource.find((item) => item.name === cotacaoName);
		if (cotacao) {
			const modalTableBody = modal.querySelector(".modal-table tbody");
			modalTableBody.innerHTML = cotacao.itens
				.map(
					(item) => `
        <tr>
          <td>${item.codigo_item}</td>
          <td>${item.descricao_item || ""}</td>
          <td>${item.quantidade}</td>
          <td>${item.unidade_de_compra}</td>
          <td>${item.valor_total}</td>
        </tr>
      `
				)
				.join("");
		}
	}
}

// Função para formatar a data no formato DD-MM-YYYY
function formatarData(data) {
	if (!data) return "N/A";
	const date = new Date(data);
	if (isNaN(date)) return "N/A";
	const dia = String(date.getUTCDate()).padStart(2, "0");
	const mes = String(date.getUTCMonth() + 1).padStart(2, "0");
	const ano = date.getUTCFullYear();
	return `${dia}-${mes}-${ano}`;
}

function dialogOpen(args) {
	args.cancel = false;
}

// Função para abrir o modal
window.openModal = function (Name) {
	// Lógica para abrir o modal

	// Obter os dados da cotação
	const cotacao = kanbanObj.dataSource.find((item) => item.name === Name);
	if (!cotacao) return;

	// Criar o conteúdo do modal
	const modalContent = `
    <div class="modal-content">
      <h2>Itens da ${
			cotacao.doctype === "Ordem de Compra" ? "Ordem de Compra" : "Cotação"
		} : ${Name}</h2>
      <div class="modal-table-container">
        <table class="modal-table">
          <thead>
            <tr>
              <th>Código do Item</th>
              <th>Descrição do Item</th>
              <th>Quantidade</th>
              <th>Unidade</th>
              ${cotacao.doctype === "Ordem de Compra" ? "<th>Valor Unitário</th>" : ""}
              ${cotacao.doctype === "Ordem de Compra" ? "<th>Valor Total</th>" : ""}
            </tr>
          </thead>
          <tbody>
            ${cotacao.itens
				.map(
					(item) => `
              <tr>
                <td>${item.codigo_item}</td>
                <td>${item.descricao_item || ""}</td>
                <td>${item.quantidade}</td>
                <td>${item.unidade_de_compra}</td>
                ${cotacao.doctype === "Ordem de Compra" ? `<td>${item.valor_unitario}</td>` : ""}
                ${cotacao.doctype === "Ordem de Compra" ? `<td>${item.valor_total}</td>` : ""}
              </tr>
            `
				)
				.join("")}
          </tbody>
        </table>
      </div>
      <button onclick="closeModal()">Fechar</button>
    </div>
  `;

	// Exibir o modal
	const modal = document.createElement("div");
	modal.classList.add("modal");
	modal.innerHTML = modalContent;
	document.body.appendChild(modal);
};

// Função para fechar o modal
window.closeModal = function () {
	const modal = document.querySelector(".modal");
	if (modal) {
		modal.remove();
	}
};

// Adicionar estilos para o modal
// const style = document.createElement("style");
// style.innerHTML = `
//   .modal {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.5);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   .modal-content {
//     background: white;
//     padding: 20px;
//     border-radius: 5px;
//     max-width: 500px;
//     width: 100%;
//     max-height: 80%;
//     overflow-y: auto;
//   }
//   .modal-table-container {
//     max-height: 300px;
//     overflow-y: auto;
//   }
//   .modal-table {
//     width: 100%;
//     border-collapse: collapse;
//   }
//   .modal-table th, .modal-table td {
//     border: 1px solid #ddd;
//     padding: 8px;
//   }
//   .modal-table th {
//     background-color: #f2f2f2;
//   }
//   .clickable {
//     cursor: pointer;
//   }
// `;
// document.head.appendChild(style);

//Função para obter a cor de acordo com a data de expiração

function getExpirationColor(expirationDate) {
	const currentDate = new Date();
	const expDate = new Date(expirationDate);
	const diffTime = expDate - currentDate;
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	if (diffDays > 5) {
		return "#00bf63"; // Verde
	} else if (diffDays >= 0 && diffDays <= 5) {
		return "#ffde59"; // Amarelo
	} else {
		return "#ff3131"; // Vermelho
	}
}

class ParetoChart {
	constructor({ wrapper }) {
		if (!wrapper) {
			throw new Error("Wrapper is required to initialize ParetoChart.");
		}
		this.wrapper = wrapper;
		this.selectedRecurso = null;
		this.selectedCentroCusto = null;
		this.selectedPeriod = null;
		this.startDate = null;
		this.endDate = null;
		this.paretoChart = null;
	}

	async initSyncfusionCharts() {
		const syncfusionScript = document.createElement("script");
		syncfusionScript.src = "https://cdn.syncfusion.com/ej2/20.2.36/dist/ej2.min.js";
		document.head.appendChild(syncfusionScript);

		const syncfusionStyles = document.createElement("link");
		syncfusionStyles.rel = "stylesheet";
		syncfusionStyles.href = "https://cdn.syncfusion.com/ej2/20.2.36/material.css";
		document.head.appendChild(syncfusionStyles);

		syncfusionScript.onload = async () => {
			ej.base.registerLicense(
				"ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXd0dgWX9WdXJXQGFUWEM="
			);

			const paretoPanelId = "panel-" + Math.random().toString(36).substr(2, 9);
			const paretoPanelContent = document.createElement("div");
			paretoPanelContent.id = `${paretoPanelId}-content`;

			const chartHeader = document.createElement("div");
			chartHeader.className = "chart-header";
			chartHeader.innerText = "PARETO DAS PARADAS";
			this.wrapper.appendChild(chartHeader);

			const filterContainer = document.createElement("div");
			filterContainer.className = "filter-container";
			filterContainer.innerHTML = `
                <select id="recursoDropdown">
                    <option value="">Selecionar Recurso</option>
                </select>
                <select id="centroCustoDropdown">
                    <option value="">Selecionar Centro de Custo</option>
                </select>
                <select id="period_filter">
                    <option value="1_month">Último Mês</option>
                    <option value="3_months">Últimos 3 Meses</option>
                    <option value="6_months">Últimos 6 Meses</option>
                    <option value="1_year">Último Ano</option>
                    <option value="custom">Período Customizado</option>
                </select>
                <div id="custom_period_controls" style="display: none;">
                    <input type="text" id="date_range" placeholder="Selecione o período">
                </div>
                <button id="applyFiltersButton">
                    <img src="https://img.icons8.com/ios-filled/50/ffffff/search.png" alt="Pesquisar" style="width: 16px; height: 16px;">
                </button>
            `;
			chartHeader.appendChild(filterContainer);

			const style = document.createElement("style");
			style.textContent = `
                select {
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    background-color: #fff;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                    appearance: none;
                    outline: none;
                    cursor: pointer;
                }

                select:focus {
                    border-color: #0078d4;
                    box-shadow: 0 0 3px #0078d4;
                }

                select::-ms-expand {
                    display: none;
                }

                .filter-container {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    gap: 10px;
                    width: calc(100% - 10px);
                }

                .filter-container select, 
                .filter-container button {
                    flex: 0 1 auto;
                }

                .filter-container button {
                    padding: 5px 10px;
                    font-size: 14px;
                    border: none;
                    border-radius: 4px;
                    background-color: #0078d4;
                    color: white;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .filter-container button:hover {
                    background-color: #005a9e;
                }

                #paretoPanel {
                    overflow: hidden;
                }

                #paretoPanel-content {
                    max-width: 100%;
                    height: auto;
                }
            `;
			document.head.appendChild(style);

			this.wrapper.appendChild(paretoPanelContent);

			document.getElementById("period_filter").addEventListener("change", (event) => {
				const customPeriodControls = document.getElementById("custom_period_controls");
				if (event.target.value === "custom") {
					customPeriodControls.style.display = "inline-block";
				} else {
					customPeriodControls.style.display = "none";
				}
			});

			// Initialize date range picker
			const dateRangePicker = new ej.calendars.DateRangePicker({
				placeholder: "Selecione o período",
				format: "dd/MM/yyyy",
				change: (args) => {
					this.startDate = args.startDate;
					this.endDate = args.endDate;
				},
			});
			dateRangePicker.appendTo("#date_range");

			document.getElementById("applyFiltersButton").addEventListener("click", () => {
				this.selectedRecurso = document.getElementById("recursoDropdown").value;
				this.selectedCentroCusto = document.getElementById("centroCustoDropdown").value;
				this.selectedPeriod = document.getElementById("period_filter").value;
				this.updateParetoChart();
			});

			await this.populateDropdowns();
			await this.renderParetoChart(paretoPanelId);

			this.handleResize(paretoPanelId);
			window.addEventListener("resize", () => this.handleResize(paretoPanelId));
		};
	}

	async populateDropdowns() {
		const apontamento = await frappe.call({
			method: "nxlite.nx_producao.page.minha_producao.controller.pareto_parada",
		});

		const recursos = [...new Set(apontamento.message.map((item) => item.recurso))];
		const centroCustos = [...new Set(apontamento.message.map((item) => item.centro_custo))];

		const recursoDropdown = document.getElementById("recursoDropdown");
		recursos.forEach((recurso) => {
			const option = document.createElement("option");
			option.value = recurso;
			option.text = recurso;
			recursoDropdown.appendChild(option);
		});

		const centroCustoDropdown = document.getElementById("centroCustoDropdown");
		centroCustos.forEach((centroCusto) => {
			const option = document.createElement("option");
			option.value = centroCusto;
			option.text = centroCusto;
			centroCustoDropdown.appendChild(option);
		});
	}

	handleResize(panelId) {
		if (this.paretoChart) {
			const panelContent = document.getElementById(`${panelId}-content`);
			const parentWidth = panelContent.offsetWidth;

			// Adjust size based on parent
			this.paretoChart.width = `${Math.max(300, parentWidth)}px`;
			this.paretoChart.height = panelContent.offsetHeight + "px";
			this.paretoChart.refresh();
		}
	}

	async renderParetoChart(panelId) {
		const apontamento = await frappe.call({
			method: "nxlite.nx_producao.page.minha_producao.controller.pareto_parada",
		});
		// console.log('Apontamento', apontamento.message);

		let paretoData = apontamento.message
			.filter((item) => item.tipo === "Parada")
			.filter((item) => !this.selectedRecurso || item.recurso === this.selectedRecurso)
			.filter(
				(item) =>
					!this.selectedCentroCusto || item.centro_custo === this.selectedCentroCusto
			)
			.filter((item) => {
				const itemDate = new Date(item.dt_inicio);
				return (
					(!this.startDate || itemDate >= this.startDate) &&
					(!this.endDate || itemDate <= this.endDate)
				);
			})
			.map((item) => ({
				tipo_parada: item.desc_motivo_parada,
				horas: item.total_hr,
				cumulative_percentage: 0,
			}));

		// Sort paretoData in descending order based on total_hr
		paretoData.sort((a, b) => b.horas - a.horas);

		let cumulativeSum = 0;
		const totalHours = paretoData.reduce((sum, item) => sum + item.horas, 0);
		paretoData.forEach((item) => {
			cumulativeSum += item.horas;
			item.cumulative_percentage = (cumulativeSum / totalHours) * 100;
		});

		this.paretoChart = new ej.charts.Chart({
			primaryXAxis: { valueType: "Category", title: "" },
			primaryYAxis: { title: "Horas" },
			series: [
				{
					dataSource: paretoData,
					xName: "tipo_parada",
					yName: "horas",
					type: "Column",
					name: "Horas",
				},
				{
					type: "Line",
					dataSource: paretoData,
					xName: "tipo_parada",
					yName: "cumulative_percentage",
					yAxisName: "secondary",
					name: "Percentual",
					marker: { visible: true },
				},
			],
			axes: [
				{
					name: "secondary",
					opposedPosition: true,
					title: "Percentual",
					labelFormat: "{value}%",
					// maximum: 100
				},
			],
			tooltip: { enable: true },
			legendSettings: { visible: false, position: "Bottom" },
			width: "100%",
			height: "100%",
		});

		this.paretoChart.appendTo(`#${panelId}-content`);
	}

	async updateParetoChart() {
		if (this.paretoChart) {
			const apontamento = await frappe.call({
				method: "nxlite.nx_producao.page.minha_producao.controller.pareto_parada",
				args: {
					period: this.selectedPeriod,
					start_date: this.startDate,
					end_date: this.endDate,
				},
			});

			let paretoData = apontamento.message
				.filter((item) => item.tipo === "Parada")
				.filter((item) => !this.selectedRecurso || item.recurso === this.selectedRecurso)
				.filter(
					(item) =>
						!this.selectedCentroCusto || item.centro_custo === this.selectedCentroCusto
				)
				.filter((item) => {
					const itemDate = new Date(item.dt_inicio);
					return (
						(!this.startDate || itemDate >= this.startDate) &&
						(!this.endDate || itemDate <= this.endDate)
					);
				})
				.map((item) => ({
					tipo_parada: item.desc_motivo_parada,
					horas: item.total_hr,
					cumulative_percentage: 0, // This will be calculated later
				}));

			// Sort paretoData in descending order based on total_hr
			paretoData.sort((a, b) => b.horas - a.horas);

			let cumulativeSum = 0;
			const totalHours = paretoData.reduce((sum, item) => sum + item.horas, 0);
			paretoData.forEach((item) => {
				cumulativeSum += item.horas;
				item.cumulative_percentage = (cumulativeSum / totalHours) * 100;
			});

			this.paretoChart.series[0].dataSource = paretoData;
			this.paretoChart.series[1].dataSource = paretoData;
			this.paretoChart.refresh();
		}
	}
}

class SituacaoOpChart {
	constructor({ wrapper }) {
		this.wrapper = wrapper;
		this.initDateFilter();
		this.currentState = null; // Track the current navigation state
		this.navigationPath = []; // Track the navigation path
		window.addEventListener("resize", () => this.handleResize());
	}

	initDateFilter() {
		const dateFilter = document.createElement("select");
		dateFilter.innerHTML = `
            <option value="15">Próximos 15 dias</option>
            <option value="30">Próximos 30 dias</option>
            <option value="60">Próximos 60 dias</option>
        `;
		dateFilter.onchange = () => this.initSyncfusionCharts();
		this.wrapper.appendChild(dateFilter);
		this.dateFilter = dateFilter;

		this.navigationContainer = document.createElement("div");
		this.wrapper.appendChild(this.navigationContainer);

		this.chartContainer = document.createElement("div");
		this.wrapper.appendChild(this.chartContainer);
	}

	async initSyncfusionCharts() {
		const situ_op = await frappe.call({
			method: "nxlite.nx_producao.page.minha_producao.controller.situacao_op",
		});
		console.log("situ_op", situ_op.message);

		const data = this.processSituOpData(situ_op.message);

		this.renderChart(data, situ_op.message);
	}

	processSituOpData(situ_op) {
		const currentDate = new Date();
		const upcomingDate = new Date();
		upcomingDate.setDate(currentDate.getDate() + parseInt(this.dateFilter.value));
		const data = [];

		// OPs em Atraso
		const opsEmAtraso = situ_op.filter((op) => new Date(op.dt_entrega) < currentDate);
		const opsEmAtrasoData = this.groupOpsEmAtraso(opsEmAtraso);
		if (opsEmAtrasoData.length > 0) {
			data.push({
				x: "OPs em Atraso",
				y: opsEmAtrasoData.reduce((sum, group) => sum + group.y, 0),
				z: opsEmAtrasoData,
			});
		}

		// OPs Próximas da Entrega
		const opsProximasEntrega = situ_op.filter(
			(op) =>
				new Date(op.dt_entrega) >= currentDate && new Date(op.dt_entrega) <= upcomingDate
		);
		const opsProximasEntregaData = this.groupOpsProximasEntrega(opsProximasEntrega);
		if (opsProximasEntregaData.length > 0) {
			data.push({
				x: "OPs Próximas da Entrega",
				y: opsProximasEntregaData.reduce((sum, group) => sum + group.y, 0),
				z: opsProximasEntregaData,
			});
		}

		// OPs Restantes
		const opsRestantes = situ_op.filter((op) => new Date(op.dt_entrega) > upcomingDate);
		const opsRestantesData = this.groupOpsRestantes(opsRestantes);
		if (opsRestantesData.length > 0) {
			data.push({ x: "OPs Restantes", y: opsRestantesData.length, z: opsRestantesData });
		}

		return data;
	}

	groupOpsEmAtraso(ops) {
		const groups = {
			30: [],
			60: [],
			"60+": [],
		};

		ops.forEach((op) => {
			const delay = Math.floor(
				(new Date() - new Date(op.dt_entrega)) / (1000 * 60 * 60 * 24)
			);
			if (delay <= 30) {
				groups["30"].push(op);
			} else if (delay <= 60) {
				groups["60"].push(op);
			} else {
				groups["60+"].push(op);
			}
		});

		return Object.keys(groups)
			.map((key) => ({
				x: `${key} dias de atraso`,
				y: groups[key].length,
				z: this.groupByEntity(groups[key]),
			}))
			.filter((group) => group.y > 0);
	}

	groupOpsProximasEntrega(ops) {
		const groups = {
			5: [],
			10: [],
			15: [],
		};

		ops.forEach((op) => {
			const daysToDelivery = Math.floor(
				(new Date(op.dt_entrega) - new Date()) / (1000 * 60 * 60 * 24)
			);
			if (daysToDelivery <= 5) {
				groups["5"].push(op);
			} else if (daysToDelivery <= 10) {
				groups["10"].push(op);
			} else if (daysToDelivery <= 15) {
				groups["15"].push(op);
			}
		});

		return Object.keys(groups)
			.map((key) => ({
				x: `${key} dias para entrega`,
				y: groups[key].length,
				z: this.groupByEntity(groups[key]),
			}))
			.filter((group) => group.y > 0);
	}

	groupOpsRestantes(ops) {
		return this.groupByEntity(ops);
	}

	groupByEntity(ops) {
		const entityGroups = {};

		ops.forEach((op) => {
			const entity = op.entidade || "Sem entidade";
			if (!entityGroups[entity]) {
				entityGroups[entity] = [];
			}
			entityGroups[entity].push(op);
		});

		return Object.keys(entityGroups).map((entity) => ({
			x: entity,
			y: entityGroups[entity].length,
			z: entityGroups[entity].map((op) => ({
				x: op.name || "Sem Nome",
				y: 1,
				text: `${op.name || "Sem Nome"} - ${op.entidade || "Sem entidade"}`,
			})),
		}));
	}

	getDrilldownData(selectedCategory, situ_op, data) {
		let categoryData = [];

		if (selectedCategory.includes("dias de atraso")) {
			const delayDays = parseInt(selectedCategory.split(" ")[0]);
			categoryData = situ_op.filter((op) => {
				const delay = Math.floor(
					(new Date() - new Date(op.dt_entrega)) / (1000 * 60 * 60 * 24)
				);
				if (selectedCategory.includes("60+")) {
					return delay > 60;
				}
				return delay <= delayDays && delay > delayDays - 30;
			});
			return this.groupByEntity(categoryData);
		} else if (selectedCategory.includes("dias para entrega")) {
			const daysToDelivery = parseInt(selectedCategory.split(" ")[0]);
			categoryData = situ_op.filter((op) => {
				const days = Math.floor(
					(new Date(op.dt_entrega) - new Date()) / (1000 * 60 * 60 * 24)
				);
				return days <= daysToDelivery && days > daysToDelivery - 5;
			});
			return this.groupByEntity(categoryData);
		} else if (
			selectedCategory === "OPs em Atraso" ||
			selectedCategory === "OPs Próximas da Entrega" ||
			selectedCategory === "OPs Restantes"
		) {
			return data.find((d) => d.x === selectedCategory).z;
		} else {
			categoryData = situ_op.filter(
				(op) =>
					op.entidade === selectedCategory ||
					op.name === selectedCategory ||
					(selectedCategory === "Sem entidade" && !op.entidade)
			);
			return categoryData.map((op) => ({
				x: `${op.entidade || "Sem entidade"} - ${op.name}`,
				y: 1,
				text: `${op.entidade || "Sem entidade"} - ${op.name}`,
			}));
		}
	}

	renderChart(data, situ_op, previousState = null) {
		this.chartContainer.innerHTML = "";

		const totalOps = data.reduce((sum, category) => sum + category.y, 0);

		const chart = new ej.charts.AccumulationChart({
			series: [
				{
					dataSource: data.map((category) => ({
						...category,
						text: `${category.x} (${category.y}) - ${(
							(category.y / totalOps) *
							100
						).toFixed(2)}%`,
					})),
					xName: "x",
					yName: "y",
					pointColorMapping: "color",
					dataLabel: {
						visible: true,
						position: "Outside",
						name: "text",
					},
					type: "Pie",
				},
			],
			legendSettings: {
				visible: true,
			},
			tooltip: {
				enable: true,
			},
			enableSmartLabels: true,
			enableAnimation: true,
			title: "Situação das OPs",
			annotations: previousState
				? [
						{
							content:
								'<div id="black" style="cursor:pointer;padding:10px;width:70px; height:70px;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background:white;border-radius:50%;display:flex;align-items:center;justify-content:center;">' +
								'<img src="//ej2.syncfusion.com/demos/src/chart/images/back.png" id="back" alt="Back Icon" style="width:70%;height:70%;border-radius:50%;"/></div>',
							region: "Series",
							x: "50%",
							y: "50%",
						},
				  ]
				: [],
		});

		chart.appendTo(this.chartContainer);

		if (previousState) {
			document.querySelector("#black").addEventListener("click", () => {
				this.navigationPath.pop();
				this.updateNavigation();
				this.renderChart(previousState.data, situ_op, previousState.previousState);
			});
		}

		chart.pointClick = (args) => {
			const selectedCategory = args.point.x;
			this.navigationPath.push(selectedCategory);
			this.updateNavigation();
			const drilldownData = this.getDrilldownData(selectedCategory, situ_op, data);
			if (drilldownData.length > 0 && drilldownData.some((d) => d.z && d.z.length > 0)) {
				const nextState = { data, previousState };
				this.renderChart(drilldownData, situ_op, nextState);
			} else if (drilldownData.length > 0) {
				const nextState = { data, previousState };
				this.renderChart(drilldownData, situ_op, nextState);
			}
		};
	}

	updateNavigation() {
		this.navigationContainer.innerHTML = this.navigationPath.join(" > ");
	}

	handleResize() {
		const situacaoOpChart = this.wrapper.querySelector(".e-chart").ej2_instances[0];
		if (situacaoOpChart) {
			situacaoOpChart.width = "100%";
			situacaoOpChart.height = "100%";
			situacaoOpChart.refresh();
		}
	}
}

frappe.syncfusion.ParetoChart = ParetoChart;
frappe.syncfusion.SyncFusionKanban = SyncFusionKanban;
frappe.syncfusion.MeuFaturamento = MeuFaturamento;
frappe.syncfusion.SyncFusionTreeGrid = SyncFusionTreeGrid;
frappe.syncfusion.CargaMaquina = CargaMaquina;
frappe.syncfusion.BurndownChart = BurndownChart;
frappe.syncfusion.SyncFusionGantt = SyncFusionGantt;
frappe.syncfusion.SituacaoOpChart = SituacaoOpChart;
