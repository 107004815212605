import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_navbar = _resolveComponent("navbar")
  const _component_overlay_events = _resolveComponent("overlay_events")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_navbar),
    _createVNode(_component_overlay_events)
  ]))
}