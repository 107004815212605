<template>
    <div>
        <navbar />
        <overlay_events />
    </div>
</template>

<script>
import { useAppStore } from './stores/app'
import navbar from './app/navbar.vue';
import overlay_events from './app/overlay_events.vue';
export default {
    name: 'App',
    components: {
        navbar,
        overlay_events,
    },
    data() {
        return {
            count: 0
        }
    },
    computed: {
        appStore() {
            return useAppStore();
        },
    },
    async mounted() {
        // await this.appStore.reset()

        await this.appStore.initial_fetch()
        frappe.realtime.on('apontamento-web', (data) => {
            console.log('apontamento_web', data)
            this.appStore.update_store_apontamento(data)
        })
        frappe.realtime.on('recurso-web', (data) => {
            console.log('recurso-web', data)
            this.appStore.update_store_recurso(data)
        })
        frappe.realtime.on('operador-web', (data) => {
            console.log('operador-web', data)
            this.appStore.update_store_operador(data)
        })
        frappe.realtime.on('ordem_de_producao-web', (data) => {
            console.log('ordem_de_producao-web', data)
            this.appStore.update_store_ordem_de_producao(data)
        })
  
    }
}
</script>
<style></style>
