<template>
  <div class="text-center pa-4">
    <v-dialog v-model="appStore.Modal_fechar_apontamento_aberto" class="dialog-container">
      <v-card class="modal-card">
        <!-- Botão Fechar -->
        <v-icon icon class="close-btn" @click="appStore.Modal_fechar_apontamento_aberto = false">
          mdi-close
        </v-icon>

        <!-- Título -->
        <div class="modal-title">
          <h3 class="titulo">Deseja fechar o apontamento atual?</h3>
          <hr class="linha">
        </div>

        <!-- Inputs -->
        <div class="input-container">
          <p>Peças boas:</p>
          <v-text-field variant="outlined" v-model="boas" class="modal-input"></v-text-field>
        </div>
        <div class="input-container">
          <p>Peças refugadas:</p>
          <v-text-field variant="outlined" v-model="refugadas" class="modal-input"></v-text-field>
        </div>

        <!-- Divisória -->
        <v-divider></v-divider>

        <div style="  
        display: flex;
        justify-content: center;
        margin-top: 20px;">
          <v-btn class="submit-btn" @click="process_apontamento" v-ripple="false" ref="closeButton">
            Fechar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.dialog-container {
  max-width: 95%;
  width: 900px;
}

.modal-card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid black;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 20px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  transition: color 0.3s;
}

.close-btn:hover {
  color: #000;
}

.modal-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: 600;
}

.input-container {
  margin-bottom: 20px;
}

.modal-input {
  width: 100%;
}



.titulo {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
}

.linha {
  width: 100%;
  margin: 15px 0;
  border-top: 2px solid #ccc;
}

.submit-btn {
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  background-color: black;
  color: #fff;
}

/* Remover o estilo de foco ou contorno do botão */
.v-btn:focus {
  outline: none !important;
  /* Remove o contorno do botão ao focar */
  box-shadow: none !important;
  /* Remove qualquer sombra do botão */
}

/* Remover o estilo quando o botão está em estado "ativo" */
.v-btn:active {
  background-color: black !important;
  /* Cor do fundo no estado ativo */
  box-shadow: none !important;
  /* Remove a sombra quando pressionado */
}

/* Remove o estilo de foco e ativo no botão */
.submit-btn:focus,
.submit-btn:active {
  outline: none !important;
  box-shadow: none !important;
  background-color: black !important;
  /* Manter a cor original, se necessário */
}
</style>


<script>
import { useAppStore } from '../../../stores/app';

export default {
  name: "ModalFecharApontamentoAberto",
  data() {
    return {
      boas: 0,
      refugadas: 0
    }
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
  methods: {
    async process_apontamento(data) {
      console.log(this.appStore.selected_apontamento)
      this.appStore.close_apontamento(this.appStore.selected_apontamento.name, this.boas, this.refugadas)
      this.appStore.toggleModals('fechar')
      this.closeModalFecharApontamentoAberto()
      this.$nextTick(() => {
        if (this.$refs.closeButton) {
          this.$refs.closeButton.blur(); // Remove o foco do botão
        }
      });
    },
    openModalFecharApontamentoAberto() {
      this.Modal_fechar_apontamento_aberto = true;
    },
    closeModalFecharApontamentoAberto() {
      this.appStore.selectItem('apontamento', null)
      this.appStore.closeModalFecharApontamentoAberto();
      if (this.appStore.Modal_Apontamento_Aberto_Body) this.appStore.closeModalApontamentoAbertoBody()
    },
  },
};
</script>