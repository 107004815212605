frappe.producao.SituacaoOpChart = class {
    constructor({ wrapper }) {
        this.wrapper = wrapper;
        this.initDateFilter();
        this.currentState = null; // Track the current navigation state
        this.navigationPath = []; // Track the navigation path
        window.addEventListener('resize', () => this.handleResize());
    }

    initDateFilter() {
        const dateFilter = document.createElement('select');
        dateFilter.innerHTML = `
            <option value="15">Próximos 15 dias</option>
            <option value="30">Próximos 30 dias</option>
            <option value="60">Próximos 60 dias</option>
        `;
        dateFilter.onchange = () => this.initSyncfusionCharts();
        this.wrapper.appendChild(dateFilter);
        this.dateFilter = dateFilter;

        this.navigationContainer = document.createElement('div');
        this.wrapper.appendChild(this.navigationContainer);

        this.chartContainer = document.createElement('div');
        this.wrapper.appendChild(this.chartContainer);
    }

    async initSyncfusionCharts() {
        const situ_op = await frappe.call({
            method: "nxlite.nx_producao.page.minha_producao.controller.situacao_op"
        });
        console.log('situ_op', situ_op.message);

        const data = this.processSituOpData(situ_op.message);

        this.renderChart(data, situ_op.message);
    }

    processSituOpData(situ_op) {
        const currentDate = new Date();
        const upcomingDate = new Date();
        upcomingDate.setDate(currentDate.getDate() + parseInt(this.dateFilter.value));
        const data = [];

        // OPs em Atraso
        const opsEmAtraso = situ_op.filter(op => new Date(op.dt_entrega) < currentDate);
        const opsEmAtrasoData = this.groupOpsEmAtraso(opsEmAtraso);
        if (opsEmAtrasoData.length > 0) {
            data.push({ x: 'OPs em Atraso', y: opsEmAtrasoData.reduce((sum, group) => sum + group.y, 0), z: opsEmAtrasoData });
        }

        // OPs Próximas da Entrega
        const opsProximasEntrega = situ_op.filter(op => new Date(op.dt_entrega) >= currentDate && new Date(op.dt_entrega) <= upcomingDate);
        const opsProximasEntregaData = this.groupOpsProximasEntrega(opsProximasEntrega);
        if (opsProximasEntregaData.length > 0) {
            data.push({ x: 'OPs Próximas da Entrega', y: opsProximasEntregaData.reduce((sum, group) => sum + group.y, 0), z: opsProximasEntregaData });
        }

        // OPs Restantes
        const opsRestantes = situ_op.filter(op => new Date(op.dt_entrega) > upcomingDate);
        const opsRestantesData = this.groupOpsRestantes(opsRestantes);
        if (opsRestantesData.length > 0) {
            data.push({ x: 'OPs Restantes', y: opsRestantesData.length, z: opsRestantesData });
        }

        return data;
    }

    groupOpsEmAtraso(ops) {
        const groups = {
            '30': [],
            '60': [],
            '60+': []
        };

        ops.forEach(op => {
            const delay = Math.floor((new Date() - new Date(op.dt_entrega)) / (1000 * 60 * 60 * 24));
            if (delay <= 30) {
                groups['30'].push(op);
            } else if (delay <= 60) {
                groups['60'].push(op);
            } else {
                groups['60+'].push(op);
            }
        });

        return Object.keys(groups).map(key => ({
            x: `${key} dias de atraso`,
            y: groups[key].length,
            z: this.groupByEntity(groups[key])
        })).filter(group => group.y > 0);
    }

    groupOpsProximasEntrega(ops) {
        const groups = {
            '5': [],
            '10': [],
            '15': []
        };

        ops.forEach(op => {
            const daysToDelivery = Math.floor((new Date(op.dt_entrega) - new Date()) / (1000 * 60 * 60 * 24));
            if (daysToDelivery <= 5) {
                groups['5'].push(op);
            } else if (daysToDelivery <= 10) {
                groups['10'].push(op);
            } else if (daysToDelivery <= 15) {
                groups['15'].push(op);
            }
        });

        return Object.keys(groups).map(key => ({
            x: `${key} dias para entrega`,
            y: groups[key].length,
            z: this.groupByEntity(groups[key])
        })).filter(group => group.y > 0);
    }

    groupOpsRestantes(ops) {
        return this.groupByEntity(ops);
    }

    groupByEntity(ops) {
        const entityGroups = {};
    
        ops.forEach(op => {
            const entity = op.entidade || 'Sem entidade';
            if (!entityGroups[entity]) {
                entityGroups[entity] = [];
            }
            entityGroups[entity].push(op);
        });
    
        return Object.keys(entityGroups).map(entity => ({
            x: entity,
            y: entityGroups[entity].length,
            z: entityGroups[entity].map(op => ({
                x: op.name || 'Sem Nome',
                y: 1,
                text: `${op.name || 'Sem Nome'} - ${op.entidade || 'Sem entidade'}`
            }))
        }));
    }

    getDrilldownData(selectedCategory, situ_op, data) {
        let categoryData = [];

        if (selectedCategory.includes('dias de atraso')) {
            const delayDays = parseInt(selectedCategory.split(' ')[0]);
            categoryData = situ_op.filter(op => {
                const delay = Math.floor((new Date() - new Date(op.dt_entrega)) / (1000 * 60 * 60 * 24));
                if (selectedCategory.includes('60+')) {
                    return delay > 60;
                }
                return delay <= delayDays && delay > (delayDays - 30);
            });
            return this.groupByEntity(categoryData);
        } else if (selectedCategory.includes('dias para entrega')) {
            const daysToDelivery = parseInt(selectedCategory.split(' ')[0]);
            categoryData = situ_op.filter(op => {
                const days = Math.floor((new Date(op.dt_entrega) - new Date()) / (1000 * 60 * 60 * 24));
                return days <= daysToDelivery && days > (daysToDelivery - 5);
            });
            return this.groupByEntity(categoryData);
        } else if (selectedCategory === 'OPs em Atraso' || selectedCategory === 'OPs Próximas da Entrega' || selectedCategory === 'OPs Restantes') {
            return data.find(d => d.x === selectedCategory).z;
        } else {
            categoryData = situ_op.filter(op => op.entidade === selectedCategory || op.name === selectedCategory || (selectedCategory === 'Sem entidade' && !op.entidade));
            return categoryData.map(op => ({
                x: `${op.entidade || 'Sem entidade'} - ${op.name}`,
                y: 1,
                text: `${op.entidade || 'Sem entidade'} - ${op.name}`
            }));
        }
    }

    renderChart(data, situ_op, previousState = null) {
        this.chartContainer.innerHTML = '';

        const totalOps = data.reduce((sum, category) => sum + category.y, 0);

        const chart = new ej.charts.AccumulationChart({
            series: [{
                dataSource: data.map(category => ({
                    ...category,
                    text: `${category.x} (${category.y}) - ${((category.y / totalOps) * 100).toFixed(2)}%`
                })),
                xName: 'x',
                yName: 'y',
                pointColorMapping: 'color',
                dataLabel: {
                    visible: true,
                    position: 'Outside',
                    name: 'text'
                },
                type: 'Pie'
            }],
            legendSettings: {
                visible: true
            },
            tooltip: {
                enable: true
            },
            enableSmartLabels: true,
            enableAnimation: true,
            title: 'Situação das OPs',
            annotations: previousState ? [{
                content: '<div id="black" style="cursor:pointer;padding:10px;width:70px; height:70px;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background:white;border-radius:50%;display:flex;align-items:center;justify-content:center;">' +
                '<img src="//ej2.syncfusion.com/demos/src/chart/images/back.png" id="back" alt="Back Icon" style="width:70%;height:70%;border-radius:50%;"/></div>',
                region: 'Series',
                x: '50%',
                y: '50%'
            }] : []
        });

        chart.appendTo(this.chartContainer);

        if (previousState) {
            document.querySelector('#black').addEventListener('click', () => {
                this.navigationPath.pop();
                this.updateNavigation();
                this.renderChart(previousState.data, situ_op, previousState.previousState);
            });
        }

        chart.pointClick = (args) => {
            const selectedCategory = args.point.x;
            this.navigationPath.push(selectedCategory);
            this.updateNavigation();
            const drilldownData = this.getDrilldownData(selectedCategory, situ_op, data);
            if (drilldownData.length > 0 && drilldownData.some(d => d.z && d.z.length > 0)) {
                const nextState = { data, previousState };
                this.renderChart(drilldownData, situ_op, nextState);
            } else if (drilldownData.length > 0) {
                const nextState = { data, previousState };
                this.renderChart(drilldownData, situ_op, nextState);
            }
        };
    }

    updateNavigation() {
        this.navigationContainer.innerHTML = this.navigationPath.join(' > ');
    }

    handleResize() {
        const situacaoOpChart = this.wrapper.querySelector('.e-chart').ej2_instances[0];
        if (situacaoOpChart) {
            situacaoOpChart.width = '100%';
            situacaoOpChart.height = '100%';
            situacaoOpChart.refresh();
        }
    }
}

