<template>
    <div class="text-center">
        <v-dialog v-model="appStore.Modal_Apontamento_Aberto_Head" max-width="900px">
            <v-card class="pa-3">
                <v-icon @click="closeModalApontamentoAbertoHead" class="mb-2" size="24px">mdi-close</v-icon>
                <div class="text-center mb-4">
                    <h3 class="text-h6 font-weight-medium">Detalhes da OP - {{
                        appStore.selected_apontamento?.ordem_de_producao.name || "-" }}</h3>
                   <hr>
                </div>

                <v-row dense>
                    <v-col cols="12" sm="6">
                        <v-card outlined color="grey-lighten-2" class="pa-2">
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Descrição/Item: {{ appStore.selected_apontamento?.descricao || "-" }}</v-card-text>
                            <hr>
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Operador: {{ appStore.selected_apontamento?.nome || "-" }}</v-card-text>
                            <hr>
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Recurso: {{ appStore.selected_apontamento?.recurso.nome || "-" }}</v-card-text>
                            <hr>
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Data de entrega: {{ appStore.selected_apontamento ?
                                frappe.format(appStore.selected_apontamento.ordem_de_producao.dt_entrega, {"fieldtype":
                                "Datetime"}) : "-" }}</v-card-text>
                            <hr>
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Início: {{ appStore.selected_apontamento ?
                                frappe.format(appStore.selected_apontamento.ordem_de_producao.dt_inicio, {"fieldtype":
                                "Date"}) : "-" }}</v-card-text>
                            <hr>
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Quantidade: {{
                                appStore.selected_apontamento?.ordem_de_producao.quantidade || "-" }}</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card outlined color="grey-lighten-2" class="pa-2">
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Pedido: {{ appStore.selected_apontamento?.ordem_de_producao.pedido || "-" }}</v-card-text>
                            <hr>
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Desenho: {{ appStore.selected_apontamento?.ordem_de_producao.desenho || "-" }}</v-card-text>
                            <hr>
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Revisão: {{ appStore.selected_apontamento?.ordem_de_producao.revisao || "-" }}</v-card-text>
                            <hr>
                            <v-card-text :class="display == 'sm' ?  'font-weight-bold pb-9' : 'font-weight-bold'" style="font-size: 14px;">Posição: {{ appStore.selected_apontamento?.ordem_de_producao.posicao || "-" }}</v-card-text>
                            <hr>
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Estrutura: {{ appStore.selected_apontamento?.ordem_de_producao.estrutura || "-" }}</v-card-text>
                            <hr>
                            <v-card-text class="font-weight-bold" style="font-size: 14px;">Quantidade Realizada: {{
                                appStore.selected_apontamento?.ordem_de_producao.quantidade_realizada || "-" }}</v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <div>
                    <v-card color="grey-lighten-2" outlined class="pa-2 mt-3">
                        <v-card-text class="font-weight-bold" style="font-size: 14px;">Observação: {{ appStore.selected_apontamento?.ordem_de_producao.observacao || "-" }}</v-card-text>
                    </v-card>   
                </div>

                <div class="text-center mt-3">
                    <v-btn color="primary" small @click="closeModalApontamentoAbertoHead">OK</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>



<script>
import { useAppStore } from "../../../stores/app";
import { useDisplay } from "vuetify"

export default {

    name: "ModalApontamentoAbertoHead",
    data() {
        return {
            dialog: false,
            display: useDisplay().name
        };
    },
    computed: {
        appStore() {
            return useAppStore();
        },
    },
    methods: {
        openModalApontamentoAbertoHead() {
            this.appStore.openModalApontamentoAbertoHead();
        },
        closeModalApontamentoAbertoHead() {
            this.appStore.closeModalApontamentoAbertoHead();
            this.appStore.selectItem("apontamento", null);
            this.appStore.selectItem("ordem_de_producao", null);

        },
    },
};
</script>