<template>
  <div class="text-center pa-4">
    <v-dialog 
      v-model="appStore.Modal_Motivo_De_Parada" 
      :height="display === 'xs' || display === 'sm' ? '85%' : '65%'"
      :width="display === 'xs' || display === 'sm' ? '90%' : '50%'">
      <v-card class="pa-4 rounded elevation-2">
   
        <div class="d-flex justify-end">
          <v-icon @click="closeModalMotivoDeParada()" color="grey darken-1" class="ma-2">mdi-close</v-icon>
        </div>

        <div class="text-center mb-2">
          <h3 
          style="font-weight: 600" 
          color="#333" 
          class="text-h5 text-md-h4">Selecione o Motivo de Parada:</h3>
        </div>

        <hr>

        <v-row justify="center" align="center" class="pa-1">
          <v-col cols="12" class="mb-1" 
                 v-for="(mp, index) in appStore.motivos_de_parada" :key="index">
            <v-btn class="text-truncate rounded-pill" color="primary" block @click="selectMotivoDeParada(mp)">
              {{ mp.descricao }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>





<script>
import { useAppStore } from '../../../stores/app';
import {useDisplay} from 'vuetify'

export default {
  name: "ModalMotivoDeParada",
  data() {
    return {
      display: useDisplay().name,
      dialog: false,
    };
  },
  computed: {
    appStore() {
      return useAppStore();
    }
  },
  watch: {
    'appStore.Modal_Motivo_De_Parada'(newValue) {
      this.isModalOpen = newValue;
    }
  },
  methods: {
    async selectMotivoDeParada(data) {
      this.appStore.selectItem('motivo_de_parada', data)
      if (this.appStore.Modal_Apontamento_Aberto_Body) {
        await this.appStore.break_selected_apt()
        this.appStore.closeModalApontamentoAbertoBody()
      }
      this.appStore.closeModalMotivoDeParada();
      console.log("TESTEEEEEE",data)
    },
    closeModalMotivoDeParada() {
      this.appStore.closeModalMotivoDeParada();
      this.appStore.closeModalApontamentoAbertoBody();
     
    }
  },
};
</script>
