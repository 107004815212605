import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_card_apontamento_aberto = _resolveComponent("card_apontamento_aberto")
  const _component_v_col = _resolveComponent("v-col")
  const _component_card_apontamento_parada = _resolveComponent("card_apontamento_parada")
  const _component_card_ordem_de_producao = _resolveComponent("card_ordem_de_producao")
  const _component_card_recurso = _resolveComponent("card_recurso")
  const _component_card_operador = _resolveComponent("card_operador")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createCommentVNode(" Apontamentos de Produção "),
      _createVNode(_component_v_row, {
        class: "px-5",
        style: _normalizeStyle({ marginTop: $data.display === 'xs' ? '210px' : '210px' })
      }, {
        default: _withCtx(() => [
          ($options.appStore.type_view == 'apontamentos_producao' && $options.appStore.render.length != 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($options.appStore.render, (apontamento, index) => {
                return (_openBlock(), _createBlock(_component_v_col, _mergeProps({
                  class: $data.display != 'xs' ? 'pb-0 mb-0 mt-0' : 'pa-0 mt-0 mb-0 mr-0 ml-0',
                  style: {"height":"70%","justify-items":"center !important"},
                  cols: "12",
                  sm: "6",
                  md: "4",
                  lg: "3",
                  xl: "2",
                  key: apontamento.name
                }, apontamento), {
                  default: _withCtx(() => [
                    _createVNode(_component_card_apontamento_aberto, { apontamento: apontamento }, null, 8 /* PROPS */, ["apontamento"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["class"]))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Apontamentos de Parada "),
          ($options.appStore.type_view == 'apontamentos_parada' && $options.appStore.render.length != 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($options.appStore.render, (apontamento, index) => {
                return (_openBlock(), _createBlock(_component_v_col, _mergeProps({
                  class: $data.display != 'xs' ? 'pb-0 mb-0 mt-0' : 'pa-0 mt-0 mb-0 mr-0 ml-0',
                  style: {"height":"70%","justify-items":"center !important"},
                  cols: "12",
                  sm: "6",
                  md: "4",
                  lg: "3",
                  xl: "2",
                  key: apontamento.name
                }, apontamento), {
                  default: _withCtx(() => [
                    _createVNode(_component_card_apontamento_parada, { apontamento: apontamento }, null, 8 /* PROPS */, ["apontamento"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["class"]))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Ordens de Produção "),
          ($options.appStore.type_view == 'ordens_de_producao' && $options.appStore.render.length != 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList($options.appStore.render, (ordem_de_producao, index) => {
                return (_openBlock(), _createBlock(_component_v_col, _mergeProps({
                  class: $data.display != 'xs' ? 'pb-0 mb-0 mt-0' : 'pa-0 mt-0 mb-0 mr-0 ml-0',
                  style: {"height":"70%","justify-items":"center !important"},
                  cols: "12",
                  sm: "6",
                  md: "4",
                  lg: "3",
                  xl: "2",
                  key: ordem_de_producao.name
                }, ordem_de_producao), {
                  default: _withCtx(() => [
                    _createVNode(_component_card_ordem_de_producao, { ordem_de_producao: ordem_de_producao }, null, 8 /* PROPS */, ["ordem_de_producao"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["class"]))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Recursos "),
          ($options.appStore.type_view == 'recursos' && $options.appStore.render.length != 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList($options.appStore.render, (recurso, index) => {
                return (_openBlock(), _createBlock(_component_v_col, _mergeProps({
                  cols: "12",
                  sm: "4",
                  md: "4",
                  lg: "3",
                  xl: "2",
                  class: $data.display != 'xs' ? 'pb-0 mb-0 mt-0' : 'pa-0 mt-0 mb-0 mr-0 ml-0',
                  key: recurso.name
                }, recurso), {
                  default: _withCtx(() => [
                    _createVNode(_component_card_recurso, { recurso: recurso }, null, 8 /* PROPS */, ["recurso"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["class"]))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Operadores "),
          ($options.appStore.type_view == 'operadores' && $options.appStore.render.length != 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList($options.appStore.render, (operador, index) => {
                return (_openBlock(), _createBlock(_component_v_col, _mergeProps({
                  style: {"justify-items":"center !important"},
                  class: $data.display != 'xs' ? 'pb-0 mb-0 mt-0' : 'pa-0 mt-0 mb-0 mr-0 ml-0',
                  cols: "12",
                  sm: "4",
                  md: "4",
                  lg: "3",
                  xl: "2",
                  key: operador.name
                }, operador), {
                  default: _withCtx(() => [
                    _createVNode(_component_card_operador, { operador: operador }, null, 8 /* PROPS */, ["operador"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["class"]))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["style"]),
      _createCommentVNode(" <v-row  \n    class=\"px-5\" \n    :style=\"{ marginTop: display === 'xs' ? '36px' : '22px' }\">\n    </v-row> ")
    ]),
    _: 1 /* STABLE */
  }))
}