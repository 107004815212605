import { registerLicense } from "@syncfusion/ej2-base";

import { Button } from '@syncfusion/ej2-buttons';



class SyncFusionButton {
  constructor({ wrapper,elemento }) {
    try {
      this.$wrapper = $(wrapper);
      console.log("wrapper", wrapper);
      registerLicense(
        "Ngo9BigBOggjHTQxAR8/V1NCaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUX9acH1XR2BZVEZ1Xw=="
      );

      let button = new Button({ content: 'Button' });
      
      console.log("elemento", `.${wrapper}`);
      button.appendTo(`#${wrapper}`)

      console.log("button", button);

    } catch (e) {
      console.error(e);
    }
  }
}

frappe.provide("frappe.syncfusion");
frappe.syncfusion.SyncFusionButton = SyncFusionButton;

export default SyncFusionButton;
