import {
	TreeGrid,
	Page,
	Sort,
	Filter,
	Edit,
	Toolbar,
	VirtualScroll,
	InfiniteScroll,
} from "@syncfusion/ej2-treegrid";
import { ChildProperty, registerLicense } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-treegrid/styles/material.css";

TreeGrid.Inject(Page, Sort, Filter, Edit, Toolbar, VirtualScroll, InfiniteScroll);

class SyncFusionTreeGrid {
	constructor({ wrapper, element, data }) {
		registerLicense(
			"ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sdk1iWXtbcndcQmNc"
		);
		console.log("Registrando licença Syncfusion para TreeGrid..."); 

		try {
			if (!wrapper) {
				throw new Error("Wrapper is required to initialize SyncFusionTreeGrid.");
			}

			this.$wrapper = $(wrapper);
			this.element = element || "#TreeGrid";

			const treeGridData = Array.isArray(data)
				? data.map((item) => ({
						ElementEstrutura: item.ElementEstrutura,
						CustoEstrutura: item.CustoEstrutura.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
						Children: item.Children || [],
				  }))
				: [];

			const treeGrid = new TreeGrid({
				dataSource: treeGridData,
				childMapping: "Children",
				parentIdMapping: "ParentId",
				height: "1000px",
				idMapping: 'EstruturaId',
				InfiniteScrolling: true,
				pageSettings: {
					pageSize: 1000,
					pageCount: 5,
				},
				treeColumnIndex: 0,
				allowPaging: true,
				allowSorting: true,
				allowFiltering: true,
				allowEditing: true,
				editSettings: {
					allowAdding: true,
					allowEditing: true,
					allowDeleting: true,
					mode: "Row",
				},
				toolbar: ["Search", "ExpandAll", "CollapseAll"],
				columns: [
					{ field: "ElementEstrutura", headerText: "Descrição",isPrimaryKey: true, width: 30, textAlign: "Left" },
					{ field: "CustoEstrutura", headerText: "Custo", width: 30, textAlign: "Left" },
					
				],
				childIndent: 80,

				queryCellInfo: function (args) {
					if (args.column.field === 'ElementEstrutura') {
						console.log("argsssss", args);
						console.log("argsssssdata", args.data);
						
						let levelClass = args.cell.classList.value.match(/level(\d+)/);
						if (levelClass) {
							let level = parseInt(levelClass[1]);
							if (level < 2) {
							}
							args.cell.style.paddingLeft = `${level * 10}px`;
						}
					}
				}
				
				

			});

			treeGrid.appendTo(this.$wrapper[0]);
			console.log("TreeGrid rendered successfully.");
		} catch (e) {
			console.error("Error initializing SyncFusionTreeGrid:", e);
		}
	}
}

frappe.provide("frappe.treegrid");
frappe.treegrid.SyncFusionTreeGrid = SyncFusionTreeGrid;

export default SyncFusionTreeGrid;
