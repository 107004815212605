<template>
	<v-container fluid>
        <!-- formatação dos cards -->
		<v-row justify="start" class="mt-2">
			<v-col
				class="pt-0 pb-0 mt-0 mb-4"
				style="height: 70%; justify-items: center !important"
				cols="12"
				sm="6"
				md="3"
				v-if="appStore.cards"
				v-for="(card, index) in appStore.cards"
				:key="card.name"
				v-bind="card"
			>
				<Card :card="card" />
			</v-col>
		</v-row>
	</v-container>
</template>

<!-- <v-container fluid v-if="appStore.cards"> -->
<!-- Apontamentos de Produção -->
<!-- <v-row v-for="card in appStore.cards"> -->
<!-- <Card :card="card"></Card> -->
<!-- </v-row> -->
<!-- </v-container>  -->

<script>
import Card from "./card.vue";
import { useAppStore } from "../../stores/app";

export default {
	name: "OverlayEvents",
	components: { Card },
	computed: {
		appStore() {
			return useAppStore();
		},
	},
};
</script>
<style scoped></style>
