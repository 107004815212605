import { registerLicense } from "@syncfusion/ej2-base";
import { createApp } from "vue";
import { createVuetify } from "vuetify";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import meuFaturamentoApp from '../vue/meu-faturamento/app.vue';


class MeuFaturamento {
    constructor({ wrapper }) {
        try {
            registerLicense("Sua_licenca_aqui");
            this.$wrapper = $(wrapper);
            // Use o wrapper diretamente para passar ao Vue
            const app = createApp(meuFaturamentoApp);

            // Criação e configuração do Pinia (State Management)
            const pinia = createPinia();
            pinia.use(piniaPersist);

            // Definindo as configurações globais do Vue
            SetVueGlobals(app);

            // Use Vuetify e monte a aplicação
            app.use(createVuetify())
                .use(pinia)
                .mount(this.$wrapper.get(0)); // Monta no container passado como parâmetro
        } catch (e) {
            console.error("Erro ao montar a aplicação:", e);
        }
    }
}

frappe.provide("frappe.syncfusion");
frappe.syncfusion.MeuFaturamento = MeuFaturamento;

export default MeuFaturamento;
