import { defineStore } from "pinia";
declare let frappe: any;

export const useAppStore = defineStore({
    id: "app",
    state: () => ({
        group_by: null,
        cards: null as any[] | null,
        modal: true,
        configs: null,
        login: null,

    }),
    getters: {},

    actions: {
        async populate_by_config() {
            if (this.group_by) {
                try {

                    const doctype = this.group_by.tipo_dashboard === "Operadores da Lista" ? "Operador" : "Recurso";

                    const field_table = `${doctype}_table`.toLowerCase();

                    const filter_this_data = this.group_by[field_table];

                    const list = await frappe.db.get_list(doctype, {
                        fields: "*",
                        filters: filter_this_data.length
                            ? {
                                status: "Ativo",
                                name: ["in", filter_this_data.map(e => e[doctype.toLowerCase()])],
                            }
                            : { status: "Ativo" },
                    });
                    //Lógica que implementa os apontamentos à 'Recursos' e 'Operadores'.
                    const itensApontados = await Promise.all(
                        list.map(async (e) => {
                            const apontamentos = await frappe.db.get_list("Apontamento", {
                                fields: "*",
                                filters: {
                                    [`${this.group_by.tipo_dashboard === "Operadores da Lista" ? "operador" : "recurso"
                                        }`]: e.name,
                                },
                                order_by: "dt_inicio desc",
                            });
                            // Itera sobre os apontamentos e busca informações adicionais da "Ordem de Produção".
                            const apontamentosComOrdens = await Promise.all(
                                apontamentos.map(async (apontamento) => {
                                    const ordem = await frappe.db.get_list("Ordem de Producao", {
                                        fields: ["status_op", "dt_entrega", "cliente", "item"],
                                        filters: {
                                            name: apontamento.ordem_de_producao,
                                        },
                                    });

                                    const ordemDeProducao = ordem[0] || null;
                                    return {
                                        ...apontamento,
                                        ordem_de_producao: ordemDeProducao,
                                        dt_entrega: ordemDeProducao ? ordemDeProducao.dt_entrega : null
                                    };
                                })
                            );

                            return { ...e, apontamentos: apontamentosComOrdens };
                        })
                    );

                    this.cards = itensApontados;
                    console.log("Cards atualizados com apontamentos e ordem de produção: ", this.cards);
                } catch (error) {
                    console.error("Erro ao carregar dados por configuração:", error);
                }
            }
        },

        async initial_fetch() {
            const all_configs = await frappe.db.get_list("Configuracao Dashboard Web")
            const detailed = await all_configs.map(async ({ name }) => (
                await frappe.db.get_doc('Configuracao Dashboard Web', name)
            ))
            console.log('all_configs', await detailed)
            this.configs = await Promise.all(detailed);
        },
        select_config(config) {

        },
        update_apontamento(apontamento) {

        },
        update_operador(operador) {

        },
        update_recurso(recurso) {

        },
        persist: {
            enabled: true,

        },
        
    },
});