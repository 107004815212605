frappe.provide('frappe.nxlite.utils.integracao_bancaria');

const utils = {
    ping: () => { console.log('ping') },
    api: {
        pagador: {
            criar: async (json_req) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.criar_pagador',
                    { json_req },
                );
            },
            buscar: async (cnpj) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.buscar_pagador',
                    { cnpj },
                );
            },
            atualizar: async (cnpj, json_req) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.atualizar_pagador',
                    { cnpj, json_req },
                );
            },
            buscar_lista: async () => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.buscar_lista_pagador',
                    { json_req },
                );
            },
        },
        conta: {
            criar: async (cnpj, json_req) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.criar_conta',
                    { cnpj, json_req },
                );
            },
            deletar: async (cnpj, json_req) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.deletar_conta',
                    { cnpj, json_req },
                );
            },
            buscar_lista: async (cnpj) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.buscar_lista_conta',
                    { cnpj },
                );
            },
            buscar: async (cnpj, hash) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.buscar_conta',
                    { cnpj, hash },
                );
            },
            atualizar: async (cnpj, json_req) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.atualizar_conta',
                    { cnpj, json_req },
                );
            },
        },
        extrato: {
            ler: async (file_path) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.converte_ofx_pra_json',
                    {
                        file_path: `${frappe.boot.sitename}${file_path.includes('private') ? file_path : `/public${file_path}`}`
                    },
                );
            },
            enviar: async (cnpj, file_path) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.enviar_extrato',
                    {
                        cnpj,
                        file_path: `${frappe.boot.sitename}${file_path.includes('private') ? file_path : `/public${file_path}`}`
                    },
                );
            },
            buscar: async (cnpj, unique_id) => {
                return frappe.call(
                    'integracao_bancaria.integracao_bancaria.api.tecnospeed.buscar_extrato',
                    { cnpj, unique_id },
                );
            },
        },
    },
};

frappe.nxlite.utils.integracao_bancaria = utils;