frappe.provide('frappe.nxlite.utils.calculo_itens_orcamento_e_pedido');

const utils = {
    // funcoes que estavam no Orçamento
    uf_empresa: async (frm) => {
        const empresa = await frappe.db.get_doc('Empresa', frm.doc.empresa);
        const uf = await frappe.db.get_value(
            'Entidade',
            empresa.entidade,
            'estado_faturamento',
        );
        return uf.message.estado_faturamento;
    },
    uf_cliente: async (frm) => {
        const uf = await frappe.db.get_value(
            'Entidade',
            frm.doc.entidade,
            'estado_faturamento',
        );
        if (uf) {
            return uf.message.estado_faturamento;
        } else {
            return frappe.toast(
                'Entidade nao possui Estado de Faturamento cadastrado!',
            );
        }
    },
    cur_row_child: (cdt, cdn) => locals[cdt][cdn],
    has_value: (child, fieldname) =>
        child[fieldname] != 0 &&
        child[fieldname] != undefined &&
        child[fieldname] != null,
    decimal_format: (value, f = 2) => {
        const res = parseFloat(value);
        return parseFloat(res.toFixed(f)); //4
    },
    getFormattedValue: (obj, key) =>
        utils.has_value(obj, key) ? utils.decimal_format(obj[key]) : 0,
    calculo_orcamento_e_pedido: {
        calculo_preco_total: (preco_unitario, quantidade = 1) => {
            const preco_unit = utils.decimal_format(preco_unitario);
            const qtde = utils.decimal_format(quantidade);
            const preco_total = utils.decimal_format(preco_unit * qtde);
            return preco_total;
        },
        calc_perc_custo: (custo_total, preco_total) => {
            if (preco_total == 0) {
                return 0;
            }
            let perc_custo = utils.decimal_format(
                (custo_total / preco_total) * 100,
            );
            if (perc_custo < 0.01) {
                return 0.01;
            } else {
                return perc_custo;
            }
        },

        /**
         * @param {Array} itemRow - Trata-se da linha do item, locals[cdt][cdn]
         * @param {string} baseCalc - Será "preco" ou "custo" para os triggers serem alterados com base no custo unitario utilizar o "custo" para que os triggers sejam ativados pela alteração do Preço e com isso alterar o custo unitario  usar
         *
         */
        calc_preco: (itemRow) => {
            //sets
            const prev_perc_impostos = utils.getFormattedValue(
                itemRow,
                'perc_impostos',
            );
            const prev_perc_despesas = utils.getFormattedValue(
                itemRow,
                'perc_despesas',
            );
            const prev_perc_lucro = utils.getFormattedValue(
                itemRow,
                'perc_lucro',
            );
            const prev_vl_lucro = utils.getFormattedValue(itemRow, 'vl_lucro');
            const prev_perc_custo = utils.getFormattedValue(
                itemRow,
                'perc_custo',
            );
            const custo_unitario = utils.getFormattedValue(
                itemRow,
                'custo_unitario',
            );
            const qtde = utils.getFormattedValue(itemRow, 'qtde') || 1;
            const prev_perc_frete = utils.getFormattedValue(
                itemRow,
                'perc_frete',
            );
            const prev_perc_seguro = utils.getFormattedValue(
                itemRow,
                'perc_seguro',
            );
            const prev_perc_outras_despesas = utils.getFormattedValue(
                itemRow,
                'perc_outras_despesas',
            );
            const prev_valor_frete = utils.getFormattedValue(
                itemRow,
                'valor_frete',
            );
            const prev_preco_total = utils.getFormattedValue(
                itemRow,
                'preco_total',
            );
            const prev_valor_despesa_fixa = utils.getFormattedValue(
                itemRow,
                'valor_despesa_fixa',
            );
            const prev_valor_despesa_variavel = utils.getFormattedValue(
                itemRow,
                'valor_despesa_variavel',
            );
            const prev_valor_seguro = utils.getFormattedValue(
                itemRow,
                'valor_seguro',
            );
            const prev_valor_outras_despesas = utils.getFormattedValue(
                itemRow,
                'valor_outras_despesas',
            );
            const prev_preco_unitario = utils.getFormattedValue(
                itemRow,
                'preco_unitario',
            );
            const aliquota_ipi = utils.getFormattedValue(
                itemRow,
                'aliquota_ipi',
            );

            // VINICIUS NOVO
            if (custo_unitario > 0 && prev_preco_total > 0) {
                const custo_total = custo_unitario * qtde;
                const vl_lucro = prev_preco_total - custo_total;
                const perc_lucro = (vl_lucro / prev_preco_total) * 100;
                const perc_custo = (custo_total / prev_preco_total) * 100;
                const perc_frete = (prev_valor_frete / prev_preco_total) * 100;
                const perc_seguro =
                    (prev_valor_seguro / prev_preco_total) * 100;
                const perc_outras_despesas =
                    (prev_valor_outras_despesas / prev_preco_total) * 100;
                const valor_despesa_fixa =
                    (prev_perc_despesas / 100) * prev_preco_total;
                const valor_despesa_variavel =
                    (prev_perc_impostos / 100) * prev_preco_total;
                const valor_ipi = (aliquota_ipi / 100) * prev_preco_total;
                const preco_total_com_ipi = prev_preco_total + valor_ipi;

                const resultado = {
                    custo_total: utils.decimal_format(custo_total, 2),
                    preco_total: utils.decimal_format(prev_preco_total, 2),
                    perc_custo: utils.decimal_format(perc_custo, 4),
                    perc_frete: utils.decimal_format(perc_frete, 4),
                    perc_seguro: utils.decimal_format(perc_seguro, 4),
                    perc_outras_despesas: utils.decimal_format(
                        perc_outras_despesas,
                        4,
                    ),
                    vl_lucro: utils.decimal_format(vl_lucro, 2),
                    valor_despesa_fixa: utils.decimal_format(
                        valor_despesa_fixa,
                        2,
                    ),
                    valor_despesa_variavel: utils.decimal_format(
                        valor_despesa_variavel,
                        2,
                    ),
                    // preco_unitario: utils.decimal_format(preco_unitario,4),
                    valor_ipi: utils.decimal_format(valor_ipi, 2),
                    preco_total_com_ipi: utils.decimal_format(
                        preco_total_com_ipi,
                        2,
                    ),
                };
                console.log('resultado do custo unit', resultado);
                return resultado;
            }
        },

        calc_precoByPrecoUnitario: (itemRow) => {
            //sets
            const prev_perc_impostos = utils.getFormattedValue(
                itemRow,
                'perc_impostos',
            );
            const prev_perc_despesas = utils.getFormattedValue(
                itemRow,
                'perc_despesas',
            );
            const prev_perc_lucro = utils.getFormattedValue(
                itemRow,
                'perc_lucro',
            );
            const prev_vl_lucro = utils.getFormattedValue(itemRow, 'vl_lucro');
            const prev_perc_custo = utils.getFormattedValue(
                itemRow,
                'perc_custo',
            );
            const custo_unitario = utils.getFormattedValue(
                itemRow,
                'custo_unitario',
            );
            const qtde = utils.getFormattedValue(itemRow, 'qtde') || 1;
            const prev_perc_frete = utils.getFormattedValue(
                itemRow,
                'perc_frete',
            );
            const prev_perc_seguro = utils.getFormattedValue(
                itemRow,
                'perc_seguro',
            );
            const prev_perc_outras_despesas = utils.getFormattedValue(
                itemRow,
                'perc_outras_despesas',
            );
            const prev_valor_frete = utils.getFormattedValue(
                itemRow,
                'valor_frete',
            );
            const prev_preco_total = utils.getFormattedValue(
                itemRow,
                'preco_total',
            );
            const prev_valor_despesa_fixa = utils.getFormattedValue(
                itemRow,
                'valor_despesa_fixa',
            );
            const prev_valor_despesa_variavel = utils.getFormattedValue(
                itemRow,
                'valor_despesa_variavel',
            );
            const prev_valor_seguro = utils.getFormattedValue(
                itemRow,
                'valor_seguro',
            );
            const prev_valor_outras_despesas = utils.getFormattedValue(
                itemRow,
                'valor_outras_despesas',
            );
            const prev_preco_unitario = utils.getFormattedValue(
                itemRow,
                'preco_unitario',
            );
            const aliquota_ipi = utils.getFormattedValue(
                itemRow,
                'aliquota_ipi',
            );

            // VINICIUS NOVO
            const preco_unitario = prev_preco_unitario;
            const preco_total = prev_preco_total;
            const custo_total = custo_unitario * qtde;

            const valor_despesa_fixa =
                (prev_perc_despesas / 100) * prev_preco_total;

            const valor_despesa_variavel =
                (prev_perc_impostos / 100) * prev_preco_total;

            const vl_lucro =
                prev_preco_total -
                (prev_valor_despesa_fixa +
                    prev_valor_despesa_variavel +
                    prev_valor_frete +
                    prev_valor_seguro +
                    prev_valor_outras_despesas +
                    custo_total);

            const perc_lucro = (vl_lucro / prev_preco_total) * 100;
            const perc_frete = (prev_valor_frete / prev_preco_total) * 100;
            const perc_seguro = (prev_valor_seguro / prev_preco_total) * 100;
            const perc_outras_despesas =
                (prev_valor_outras_despesas / prev_preco_total) * 100;

            const valor_ipi = (aliquota_ipi / 100) * prev_preco_total;
            const preco_total_com_ipi = prev_preco_total + valor_ipi;
            const perc_custo = (custo_total / preco_total) * 100;

            const resultado = {
                preco_unitario: utils.decimal_format(preco_unitario, 4),
                preco_total: utils.decimal_format(preco_total, 2),
                custo_total: utils.decimal_format(custo_total, 2),
                valor_despesa_fixa: utils.decimal_format(valor_despesa_fixa, 2),
                valor_despesa_variavel: utils.decimal_format(
                    valor_despesa_variavel,
                    2,
                ),
                vl_lucro: utils.decimal_format(vl_lucro, 2),
                perc_lucro: utils.decimal_format(perc_lucro, 4),
                perc_frete: utils.decimal_format(perc_frete, 4),
                perc_seguro: utils.decimal_format(perc_seguro, 4),
                perc_outras_despesas: utils.decimal_format(
                    perc_outras_despesas,
                    4,
                ),
                valor_ipi: utils.decimal_format(valor_ipi, 2),
                preco_total_com_ipi: utils.decimal_format(
                    preco_total_com_ipi,
                    2,
                ),
                perc_custo: utils.decimal_format(perc_custo, 4),
            };
            console.log('resultado', resultado);
            return resultado;
        },

        calc_aliquota_efetiva_icms: (itemRow) => {
            //Verifica se o campo CST de ICMS está preenchido
            if (itemRow.cst_icms) {
                // Extrai o código do CST de ICMS
                let tributacao_icms = itemRow.tributacao_icms;

                switch (tributacao_icms) {
                    case '00': // '00 - Tributada integralmente':
                        return {
                            aliquota_efetiva_icms: itemRow.aliquota_icms,
                            base_efetiva_icms: itemRow.base_icms_percentual,
                        };
                    case '10' || '30': // '10 - Tributada e com cobrança do ICMS por substituição tributária' ||
                        // '30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária':
                        return {
                            aliquota_efetiva_icms:
                                itemRow.aliquota_icms_st *
                                (1 + itemRow.mva_iva / 100),
                            base_efetiva_icms:
                                itemRow.base_icms_percentual *
                                (1 + itemRow.mva_iva / 100),
                        };
                    case '20': // '20 - Com redução de base de cálculo':
                        return {
                            aliquota_efetiva_icms:
                                itemRow.aliquota_icms *
                                (1 - itemRow.percentual_reducao_bc / 100),
                            base_efetiva_icms:
                                itemRow.base_icms_percentual *
                                (1 - itemRow.percentual_reducao_bc / 100),
                        };
                    case '40' || '41' || '50': // '40 - Isenta' ||
                        // '41 - Não tributada' ||
                        // '50 - Suspensão':
                        return {
                            aliquota_efetiva_icms: 0,
                            base_efetiva_icms: 0,
                        };
                    case '51': // '51 - Diferimento':
                        return {
                            aliquota_efetiva_icms:
                                itemRow.aliquota_icms *
                                (1 - itemRow.diferimento_icms / 100),
                            base_efetiva_icms: 0,
                        };
                    case '70': // '70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária':
                        return {
                            aliquota_efetiva_icms:
                                ((1 -
                                    (itemRow.aliquota_icms / 100) *
                                        (1 -
                                            itemRow.percentual_reducao_bc /
                                                100)) /
                                    (1 - itemRow.aliquota_icms / 100) -
                                    1) *
                                100,
                            base_efetiva_icms: itemRow.base_icms_percentual,
                        };
                }
            } else {
                return {
                    aliquota_efetiva_icms: 0,
                    base_efetiva_icms: 0,
                };
            }
        },
        calc_perc_impostos: async (itemRow) => {
            const aliquota_efetiva_icms = itemRow.aliquota_efetiva_icms
                ? utils.decimal_format(itemRow.aliquota_efetiva_icms)
                : 0;
            const aliquota_cofins = itemRow.aliquota_cofins
                ? utils.decimal_format(itemRow.aliquota_cofins)
                : 0;
            const aliquota_irpj_industria = itemRow.aliquota_irpj_industria
                ? utils.decimal_format(itemRow.aliquota_irpj_industria)
                : 0;
            const aliquota_irpj_servicos = itemRow.aliquota_irpj_servicos
                ? utils.decimal_format(itemRow.aliquota_irpj_servicos)
                : 0;
            const aliquota_csll_industria = itemRow.aliquota_csll_industria
                ? utils.decimal_format(itemRow.aliquota_csll_industria)
                : 0;
            const aliquota_csll_servicos = itemRow.aliquota_csll_servicos
                ? utils.decimal_format(itemRow.aliquota_csll_servicos)
                : 0;
            const aliquota_issqn = itemRow.aliquota_issqn
                ? utils.decimal_format(itemRow.aliquota_issqn)
                : 0;
            const aliquota_simples_federal_produto =
                itemRow.aliquota_simples_federal_produto
                    ? utils.decimal_format(
                          itemRow.aliquota_simples_federal_produto,
                      )
                    : 0;
            const aliquota_simples_federal_servico =
                itemRow.aliquota_simples_federal_servico
                    ? utils.decimal_format(
                          itemRow.aliquota_simples_federal_servico,
                      )
                    : 0;
            const aliquota_pis = itemRow.aliquota_pis
                ? utils.decimal_format(itemRow.aliquota_pis)
                : 0;

            let perc_impostos =
                aliquota_efetiva_icms +
                aliquota_pis +
                aliquota_simples_federal_servico +
                aliquota_cofins +
                aliquota_irpj_industria +
                aliquota_irpj_servicos +
                aliquota_csll_industria +
                aliquota_csll_servicos +
                aliquota_issqn +
                aliquota_simples_federal_produto;

            return perc_impostos;
        },
        pedido: async (frm) => {
            const date = frappe.datetime.get_today();
            if (frm.doc.item_orcamento.length == 0) {
                frappe.msgprint({
                    title: __('Notification'),
                    indicator: 'yellow',
                    message: __('É necessário preencher a Itens - Estruturas'),
                });
            } else {
                const close_confirm = await new Promise((resolve) => {
                    frappe.confirm(
                        'Esta ação irá realizar o fechamento do Orçamento atual, você tem certeza disso?',
                        () => resolve(true),
                    );
                });
                const items = frm.doc.item_orcamento.map(async (element) => {
                    console.log('element viniciusss', element);
                    if (element.tipo == 'Item') {
                        if (close_confirm) {
                            return {
                                item: element.estrutura,
                                pedido: element.pedido,
                                n_item_pedido: element.n_item_pedido,
                                descrição_complementar:
                                    element.descricao_complementar,
                                custo_do_item: element.custo_unitario,
                                preco_unitario: element.preco_unitario,
                                qtde: element.qtde,
                                unidade_medida: element.unidade_medida,
                                preco_total: element.preco_total,
                                dt_entrega: element.dt_prevista,
                                valor_ipi: element.valor_ipi,
                                preco_total_com_ipi:
                                    element.preco_total_com_ipi,
                                perc_custo: element.perc_custo,
                                perc_lucro: element.perc_lucro,
                                perc_despesas: element.perc_despesas,
                                perc_impostos: element.perc_impostos,
                                perc_frete: element.perc_frete,
                                perc_seguro: element.perc_seguro,
                                perc_outras_despesas:
                                    element.perc_outras_despesas,
                                custo_unitario: element.custo_unitario,
                                vl_lucro: element.vl_lucro,
                                valor_despesa_fixa: element.valor_despesa_fixa,
                                valor_despesa_variavel:
                                    element.valor_despesa_variavel,
                                valor_frete: element.valor_frete,
                                valor_seguro: element.valor_seguro,
                                valor_outras_despesas:
                                    element.valor_outras_despesas,
                                custo_total: element.custo_total,
                                natureza_da_operacao:
                                    element.natureza_da_operacao,
                                cfop: element.cfop,
                                tipo_de_item: element.tipo_de_item,
                                faturado: element.faturado,
                                consumidor_final: element.consumidor_final,
                                csosn: element.csosn,
                                cst_icms: element.cst_icms,
                                tributacao_icms: element.tributacao_icms,
                                base_icms_percentual:
                                    element.base_icms_percentual,
                                aliquota_icms: element.aliquota_icms,
                                mod_deter_bc_icms: element.mod_deter_bc_icms,
                                motivo_deson_icms: element.motivo_deson_icms,
                                codigo_ajuste_apuracao:
                                    element.codigo_ajuste_apuracao,
                                codigo_beneficio_uf:
                                    element.codigo_beneficio_uf,
                                diferimento_icms: element.diferimento_icms,
                                reducao_icms: element.reducao_icms,
                                percentual_reducao_bc:
                                    element.percentual_reducao_bc,
                                icms_origem: element.icms_origem,
                                mva_iva: element.mva_iva,
                                perc_red_mva: element.perc_red_mva,
                                aliquota_efetiva_icms:
                                    element.aliquota_efetiva_icms,
                                base_efetiva_icms: element.base_efetiva_icms,
                                valor_bc_icms: element.valor_bc_icms,
                                valor_icms: element.valor_icms,
                                valor_icms_desonerado:
                                    element.valor_icms_desonerado,
                                mva_iva_ajustado: element.mva_iva_ajustado,
                                mod_deter_bc_icms_st:
                                    element.mod_deter_bc_icms_st,
                                aliquota_icms_st: element.aliquota_icms_st,
                                motivo_desoneracao_icms_st:
                                    element.motivo_desoneracao_icms_st,
                                reducao_icms_st: element.reducao_icms_st,
                                aliquota_fcp: element.aliquota_fcp,
                                cst_pis: element.cst_pis,
                                base_pis: element.base_pis,
                                reter_pis: element.reter_pis,
                                aliquota_pis: element.aliquota_pis,
                                desconsiderar_icms_calculo_pis:
                                    element.desconsiderar_icms_calculo_pis,
                                cst_cofins: element.cst_cofins,
                                base_cofins: element.base_cofins,
                                reter_cofins: element.reter_cofins,
                                aliquota_cofins: element.aliquota_cofins,
                                desconsiderar_icms_calculo_cofins:
                                    element.desconsiderar_icms_calculo_cofins,
                                aliquota_irpj_industria:
                                    element.aliquota_irpj_industria,
                                aliquota_irpj_servicos:
                                    element.aliquota_irpj_servicos,
                                reter_irpj: element.reter_irpj,
                                aliquota_csll_industria:
                                    element.aliquota_csll_industria,
                                aliquota_csll_servicos:
                                    element.aliquota_csll_servicos,
                                reter_csll: element.reter_csll,
                                tipo_tributacao_issqn:
                                    element.tipo_tributacao_issqn,
                                base_issqn: element.base_issqn,
                                reter_issqn: element.reter_issqn,
                                natureza_tributacao_issqn:
                                    element.natureza_tributacao_issqn,
                                aliquota_issqn: element.aliquota_issqn,
                                cst_ipi: element.cst_ipi,
                                cel_ipi: element.cel_ipi,
                                aliquota_ipi: element.aliquota_ipi,
                                soma_ipi_base_icms: element.soma_ipi_base_icms,
                                aliquota_simples_federal_produto:
                                    element.aliquota_simples_federal_produto,
                                aliquota_simples_federal_servico:
                                    element.aliquota_simples_federal_servico,
                                aplicavel_de_credito_icms_produto:
                                    element.aplicavel_de_credito_icms_produto,
                            };
                        }
                    } else {
                        const doc_empresa = await frappe.db.get_doc(
                            'Empresa',
                            frm.doc.empresa,
                        );

                        const item_padrao =
                            await doc_empresa.item_padrao_producao;

                        if (!item_padrao) {
                            frappe.throw(
                                'Item padrão não configurado nas configurações da empresa. <a href="#" onclick="window.open(\'/app/empresa/' +
                                    frm.doc.empresa +
                                    "', '_blank'); return false;\"><br><br>Ir para as configurações da Empresa</a>",
                            );
                        } else {
                            const estr_docs = frm.doc.item_orcamento.filter(
                                (estr) => {
                                    return estr.tipo == 'Estrutura de Produto';
                                },
                            );

                            const estrutura_doc_list = estr_docs.map(
                                async (estrutura) => {
                                    return await frappe.db.get_doc(
                                        'Estrutura de Produto',
                                        estrutura.estrutura,
                                    );
                                },
                            );

                            const estrutura_list = await Promise.all(
                                estrutura_doc_list,
                            );

                            const estrutura_name_list = estrutura_list.map(
                                (est_des) => {
                                    return est_des.descricao;
                                },
                            );

                            const item_estrutura_list =
                                await frappe.db.get_list('Item', {
                                    fields: ['descricao'],
                                });
                            const item_estrutura_name = item_estrutura_list.map(
                                (estr_des) => {
                                    return estr_des.descricao;
                                },
                            );

                            const estrutura_exists = estrutura_name_list.filter(
                                (name) => {
                                    return item_estrutura_name.includes(name);
                                },
                            );
                            const estrutura = await frappe.db.get_doc(
                                'Estrutura de Produto',
                                element.estrutura,
                            );
                            if (estrutura_exists.length > 0) {
                                const estrutura_item = estrutura.descricao;
                                const confirmed = await new Promise(
                                    (resolve, reject) => {
                                        frappe.confirm(
                                            `Este nome "${estrutura_item}" já existe no cadastro de Itens. Pretende continuar com o mesmo nome?`,
                                            () => resolve(true),
                                            () =>
                                                frappe.msgprint({
                                                    title: __('Notification'),
                                                    indicator: 'yellow',
                                                    message: __(
                                                        'É necessário alterar o nome na estrutura do item',
                                                    ),
                                                }),
                                        );
                                    },
                                );

                                if (confirmed) {
                                    if (close_confirm) {
                                        const default_item =
                                            await frappe.db.get_doc(
                                                'Item',
                                                item_padrao,
                                            );
                                        const codigo_random = Math.floor(
                                            Math.random() * 99999999,
                                        ).toString();

                                        const estrutura =
                                            await frappe.db.get_doc(
                                                'Estrutura de Produto',
                                                element.estrutura,
                                            );
                                        const obj_new_pedido = {
                                            codigo: codigo_random,
                                            // 'item':`Produto ${codigo_random}`,
                                            doctype: 'Item',
                                            estrutura: element.estrutura,
                                            descricao: estrutura.descricao,
                                            // descricao:"vinicius tentando",
                                            custo_do_item:
                                                element.custo_unitario,
                                        };

                                        const itemFields = [
                                            'cnpj_do_fabricante',
                                            'codigo_tipo_de_serviço',
                                            // "custo_do_item",
                                            'código_benefício_fiscal_na_uf',
                                            'código_de_barras',
                                            'código_ean_gtin',
                                            'descricao_tipo_de_servico',
                                            'dias_ressuprimento',
                                            'docstatus',
                                            'doctype',
                                            'epi_vestuario',
                                            'equipamento',
                                            'escala_relevante',
                                            'estoque_minimo',
                                            'fabricante',
                                            'fornecido_pelo_cliente',
                                            'manter_estoque',
                                            'materia_prima',
                                            'metodo_baixa',
                                            'metodo_ressuprimento',
                                            'numero_ncm',
                                            'permite_comprar',
                                            'permite_fabricar',
                                            'permite_vender',
                                            'ressuprimento_automático',
                                            'serviço_de_terceiros',
                                            'status',
                                            'tipo_de_item',
                                            'unidade_de_estoque',
                                            'familia_do_item',
                                            'grupo_item',
                                            'unidade_venda_padrao',
                                            'plano_contas',
                                            'unidade_medida',
                                        ];
                                        itemFields.forEach(
                                            (field) =>
                                                (obj_new_pedido[field] =
                                                    default_item[field]),
                                        );
                                        const new_item = await frappe.db.insert(
                                            obj_new_pedido,
                                        );
                                        return {
                                            item: await new_item.name,
                                            pedido: element.pedido,
                                            n_item_pedido:
                                                element.n_item_pedido,
                                            descrição_complementar:
                                                element.descricao_complementar,
                                            preco_unitario:
                                                element.preco_unitario,
                                            qtde: element.qtde,
                                            unidade_medida:
                                                element.unidade_medida,
                                            preco_total: element.preco_total,
                                            dt_entrega: element.dt_prevista,
                                            valor_ipi: element.valor_ipi,
                                            preco_total_com_ipi:
                                                element.preco_total_com_ipi,
                                            perc_custo: element.perc_custo,
                                            perc_lucro: element.perc_lucro,
                                            perc_despesas:
                                                element.perc_despesas,
                                            perc_impostos:
                                                element.perc_impostos,
                                            perc_frete: element.perc_frete,
                                            perc_seguro: element.perc_seguro,
                                            perc_outras_despesas:
                                                element.perc_outras_despesas,
                                            custo_do_item:
                                                element.custo_unitario,
                                            vl_lucro: element.vl_lucro,
                                            valor_despesa_fixa:
                                                element.valor_despesa_fixa,
                                            valor_despesa_variavel:
                                                element.valor_despesa_variavel,
                                            valor_frete: element.valor_frete,
                                            valor_seguro: element.valor_seguro,
                                            valor_outras_despesas:
                                                element.valor_outras_despesas,
                                            custo_total: element.custo_total,
                                            natureza_da_operacao:
                                                element.natureza_da_operacao,
                                            cfop: element.cfop,
                                            tipo_de_item: element.tipo_de_item,
                                            faturado: element.faturado,
                                            consumidor_final:
                                                element.consumidor_final,
                                            csosn: element.csosn,
                                            cst_icms: element.cst_icms,
                                            tributacao_icms:
                                                element.tributacao_icms,
                                            base_icms_percentual:
                                                element.base_icms_percentual,
                                            aliquota_icms:
                                                element.aliquota_icms,
                                            mod_deter_bc_icms:
                                                element.mod_deter_bc_icms,
                                            motivo_deson_icms:
                                                element.motivo_deson_icms,
                                            codigo_ajuste_apuracao:
                                                element.codigo_ajuste_apuracao,
                                            codigo_beneficio_uf:
                                                element.codigo_beneficio_uf,
                                            diferimento_icms:
                                                element.diferimento_icms,
                                            reducao_icms: element.reducao_icms,
                                            percentual_reducao_bc:
                                                element.percentual_reducao_bc,
                                            icms_origem: element.icms_origem,
                                            mva_iva: element.mva_iva,
                                            perc_red_mva: element.perc_red_mva,
                                            aliquota_efetiva_icms:
                                                element.aliquota_efetiva_icms,
                                            base_efetiva_icms:
                                                element.base_efetiva_icms,
                                            valor_bc_icms:
                                                element.valor_bc_icms,
                                            valor_icms: element.valor_icms,
                                            valor_icms_desonerado:
                                                element.valor_icms_desonerado,
                                            mva_iva_ajustado:
                                                element.mva_iva_ajustado,
                                            mod_deter_bc_icms_st:
                                                element.mod_deter_bc_icms_st,
                                            aliquota_icms_st:
                                                element.aliquota_icms_st,
                                            motivo_desoneracao_icms_st:
                                                element.motivo_desoneracao_icms_st,
                                            reducao_icms_st:
                                                element.reducao_icms_st,
                                            aliquota_fcp: element.aliquota_fcp,
                                            cst_pis: element.cst_pis,
                                            base_pis: element.base_pis,
                                            reter_pis: element.reter_pis,
                                            aliquota_pis: element.aliquota_pis,
                                            desconsiderar_icms_calculo_pis:
                                                element.desconsiderar_icms_calculo_pis,
                                            cst_cofins: element.cst_cofins,
                                            base_cofins: element.base_cofins,
                                            reter_cofins: element.reter_cofins,
                                            aliquota_cofins:
                                                element.aliquota_cofins,
                                            desconsiderar_icms_calculo_cofins:
                                                element.desconsiderar_icms_calculo_cofins,
                                            aliquota_irpj_industria:
                                                element.aliquota_irpj_industria,
                                            aliquota_irpj_servicos:
                                                element.aliquota_irpj_servicos,
                                            reter_irpj: element.reter_irpj,
                                            aliquota_csll_industria:
                                                element.aliquota_csll_industria,
                                            aliquota_csll_servicos:
                                                element.aliquota_csll_servicos,
                                            reter_csll: element.reter_csll,
                                            tipo_tributacao_issqn:
                                                element.tipo_tributacao_issqn,
                                            base_issqn: element.base_issqn,
                                            reter_issqn: element.reter_issqn,
                                            natureza_tributacao_issqn:
                                                element.natureza_tributacao_issqn,
                                            aliquota_issqn:
                                                element.aliquota_issqn,
                                            cst_ipi: element.cst_ipi,
                                            cel_ipi: element.cel_ipi,
                                            aliquota_ipi: element.aliquota_ipi,
                                            soma_ipi_base_icms:
                                                element.soma_ipi_base_icms,
                                            aliquota_simples_federal_produto:
                                                element.aliquota_simples_federal_produto,
                                            aliquota_simples_federal_servico:
                                                element.aliquota_simples_federal_servico,
                                            aplicavel_de_credito_icms_produto:
                                                element.aplicavel_de_credito_icms_produto,
                                        };
                                    }
                                }
                            } else {
                                if (close_confirm) {
                                    const default_item =
                                        await frappe.db.get_doc(
                                            'Item',
                                            item_padrao,
                                        );
                                    const codigo_random = Math.floor(
                                        Math.random() * 99999999,
                                    ).toString();

                                    const estrutura = await frappe.db.get_doc(
                                        'Estrutura de Produto',
                                        element.estrutura,
                                    );
                                    const obj_new_pedido = {
                                        codigo: codigo_random,
                                        // 'item':`Produto ${codigo_random}`,
                                        doctype: 'Item',
                                        estrutura: element.estrutura,
                                        descricao: estrutura.descricao,
                                        custo_do_item: element.custo_unitario,
                                    };

                                    const itemFields = [
                                        'cnpj_do_fabricante',
                                        'codigo_tipo_de_serviço',
                                        'custo_do_item',
                                        'código_benefício_fiscal_na_uf',
                                        'código_de_barras',
                                        'código_ean_gtin',
                                        'descricao_tipo_de_servico',
                                        'dias_ressuprimento',
                                        'docstatus',
                                        'doctype',
                                        'epi_vestuario',
                                        'equipamento',
                                        'escala_relevante',
                                        'estoque_minimo',
                                        'fabricante',
                                        'fornecido_pelo_cliente',
                                        'manter_estoque',
                                        'materia_prima',
                                        'metodo_baixa',
                                        'metodo_ressuprimento',
                                        'numero_ncm',
                                        'permite_comprar',
                                        'permite_fabricar',
                                        'permite_vender',
                                        'ressuprimento_automático',
                                        'serviço_de_terceiros',
                                        'status',
                                        'tipo_de_item',
                                        'unidade_de_estoque',
                                        'familia_do_item',
                                        'grupo_item',
                                    ];
                                    itemFields.forEach(
                                        (field) =>
                                            (obj_new_pedido[field] =
                                                default_item[field]),
                                    );
                                    const new_item = await frappe.db.insert(
                                        obj_new_pedido,
                                    );

                                    return {
                                        item: await new_item.name,
                                        pedido: element.pedido,
                                        n_item_pedido: element.n_item_pedido,
                                        descrição_complementar:
                                            element.descricao_complementar,
                                        preco_unitario: element.preco_unitario,
                                        qtde: element.qtde,
                                        unidade_medida: element.unidade_medida,
                                        preco_total: element.preco_total,
                                        dt_entrega: element.dt_prevista,
                                        valor_ipi: element.valor_ipi,
                                        preco_total_com_ipi:
                                            element.preco_total_com_ipi,
                                        perc_custo: element.perc_custo,
                                        perc_lucro: element.perc_lucro,
                                        perc_despesas: element.perc_despesas,
                                        perc_impostos: element.perc_impostos,
                                        perc_frete: element.perc_frete,
                                        perc_seguro: element.perc_seguro,
                                        perc_outras_despesas:
                                            element.perc_outras_despesas,
                                        custo_do_item: element.custo_unitario,
                                        custo_unitario: element.custo_unitario,
                                        vl_lucro: element.vl_lucro,
                                        valor_despesa_fixa:
                                            element.valor_despesa_fixa,
                                        valor_despesa_variavel:
                                            element.valor_despesa_variavel,
                                        valor_frete: element.valor_frete,
                                        valor_seguro: element.valor_seguro,
                                        valor_outras_despesas:
                                            element.valor_outras_despesas,
                                        custo_total: element.custo_total,
                                        natureza_da_operacao:
                                            element.natureza_da_operacao,
                                        cfop: element.cfop,
                                        tipo_de_item: element.tipo_de_item,
                                        faturado: element.faturado,
                                        consumidor_final:
                                            element.consumidor_final,
                                        csosn: element.csosn,
                                        cst_icms: element.cst_icms,
                                        tributacao_icms:
                                            element.tributacao_icms,
                                        base_icms_percentual:
                                            element.base_icms_percentual,
                                        aliquota_icms: element.aliquota_icms,
                                        mod_deter_bc_icms:
                                            element.mod_deter_bc_icms,
                                        motivo_deson_icms:
                                            element.motivo_deson_icms,
                                        codigo_ajuste_apuracao:
                                            element.codigo_ajuste_apuracao,
                                        codigo_beneficio_uf:
                                            element.codigo_beneficio_uf,
                                        diferimento_icms:
                                            element.diferimento_icms,
                                        reducao_icms: element.reducao_icms,
                                        percentual_reducao_bc:
                                            element.percentual_reducao_bc,
                                        icms_origem: element.icms_origem,
                                        mva_iva: element.mva_iva,
                                        perc_red_mva: element.perc_red_mva,
                                        aliquota_efetiva_icms:
                                            element.aliquota_efetiva_icms,
                                        base_efetiva_icms:
                                            element.base_efetiva_icms,
                                        valor_bc_icms: element.valor_bc_icms,
                                        valor_icms: element.valor_icms,
                                        valor_icms_desonerado:
                                            element.valor_icms_desonerado,
                                        mva_iva_ajustado:
                                            element.mva_iva_ajustado,
                                        mod_deter_bc_icms_st:
                                            element.mod_deter_bc_icms_st,
                                        aliquota_icms_st:
                                            element.aliquota_icms_st,
                                        motivo_desoneracao_icms_st:
                                            element.motivo_desoneracao_icms_st,
                                        reducao_icms_st:
                                            element.reducao_icms_st,
                                        aliquota_fcp: element.aliquota_fcp,
                                        cst_pis: element.cst_pis,
                                        base_pis: element.base_pis,
                                        reter_pis: element.reter_pis,
                                        aliquota_pis: element.aliquota_pis,
                                        desconsiderar_icms_calculo_pis:
                                            element.desconsiderar_icms_calculo_pis,
                                        cst_cofins: element.cst_cofins,
                                        base_cofins: element.base_cofins,
                                        reter_cofins: element.reter_cofins,
                                        aliquota_cofins:
                                            element.aliquota_cofins,
                                        desconsiderar_icms_calculo_cofins:
                                            element.desconsiderar_icms_calculo_cofins,
                                        aliquota_irpj_industria:
                                            element.aliquota_irpj_industria,
                                        aliquota_irpj_servicos:
                                            element.aliquota_irpj_servicos,
                                        reter_irpj: element.reter_irpj,
                                        aliquota_csll_industria:
                                            element.aliquota_csll_industria,
                                        aliquota_csll_servicos:
                                            element.aliquota_csll_servicos,
                                        reter_csll: element.reter_csll,
                                        tipo_tributacao_issqn:
                                            element.tipo_tributacao_issqn,
                                        base_issqn: element.base_issqn,
                                        reter_issqn: element.reter_issqn,
                                        natureza_tributacao_issqn:
                                            element.natureza_tributacao_issqn,
                                        aliquota_issqn: element.aliquota_issqn,
                                        cst_ipi: element.cst_ipi,
                                        cel_ipi: element.cel_ipi,
                                        aliquota_ipi: element.aliquota_ipi,
                                        soma_ipi_base_icms:
                                            element.soma_ipi_base_icms,
                                        aliquota_simples_federal_produto:
                                            element.aliquota_simples_federal_produto,
                                        aliquota_simples_federal_servico:
                                            element.aliquota_simples_federal_servico,
                                        aplicavel_de_credito_icms_produto:
                                            element.aplicavel_de_credito_icms_produto,
                                    };
                                }
                            }
                        }
                    }
                });

                await Promise.all(items).then(async (value) => {
                    const pedido = await frappe.db.insert({
                        entidade: frm.doc.entidade,
                        empresa: frm.doc.empresa,
                        contato: frm.doc.contato,
                        orcamento: frm.doc.name,
                        perc_custo: frm.doc.perc_custo,
                        perc_despesas: frm.doc.perc_despesas,
                        perc_lucro: frm.doc.perc_lucro,
                        perc_imposto: frm.doc.perc_imposto,
                        total_custo: frm.doc.total_custo,
                        total_despesas: frm.doc.total_despesas,
                        total_imposto: frm.doc.total_imposto,
                        total_lucro: frm.doc.total_lucro,
                        total_ipi: frm.doc.total_ipi,
                        total_frete: frm.doc.total_frete,
                        total_seguro: frm.doc.total_seguro,
                        total_outras_despesas: frm.doc.total_outras_despesas,
                        preco_total: frm.doc.preco_total,
                        valor_total: frm.doc.valor_total,
                        valor_total_com_ipi: frm.doc.valor_total_com_ipi,
                        condicao_de_pagamento: frm.doc.condicao_de_pagamento,
                        data_prevista_de_entrega:
                            frm.doc.data_prevista_de_entrega,
                        previsao_de_parcelas: frm.doc.previsao_de_parcelas,
                        total_frete_percentual: frm.doc.perc_total_frete,
                        total_seguro_percentual: frm.doc.perc_total_seguro,
                        total_outras_despesas_percentual:
                            frm.doc.perc_total_outras_despesas,
                        num_pedido: frm.doc.numero_pedido_cliente,
                        data_do_pedido: date,
                        doctype: 'Pedido',
                        itens: value,
                        descricao_condicao_de_pagamento:
                            frm.doc.descricao_condicao_de_pagamento,
                        observacao_pedido: frm.doc.observacao_orcamento,
                        documentos_referenciados: [
                            {
                                documento: 'Orcamento',
                                id_documento: frm.doc.name,
                            },
                        ],
                    });
                    const name = await pedido.name;

                    const entidade_doc = await frappe.db.get_doc(
                        'Entidade',
                        pedido.entidade,
                    );

                    const title =
                        (await pedido.name) +
                        '-' +
                        entidade_doc.nome.split(' ')[0] +
                        '-' +
                        frm.doc.data_prevista_de_entrega;

                    frappe.db.set_value('Pedido', name, 'title', title);

                    frm.set_value('status', 'Fechado');
                    frm.set_value('estagio', 'Ganho');
                    frm.set_value('data_de_aprovacao_do_orcamento', date);
                    frm.save();
                    await frappe.set_route('Form', 'pedido', await name);
                    frappe.toast('Pedido criado com sucesso!', 10);
                });
            }
        },
        natureza_da_operacao: async (frm, itemRow) => {
            // Obtém a UF do cliente
            const uf = await utils.uf_cliente(frm);
            console.log('uF', uf);

            // Obtém o valor de "natureza_da_operacao" da linha atual
            const natureza = itemRow.natureza_da_operacao;
            console.log('natureza', natureza);

            if (natureza && uf) {
                // Se "natureza_da_operacao" estiver definida, busca o documento correspondente
                const natop = await frappe.db.get_doc(
                    'Natureza da Operacao',
                    natureza,
                );

                // Encontra o estado específico no documento
                const nat = natop.estados.find((e) => e.estado === uf);
                console.log('nat', nat);

                // Define os campos a serem atualizados com seus respectivos valores
                const fieldsToUpdate = {
                    perc_despesas: natop.perc_despesas_fixas,
                    cfop: nat.cfop,
                    aliquota_icms: nat.aliquota_icms,
                    csosn: nat.csosn,
                    cst_icms: nat.cst_icms,
                    tributacao_icms: nat.tributacao_icms,
                    base_icms_percentual: nat.base_icms_percentual,
                    mod_deter_bc_icms: nat.mod_deter_bc_icms,
                    motivo_deson_icms: nat.motivo_deson_icms,
                    codigo_ajuste_apuracao: nat.codigo_ajuste_apuracao,
                    codigo_beneficio_uf: nat.codigo_beneficio_uf,
                    diferimento_icms: nat.diferimento_icms,
                    reducao_icms: nat.reducao_icms,
                    percentual_reducao_bc: nat.percentual_reducao_bc,
                    icms_origem: nat.icms_origem,
                    mva_iva: nat.mva_iva,
                    motivo_desoneracao_icms_st: nat.motivo_desoneracao_icms_st,
                    aliquota_icms_st: nat.aliquota_icms_st,
                    reducao_icms_st: nat.reducao_icms_st,
                    aliquota_fcp: nat.aliquota_fcp,
                    perc_red_mva: nat.perc_red_mva,
                    cst_pis: natop.cst_pis,
                    aliquota_pis: natop.aliquota_pis,
                    desconsiderar_icms_calculo_pis:
                        natop.desconsiderar_icms_calculo_pis,
                    reter_pis: natop.reter_pis,
                    cst_cofins: natop.cst_cofins,
                    aliquota_cofins: natop.aliquota_cofins,
                    desconsiderar_icms_calculo_cofins:
                        natop.desconsiderar_icms_calculo_cofins,
                    reter_cofins: natop.reter_cofins,
                    aliquota_irpj_industria: natop.aliquota_irpj_industria,
                    aliquota_irpj_servicos: natop.aliquota_irpj_servicos,
                    aliquota_csll_industria: natop.aliquota_csll_industria,
                    aliquota_csll_servicos: natop.aliquota_csll_servicos,
                    reter_irpj: natop.reter_irpj,
                    reter_csll: natop.reter_csll,
                    tipo_tributacao_issqn: natop.tipo_tributacao_issqn,
                    natureza_tributacao_issqn: natop.natureza_tributacao_issqn,
                    aliquota_issqn: natop.aliquota_issqn,
                    reter_issqn: natop.reter_issqn,
                    cst_ipi: natop.cst_ipi,
                    aliquota_ipi: natop.aliquota_ipi,
                    cel_ipi: natop.cel_ipi,
                    soma_ipi_base_icms: natop.soma_ipi_base_icms,
                    aliquota_simples_federal_produto:
                        natop.aliquota_simples_federal_produto,
                    aliquota_simples_federal_servico:
                        natop.aliquota_simples_federal_servico,
                    aplicavel_de_credito_icms_produto:
                        natop.aplicavel_de_credito_icms_produto,
                };
                console.log('fields to Update', fieldsToUpdate);
                return fieldsToUpdate;
            } else {
                // Se "natureza_da_operacao" não estiver definida, define valores padrão para os campos
                const defaultValues = {
                    perc_despesas: 0,
                    cfop: '',
                    aliquota_icms: 0,
                    cst_icms: '',
                    base_icms_percentual: 0,
                    mod_deter_bc_icms: '',
                    motivo_deson_icms: '',
                    codigo_ajuste_apuracao: '',
                    codigo_beneficio_uf: '',
                    diferimento_icms: 0,
                    reducao_icms: 0,
                    percentual_reducao_bc: 0,
                    icms_origem: 0,
                    mva_iva: 0,
                    mod_deter_bc_icms_st: '',
                    motivo_desoneracao_icms_st: '',
                    aliquota_icms_st: 0,
                    reducao_icms_st: 0,
                    aliquota_fcp: 0,
                    perc_red_mva: 0,
                    cst_pis: '',
                    aliquota_pis: 0,
                    desconsiderar_icms_calculo_pis: 0,
                    reter_pis: 0,
                    cst_cofins: '',
                    aliquota_cofins: 0,
                    desconsiderar_icms_calculo_cofins: 0,
                    reter_cofins: 0,
                    aliquota_irpj_industria: 0,
                    aliquota_irpj_servicos: 0,
                    aliquota_csll_industria: '',
                    aliquota_csll_servicos: '',
                    reter_irpj: 0,
                    reter_csll: 0,
                    tipo_tributacao_issqn: '',
                    natureza_tributacao_issqn: '',
                    aliquota_issqn: '',
                    reter_issqn: '',
                    cst_ipi: '',
                    aliquota_ipi: 0,
                    cel_ipi: '',
                    soma_ipi_base_icms: 0,
                    aliquota_simples_federal_produto: '',
                    aliquota_simples_federal_servico: '',
                    aplicavel_de_credito_icms_produto: 0,
                };

                return defaultValues;
            }
        },
        setAllValues: async (cdt, cdn, onChangeField) => {
            const child = await utils.cur_row_child(cdt, cdn);

            if (onChangeField == 'preco_unitario') {
                //Fields
                const preco_unitario = child.preco_unitario;
                const qtde = child.qtde || 1;
                const preco_total = qtde * preco_unitario;
                const valor_ipi = preco_total * (child.aliquota_ipi / 100);
                const preco_total_com_ipi = preco_total + valor_ipi;
                const custo_unitario = child.custo_unitario;
                const custo_total = custo_unitario * qtde;

                const perc_despesas = child.perc_despesas || 0;
                const perc_impostos = child.perc_impostos || 0;
                const valor_frete = child.valor_frete;
                const valor_seguro = child.valor_seguro;
                const valor_outras_despesas = child.valor_outras_despesas;

                const valor_despesa_fixa =
                    (perc_despesas / 100) * preco_total || 0;
                const valor_despesa_variavel =
                    (perc_impostos / 100) * preco_total;
                const vl_lucro =
                    preco_total -
                    (+valor_despesa_variavel +
                        valor_frete +
                        valor_seguro +
                        valor_outras_despesas +
                        custo_total);

                const perc_lucro = (vl_lucro / preco_total) * 100;
                const perc_frete = (valor_frete / preco_total) * 100;
                const perc_seguro = (valor_seguro / preco_total) * 100;
                const perc_outras_despesas =
                    (valor_outras_despesas / preco_total) * 100;

                const perc_custo = (custo_total / preco_total) * 100;

                //Fields

                const result = {
                    preco_unitario: utils.decimal_format(preco_unitario, 2),
                    qtde: utils.decimal_format(qtde, 2),
                    preco_total: utils.decimal_format(preco_total, 2),
                    valor_ipi: utils.decimal_format(valor_ipi, 2),
                    preco_total_com_ipi: utils.decimal_format(
                        preco_total_com_ipi,
                        2,
                    ),
                    perc_custo: utils.decimal_format(perc_custo, 2),
                    custo_unitario: utils.decimal_format(custo_unitario, 2),
                    perc_lucro: utils.decimal_format(perc_lucro, 2),
                    vl_lucro: utils.decimal_format(vl_lucro, 2),
                    perc_despesas: utils.decimal_format(perc_despesas, 2),
                    valor_despesa_fixa: utils.decimal_format(
                        valor_despesa_fixa,
                        2,
                    ),
                    perc_impostos: utils.decimal_format(perc_impostos, 2),
                    valor_despesa_variavel: utils.decimal_format(
                        valor_despesa_variavel,
                        2,
                    ),
                    perc_frete: utils.decimal_format(perc_frete, 2),
                    valor_frete: utils.decimal_format(valor_frete, 2),
                    perc_seguro: utils.decimal_format(perc_seguro, 2),
                    valor_seguro: utils.decimal_format(valor_seguro, 2),
                    perc_outras_despesas: utils.decimal_format(
                        perc_outras_despesas,
                        2,
                    ),
                    valor_outras_despesas: utils.decimal_format(
                        valor_outras_despesas,
                        2,
                    ),
                    custo_total: utils.decimal_format(custo_total, 2),
                };
                console.log('resultado da funcao set all fields', result);
                for (const [key, value] of Object.entries(result)) {
                    await frappe.model.set_value(cdt, cdn, key, value);
                }

                // } else if(onChangeField == "qtde") {
                //     //Fields
                //     const qtde = child.qtde || 1
                //     const preco_unitario = child.preco_unitario
                //     const preco_total = qtde * preco_unitario
                //     const valor_ipi = preco_total * (child.aliquota_ipi / 100)
                //     const preco_total_com_ipi = preco_total + valor_ipi
                //     const custo_unitario = child.custo_unitario
                //     const custo_total = custo_unitario * qtde

                //     const perc_despesas = child.perc_despesas || 0
                //     const perc_impostos = child.perc_impostos || 0
                //     const valor_frete = child.valor_frete
                //     const valor_seguro = child.valor_seguro
                //     const valor_outras_despesas = child.valor_outras_despesas

                //     const valor_despesa_fixa = (perc_despesas / 100) * preco_total || 0
                //     const valor_despesa_variavel = (perc_impostos / 100) * preco_total
                //     const vl_lucro = preco_total - (
                //          + valor_despesa_variavel + valor_frete + valor_seguro + valor_outras_despesas + custo_total)

                //     const perc_lucro = (vl_lucro / preco_total) * 100;
                //     const perc_frete = (valor_frete / preco_total) * 100;
                //     const perc_seguro = (valor_seguro / preco_total) * 100;
                //     const perc_outras_despesas =
                //         (valor_outras_despesas / preco_total) * 100;

                //     const perc_custo = (custo_total / preco_total) *100;

                //     //Fields

                //     const result = {
                //         preco_unitario,
                //         qtde,
                //         preco_total,
                //         valor_ipi,
                //         preco_total_com_ipi,
                //         perc_custo,
                //         custo_unitario,
                //         perc_lucro,
                //         vl_lucro,
                //         perc_despesas,
                //         valor_despesa_fixa,
                //         perc_impostos,
                //         valor_despesa_variavel,
                //         perc_frete,
                //         valor_frete,
                //         perc_seguro,
                //         valor_seguro,
                //         perc_outras_despesas,
                //         valor_outras_despesas,
                //         custo_total,
                //     }
                //     console.log("resultado da funcao set all fields", result)
                //     for(const [key, value] of Object.entries(result)) {
                //              await frappe.model.set_value(cdt, cdn,key,value)
                //          }
                // } else if(onChangeField == "preco_total") {
                //     //Fields
                //     const preco_total = child.preco_total
                //     const qtde = child.qtde || 1
                //     const preco_unitario = preco_total / qtde
                //     const valor_ipi = preco_total * (child.aliquota_ipi / 100)
                //     const preco_total_com_ipi = preco_total + valor_ipi
                //     const custo_unitario = child.custo_unitario
                //     const custo_total = custo_unitario * qtde

                //     const perc_despesas = child.perc_despesas || 0
                //     const perc_impostos = child.perc_impostos || 0
                //     const valor_frete = child.valor_frete
                //     const valor_seguro = child.valor_seguro
                //     const valor_outras_despesas = child.valor_outras_despesas

                //     const valor_despesa_fixa = (perc_despesas / 100) * preco_total || 0
                //     const valor_despesa_variavel = (perc_impostos / 100) * preco_total
                //     const vl_lucro = preco_total - (
                //          + valor_despesa_variavel + valor_frete + valor_seguro + valor_outras_despesas + custo_total)

                //     const perc_lucro = (vl_lucro / preco_total) * 100;
                //     const perc_frete = (valor_frete / preco_total) * 100;
                //     const perc_seguro = (valor_seguro / preco_total) * 100;
                //     const perc_outras_despesas =
                //         (valor_outras_despesas / preco_total) * 100;

                //     const perc_custo = (custo_total / preco_total) *100;

                //     //Fields

                //     const result = {
                //         preco_unitario,
                //         qtde,
                //         preco_total,
                //         valor_ipi,
                //         preco_total_com_ipi,
                //         perc_custo,
                //         custo_unitario,
                //         perc_lucro,
                //         vl_lucro,
                //         perc_despesas,
                //         valor_despesa_fixa,
                //         perc_impostos,
                //         valor_despesa_variavel,
                //         perc_frete,
                //         valor_frete,
                //         perc_seguro,
                //         valor_seguro,
                //         perc_outras_despesas,
                //         valor_outras_despesas,
                //         custo_total,
                //     }
                //     console.log("resultado da funcao set all fields", result)
                //     for(const [key, value] of Object.entries(result)) {
                //              await frappe.model.set_value(cdt, cdn,key,value)
                //          }
                // } else if(onChangeField == "custo_unitario") {
                //     //Fields
                //     if(child.custo_unitario > 0 && child.preco_total > 0){
                //         const custo_unitario = child.custo_unitario
                //         const qtde = child.qtde || 1
                //         const preco_total = child.preco_total
                //         const preco_unitario = preco_total / qtde

                //         const perc_despesas = child.perc_despesas || 0
                //         const perc_impostos = child.perc_impostos || 0
                //         const valor_frete = child.valor_frete
                //         const valor_seguro = child.valor_seguro
                //         const valor_outras_despesas = child.valor_outras_despesas

                //         const custo_total = custo_unitario * qtde
                //         const vl_lucro = preco_total - custo_total
                //         const perc_lucro = (vl_lucro / preco_total) * 100;
                //         const perc_custo = (custo_total / preco_total) *100;
                //         const perc_frete = (valor_frete / preco_total) * 100;
                //         const perc_seguro = (valor_seguro / preco_total) * 100;
                //         const perc_outras_despesas = (valor_outras_despesas / preco_total) * 100;

                //         const valor_despesa_fixa = (perc_despesas / 100) * preco_total || 0
                //         const valor_despesa_variavel = (perc_impostos / 100) * preco_total

                //         const valor_ipi = preco_total * (child.aliquota_ipi / 100)
                //         const preco_total_com_ipi = preco_total + valor_ipi

                //         //Fields

                //         const result = {
                //             preco_unitario,
                //             qtde,
                //             preco_total,
                //             valor_ipi,
                //             preco_total_com_ipi,
                //             perc_custo,
                //             custo_unitario,
                //             perc_lucro,
                //             vl_lucro,
                //             perc_despesas,
                //             valor_despesa_fixa,
                //             perc_impostos,
                //             valor_despesa_variavel,
                //             perc_frete,
                //             valor_frete,
                //             perc_seguro,
                //             valor_seguro,
                //             perc_outras_despesas,
                //             valor_outras_despesas,
                //             custo_total,
                //         }
                //         console.log("resultado da funcao set all fields", result)
                //         for(const [key, value] of Object.entries(result)) {
                //                  await frappe.model.set_value(cdt, cdn,key,value)
                //              }

                //     }

                // // } else if(onChangeField == "perc_lucro") {
                // //     //Fields
                // //     if(child.custo_unitario > 0 && child.preco_total > 0){
                // //         const qtde = child.qtde || 1

                // //         const perc_lucro = child.perc_lucro
                // //         const preco_total = child.preco_total * (perc_lucro / 100)

                // //         const custo_unitario = preco_total / qtde

                // //         const preco_unitario = child.custo_unitario
                // //         const vl_lucro = (perc_lucro /100) * preco_total

                // //         const perc_despesas = child.perc_despesas || 0
                // //         const perc_impostos = child.perc_impostos || 0
                // //         const valor_frete = child.valor_frete
                // //         const valor_seguro = child.valor_seguro
                // //         const valor_outras_despesas = child.valor_outras_despesas

                // //         const custo_total = custo_unitario * qtde
                // //         const perc_custo = (custo_total / preco_total) *100;
                // //         const perc_frete = (valor_frete / preco_total) * 100;
                // //         const perc_seguro = (valor_seguro / preco_total) * 100;
                // //         const perc_outras_despesas = (valor_outras_despesas / preco_total) * 100;

                // //         const valor_despesa_fixa = (perc_despesas / 100) * preco_total || 0
                // //         const valor_despesa_variavel = (perc_impostos / 100) * preco_total

                // //         const valor_ipi = preco_total * (child.aliquota_ipi / 100)
                // //         const preco_total_com_ipi = preco_total + valor_ipi

                // //         //Fields

                // //         const result = {
                // //             preco_unitario,
                // //             qtde,
                // //             preco_total,
                // //             valor_ipi,
                // //             preco_total_com_ipi,
                // //             perc_custo,
                // //             custo_unitario,
                // //             perc_lucro,
                // //             vl_lucro,
                // //             perc_despesas,
                // //             valor_despesa_fixa,
                // //             perc_impostos,
                // //             valor_despesa_variavel,
                // //             perc_frete,
                // //             valor_frete,
                // //             perc_seguro,
                // //             valor_seguro,
                // //             perc_outras_despesas,
                // //             valor_outras_despesas,
                // //             custo_total,
                // //         }
                // //         console.log("resultado da funcao set all fields", result)
                // //         for(const [key, value] of Object.entries(result)) {
                // //                  await frappe.model.set_value(cdt, cdn,key,value)
                // //              }

                // //     }
            }
        },
    },
};

frappe.nxlite.utils.calculo_itens_orcamento_e_pedido = utils;
