<template>
    <div class="text-center pa-4">
        <!-- Modal -->
        <v-dialog 
        class="dialog" 
        v-model="appStore.Modal_Apontamento_Aberto_Body"  
        height="auto"
        :key="appStore.Modal_Apontamento_Aberto_Body"
        
        >
            <v-card class="card">
                <div class="fecharModal">
                    <v-icon @click="closeModalApontamentoAbertoBody" class="close-btn">mdi-close</v-icon>
                </div>

                <!-- Título do Modal -->
                <div class="div-titulo">
                    <h3 class="titulo">O que gostaria de fazer com o apontamento?</h3>
                    <hr class="linha">
                </div>

                <!-- Botões do Modal -->
                <div class="div-botoes">
                    <v-btn @click="toggleModals('finalizar')" large color="primary">
                        Finalizar OP
                    </v-btn>
                    <v-btn @click="toggleModals('fechar')" large color="secondary">
                        Fechar
                    </v-btn>
                    <v-btn @click="toggleModals('motivoParada')" large color="warning">
                        Abrir MP
                    </v-btn>
                </div>
            </v-card>

            <div>
                <ModalFinalizarOP />
                <ModalMotivoDeParada />
            </div>
        </v-dialog>
        <ModalFecharApontamentoAberto />
    </div>
</template>


<style scoped>
.card {
    width: 70%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 16px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 30px;
    margin: auto;
    border-radius: 16px;
    border: 1px solid black;
}

.div-titulo {
    text-align: center;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
}

.titulo {
    font-size: 2rem;
    color: #333;
    font-weight: 600;
}

.linha {
    width: 100%;
    margin: 15px 0;
    border-top: 2px solid #ccc;
}

.div-botoes {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    gap: 10px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

button:focus {
    outline: none;
    /* Remove o contorno */
    background-color: initial;
    /* Restaura a cor de fundo padrão */
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
    /* Centralizando o card vertical e horizontal */
    body {
        display: flex;
        justify-content: flex-start; 
        align-items: center;
        min-height: 100vh;
        margin: 0;
        background-color: #f4f4f4;
    }

    .card {
        width: 93%;
        max-width: 500px;
        padding: 20px;
        margin: 0; 
        position: relative;
        left: -15px; 
    }

    .div-titulo {
        padding-top: 10px; /* Ajusta o padding para dispositivos móveis */
        margin-bottom: 15px; /* Reduz a margem inferior */
    }

    .titulo {
        font-size: 1.5rem; /* Reduz o tamanho da fonte */
    }

    .linha {
        margin: 10px 0; /* Reduz a margem para um espaço mais compacto */
    }

    .div-botoes {
        margin-top: 20px; /* Ajusta o espaço entre os botões */
        gap: 5px; 
    }

    .close-btn {
        top: 5px; /* Ajusta o posicionamento do botão de fechamento */
        right: 5px;
    }

    .dialog {
        width: 450px; /* A largura é 100% do container */
        max-width: 500px; /* Limita a largura máxima do modal */
        margin: 0 auto; /* Centraliza o modal horizontalmente */
    }
}


</style>


<script>
import { useAppStore } from '../../../stores/app';
import ModalFinalizarOP from './modal_finalizarOP.vue'
import ModalFecharApontamentoAberto from './modal_fechar_apontamento_aberto.vue'
import ModalMotivoDeParada from './modal_motivo_de_parada.vue'

export default {
    components: {
        ModalFinalizarOP,
        ModalFecharApontamentoAberto,
        ModalMotivoDeParada
    },
    name: "ModalApontamentoAbertoBody",
    data() {
        return {
        };
    },
    computed: {
        appStore() {
            return useAppStore();
        },
    },
    methods: {
        openModalApontamentoAbertoBody() {
            this.appStore.openModalApontamentoAbertoBody();
        },
        closeModalApontamentoAbertoBody() {
            this.appStore.closeModalApontamentoAbertoBody();
        },
        openModalFinalizarOP() {
            this.appStore.openModalFinalizarOP();
        },
        closeModalFinalizarOP() {
            this.appStore.closeModalFinalizarOP();
        },
        openModalFecharApontamentoAberto() {
            this.appStore.openModalFecharApontamentoAberto()
        },
        closeModalFecharApontamentoAberto() {
            this.appStore.closeModalFecharApontamentoAberto()
        },
        openModalMotivoDeParada() {
            this.Modal_Motivo_De_Parada = true;
        },
        closeModalMotivoDeParada() {
            this.Modal_Motivo_De_Parada = false;
        },


        toggleModals(selecionado) {
            // Chama a função centralizada para controlar os modais
            this.appStore.toggleModals(selecionado);
        }
    },
};
</script>
