import { registerLicense } from "@syncfusion/ej2-base";
import { Kanban } from "@syncfusion/ej2-kanban";
import { DropDownList } from "@syncfusion/ej2-dropdowns"; // Importar DropDownList

var kanbanObj = null;

class SyncFusionKanban {
  constructor({ wrapper, element, dataSource }) {
    try {
      if (!wrapper) {
        throw new Error("Wrapper is required to initialize SyncFusionKanban.");
      }
      this.$wrapper = $(wrapper);
      this.element = element || "#Kanban";

      console.log("Wrapper initialized:", wrapper);

      registerLicense(
        "Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH1fdnVURWVeVEB1VkI="
      );
      const empresas = dataSource.map(item => item.nome_empresa).filter((value, index, self) => self.indexOf(value) === index);
      // Adicionar DropDownList
      const dropDownList = new DropDownList({
        dataSource: empresas,
        placeholder: "Selecione uma empresa",
        change: (args) => {
          const filteredData = dataSource.filter(item => item.nome_empresa === args.value);
          kanbanObj.dataSource = filteredData;
          kanbanObj.refresh();
        },
      });
      dropDownList.appendTo("#dropdownElement");

      kanbanObj = new Kanban({
        dataSource: dataSource,
        keyField: "doctype",
        allowDragAndDrop: false,
        columns: [
          {
            headerText: "SOLICITAÇÃO",
            keyField: "Solicitacao",
            template:
              '<div class="kanban-column">' +
              '<div class="kanban-header">' +
              '<i class="fas fa-paper-plane icon-solicitacao"></i>' +
              '<span>Solicitação</span>' +
              '</div>' +
              `<a href="${window.location.origin}/app/solicitacao/new-solicitacao-jmyrfanpir#solciita%C3%A7%C3%A3o_tab" target="_blank">` +
              '<button class="kanban-button">Nova Solicitação</button>' +
              '</a>' +
              '</div>',
          },
          {
            headerText: "COTAÇÃO",
            keyField: "Cotacao",
            template:
              '<div class="kanban-column">' +
              '<div class="kanban-header">' +
              '<i class="fas fa-chart-bar icon-cotacao"></i>' +
              '<span>Cotação</span>' +
              '</div>' +
              `<a href="${window.location.origin}/app/cotacao/new-cotacao-amonfprpar#principal_tab" target="_blank">` +
              '<button class="kanban-button">Nova Cotação</button>' +
              '</a>' +
              '</div>',
          },
          {
            headerText: "ORDEM DE COMPRA",
            keyField: "Ordem de Compra",
            template:
              '<div class="kanban-column">' +
              '<div class="kanban-header">' +
              '<i class="fas fa-cart-arrow-down icon-ordem-compra"></i>' +
              '<span>Ordem de Compra</span>' +
              '</div>' +
              `<a href="${window.location.origin}/app/ordem-de-compra/new-ordem-de-compra-ppcatlbgmz#principal_tab" target="_blank">` +
              '<button class="kanban-button">Nova Ordem de Compra</button>' +
              '</a>' +
              '</div>',
          },
        ],

        cardSettings: {
          contentField: "observacao",
          headerField: "name",
          allowEditing: false,
          template: function (data) {
            if (data.doctype == "Solicitacao") {
              // const emissionColor = getExpirationColor(data.data_solicitacao);
              const necessityColor = getExpirationColor(data.data_da_necessidade);
              return `
                <div class="e-card-grid trello-card">
                  <div class="e-card-header">
                      <strong>
                          <a href="${window.location.origin}/app/solicitacao/${data.name}#principal_tab" target="_blank">
                              <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${data.name || "N/A"}
                          </a>
                      </strong>
                  </div>
                  <div class="e-card-content">
                    <div class="e-card-item bordered-item">
                      <strong><i class="fas fa-cogs icon-item"></i> Item:</strong> ${
                        data.codigo_item ? `${data.codigo_item} - ${data.item_descricao  || ""} (${data.quantidade} ${data.unidade || ""}) ` : "N/A"
                      }
                    </div>
                      <div class="e-card-solicitante bordered-solicitante">
                      <strong>Solicitante:</strong> ${
                        data.solicitante || "N/A"
                      }
                    </div>
                     <div class="e-card-data-emissao bordered-data-emissao">
                      <strong>Data de Emissão:</strong> ${
                        formatarData(data.data_solicitacao) || "N/A"
                      }
                    </div>
                    <div class="e-card-data-necessidade bordered-data-necessidade" style="background-color: ${necessityColor};">
                      <strong>Data Necessidade:</strong> ${
                        formatarData(data.data_da_necessidade) || "N/A"
                      }
                    </div>
                  </div>
                </div> 
                `;
            } else if (data.doctype == "Ordem de Compra") {
              const deliveryColor = getExpirationColor(data.data_prevista_de_entrega);
              return `
                  <div class="e-card-grid trello-card">
                    <div class="e-card-header">
                        <strong>
                           <a href="${window.location.origin}/app/ordem-de-compra/${data.name}#principal_tab" target="_blank">
                              <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${data.name || "N/A"} 
                          </a>
                      </strong>
                  </div>
                <div>
                  <div class="e-card-content">
                    <div class="e-card-fornecedor bordered-fornecedor">
                      <strong>Fornecedor:</strong> ${
                        data.descricao_fornecedor || "N/A"
                      }
                    </div>
                    <div class="e-card-itens bordered-itens clickable" onclick="openModal('${data.name}')">
                      <strong><i class="fas fa-cogs icon-itens"></i> Itens (${data.itens.length})</strong>
                    </div>
                    <div class="e-card-data-emissao bordered-data-emissao">
                      <strong>Data de Emissão:</strong> ${
                        formatarData(data.data_de_emissão) || "N/A"
                      }
                    </div>
                    <div class="e-card-data-prevista bordered-data-prevista" style="background-color: ${deliveryColor};">
                      <strong>Data Prevista Entrega:</strong> ${
                        formatarData(data.data_prevista_de_entrega) || "N/A"
                      }
                    </div>
                    <div class="e-card-valor-total bordered-valor-total">
                      <strong>Valor Total:</strong> ${
                        data.total
                      }
                    </div>
                    <div class="e-card-estagio bordered-estagio">
                      <strong>Estágio:</strong> ${
                        data.estágio_ordem_de_compra|| "N/A"
                      }
                  </div>
                  </div>
                </div>
                `;
            } else if (data.doctype == "Cotacao") {
              const expirationColor = getExpirationColor(data.data_expiracao);
              return `
            <div class="e-card-grid trello-card">
              <div class="e-card-header">
                <strong>
                  <a href="${window.location.origin}/app/cotacao/${data.name}#principal_tab" target="_blank">
                      <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${data.name || "N/A"}
                  </a>
                </strong>
              </div>
              <div>
                <div class="e-card-content">
                  <div class="e-card-fornecedor bordered-fornecedor">
                    <strong>Fornecedor 1:</strong> ${
                      data.fornecedor_cotacao[0]?.descricao_fornecedor || "N/A"
                    }
                  </div>
                  <div class="e-card-fornecedor bordered-fornecedor">
                    <strong>Fornecedor 2:</strong> ${
                      data.fornecedor_cotacao[1]?.descricao_fornecedor || "N/A"
                    }
                  </div>
                  <div class="e-card-fornecedor bordered-fornecedor">
                    <strong>Fornecedor 3:</strong> ${
                      data.fornecedor_cotacao[2]?.descricao_fornecedor || "N/A"
                    }
                  </div>
                  <div class="e-card-itens bordered-itens clickable" onclick="openModal('${data.name}')">
                    <strong><i class="fas fa-cogs icon-itens"></i> Itens (${data.itens.length})</strong>
                  </div>
                  <div class="e-card-data-emissao bordered-data-emissao">
                    <strong>Data de Emissão:</strong> ${
                      formatarData(data.creation || "N/A")
                    }
                  </div>
                  <div class="e-card-data-expiracao bordered-data-expiracao" style="background-color: ${expirationColor};">
                    <strong>Data de Expiração:</strong> ${
                      formatarData(data.data_expiracao || "N/A")
                    }
                  </div>
                  <div class="e-card-estagio bordered-estagio">
                    <strong>Estágio:</strong> ${
                      data.estagio_da_cotacao|| "N/A"
                    }
                  </div>
                </div>
              </div>
            </div>
              `;
            }
          },
        },
        dialogOpen: 'dialogOpen',
        // Responsividade
        width: '100%',
        height: 'auto',
        minWidth: '300px',
        minHeight: '400px',
      });
      real_time();
      kanbanObj.appendTo(this.element);
    } catch (e) {
      console.error("Error initializing SyncFusionKanban:", e);
    }
  }

  refreshKanban() {
    kanbanObj.refresh();
  }
}

function sortByDate(data, dateField) {
  return data.sort((a, b) => new Date(a[dateField]) - new Date(b[dateField]));
}

const real_time = () => {
  frappe.realtime.on("dashboard", async (data) => {
    const get_dados = await frappe.call(
      "nxlite.nx_estoque.page.kanban_de_compras.controller.get_dados"
    );
    setTimeout(() => {
      const sortedData = sortByDate(get_dados.message, 'data_da_necessidade');
      kanbanObj.dataSource = sortedData;
      updateModalContent();
      updateDropDownList(sortedData); // Atualizar DropDownList
    }, 1500);
    const sortedData = sortByDate(get_dados.message, 'data_da_necessidade');
    kanbanObj.dataSource = sortedData;
    kanbanObj.refresh();
    updateModalContent();
    updateDropDownList(sortedData); // Atualizar DropDownList
  });
};

// Função para atualizar o DropDownList em tempo real
function updateDropDownList(dataSource) {
  const empresas = dataSource.map(item => item.nome_empresa).filter((value, index, self) => self.indexOf(value) === index);
  const dropDownList = document.querySelector("#dropdownElement").ej2_instances[0];
  dropDownList.dataSource = empresas;
  dropDownList.refresh();
}

// Função para atualizar o conteúdo do modal em tempo real
function updateModalContent() {
  const modal = document.querySelector(".modal");
  if (modal) {
    const cotacaoName = modal.querySelector("h2").textContent.split(": ")[1];
    const cotacao = kanbanObj.dataSource.find(item => item.name === cotacaoName);
    if (cotacao) {
      const modalTableBody = modal.querySelector(".modal-table tbody");
      modalTableBody.innerHTML = cotacao.itens.map(item => `
        <tr>
          <td>${item.codigo_item}</td>
          <td>${item.descricao_item || ""}</td>
          <td>${item.quantidade}</td>
          <td>${item.unidade_de_compra}</td>
          <td>${item.valor_total}</td>
        </tr>
      `).join("");
    }
  }
}

// Função para formatar a data no formato DD-MM-YYYY
function formatarData(data) {
  if (!data) return "N/A";
  const date = new Date(data);
  if (isNaN(date)) return "N/A";
  const dia = String(date.getUTCDate()).padStart(2, "0");
  const mes = String(date.getUTCMonth() + 1).padStart(2, "0");
  const ano = date.getUTCFullYear();
  return `${dia}-${mes}-${ano}`;
};

function dialogOpen(args) {
  args.cancel = false;
}

// Função para abrir o modal
window.openModal = function(Name) {
  // Lógica para abrir o modal
  
  // Obter os dados da cotação
  const cotacao = kanbanObj.dataSource.find(item => item.name === Name);
  if (!cotacao) return;

  // Criar o conteúdo do modal
  const modalContent = `
    <div class="modal-content">
      <h2>Itens da ${cotacao.doctype === "Ordem de Compra" ? "Ordem de Compra" : "Cotação"} : ${Name}</h2>
      <div class="modal-table-container">
        <table class="modal-table">
          <thead>
            <tr>
              <th>Código do Item</th>
              <th>Descrição do Item</th>
              <th>Quantidade</th>
              <th>Unidade</th>
              ${cotacao.doctype === "Ordem de Compra" ? "<th>Valor Unitário</th>" : ""}
              ${cotacao.doctype === "Ordem de Compra" ? "<th>Valor Total</th>" : ""}
            </tr>
          </thead>
          <tbody>
            ${cotacao.itens.map(item => `
              <tr>
                <td>${item.codigo_item}</td>
                <td>${item.descricao_item || ""}</td>
                <td>${item.quantidade}</td>
                <td>${item.unidade_de_compra}</td>
                ${cotacao.doctype === "Ordem de Compra" ? `<td>${item.valor_unitario}</td>` : ""}
                ${cotacao.doctype === "Ordem de Compra" ? `<td>${item.valor_total}</td>` : ""}
              </tr>
            `).join("")}
          </tbody>
        </table>
      </div>
      <button onclick="closeModal()">Fechar</button>
    </div>
  `;

  // Exibir o modal
  const modal = document.createElement("div");
  modal.classList.add("modal");
  modal.innerHTML = modalContent;
  document.body.appendChild(modal);
};

// Função para fechar o modal
window.closeModal = function() {
  const modal = document.querySelector(".modal");
  if (modal) {
    modal.remove();
  }
};

// Adicionar estilos para o modal
// const style = document.createElement("style");
// style.innerHTML = `
//   .modal {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.5);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   .modal-content {
//     background: white;
//     padding: 20px;
//     border-radius: 5px;
//     max-width: 500px;
//     width: 100%;
//     max-height: 80%;
//     overflow-y: auto;
//   }
//   .modal-table-container {
//     max-height: 300px;
//     overflow-y: auto;
//   }
//   .modal-table {
//     width: 100%;
//     border-collapse: collapse;
//   }
//   .modal-table th, .modal-table td {
//     border: 1px solid #ddd;
//     padding: 8px;
//   }
//   .modal-table th {
//     background-color: #f2f2f2;
//   }
//   .clickable {
//     cursor: pointer;
//   }
// `;
// document.head.appendChild(style);

//Função para obter a cor de acordo com a data de expiração

function getExpirationColor(expirationDate) {
  const currentDate = new Date();
  const expDate = new Date(expirationDate);
  const diffTime = expDate - currentDate;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays > 5) {
    return "#00bf63"; // Verde
  } else if (diffDays >= 0 && diffDays <= 5) {
    return "#ffde59"; // Amarelo
  } else {
    return "#ff3131"; // Vermelho
  }
}

frappe.provide("frappe.kanban");
frappe.kanban.SyncFusionKanban = SyncFusionKanban;

export default SyncFusionKanban;

