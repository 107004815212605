import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e332304e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "normal_card",
  id: "operadores"
}
const _hoisted_2 = { class: "card-recurso-with-circle" }
const _hoisted_3 = {
  key: 0,
  class: "card-header-with-circle"
}
const _hoisted_4 = {
  key: 0,
  class: "circle-green-xl"
}
const _hoisted_5 = {
  key: 1,
  class: "circle-red-xl"
}
const _hoisted_6 = {
  key: 0,
  style: {"display":"flex","justify-content":"center","align-items":"center"}
}
const _hoisted_7 = ["height", "width", "src"]
const _hoisted_8 = {
  key: 0,
  class: "posicaoImg ml-3 mr-3"
}
const _hoisted_9 = ["height", "width", "src"]
const _hoisted_10 = { class: "d-flex align-center justify-center" }
const _hoisted_11 = {
  key: 0,
  class: "circle-green-xl mr-1 ml-2"
}
const _hoisted_12 = {
  key: 1,
  class: "circle-red-xl mr-1 ml-2"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "card-recurso",
    width: "100%",
    onClick: _cache[0] || (_cache[0] = $event => ($options.selectOperador($props.operador))),
    height: $data.display != 'xs' ? '243' : '130'
  }, {
    default: _withCtx(() => [
      _createElementVNode("a", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createCommentVNode(" Cabeçalho do card "),
          ($data.display != 'xs')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createCommentVNode(" Bolinha indicativa (Status) "),
                ($data.apontamento && $data.apontamento.tipo === 'Produção')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5)),
                _createVNode(_component_v_card_title, { class: "card-title-name-recurso" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($props.operador.nome), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Corpo do card "),
          _createElementVNode("div", {
            class: _normalizeClass($data.display != 'xs' ? 'recurso-card-body pa-0 mt-3' : '')
          }, [
            ($data.display != 'xs')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("img", {
                    height: $data.display === 'xs' ? '70' : '150',
                    width: $data.display === 'xs' ? '70' : '150',
                    class: "image",
                    src: $props.operador.foto || 'https://cdn-icons-png.flaticon.com/512/7425/7425445.png',
                    alt: "Logo NXLite Operador"
                  }, null, 8 /* PROPS */, _hoisted_7)
                ]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" Imagem do operador "),
            _createVNode(_component_v_row, { class: "mt-6" }, {
              default: _withCtx(() => [
                ($data.display === 'xs')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("img", {
                        style: {"align-items":"center"},
                        height: $data.display === 'xs' ? '70' : '150',
                        width: $data.display === 'xs' ? '70' : '150',
                        class: "image pt-1 ml-3",
                        src: $props.operador.foto || 'https://cdn-icons-png.flaticon.com/512/7425/7425445.png',
                        alt: "Logo NXLite Operador"
                      }, null, 8 /* PROPS */, _hoisted_9)
                    ]))
                  : _createCommentVNode("v-if", true),
                ($data.display === 'xs')
                  ? (_openBlock(), _createBlock(_component_v_card, {
                      key: 1,
                      style: {"background-color":"#f5f5f5","width":"60%"},
                      class: "rounded-lg mx-auto mt-3 text-center",
                      height: "10%"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, [
                          ($data.apontamento && $data.apontamento.tipo == 'Produção')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                            : (_openBlock(), _createElementBlock("div", _hoisted_12)),
                          _createVNode(_component_v_card_text, {
                            style: {"font-weight":"bold","max-width":"150px","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},
                            class: "text-truncate text-center text-bold"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($props.operador.nome), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ], 2 /* CLASS */)
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["height"]))
}