frappe.provide('frappe.nxlite.utils.nota_fiscal');

const Utils = frappe.nxlite.utils.utils;

const nota_fiscal = {
    create_nf_recebida: async (data) => {
        const new_nf_recebida = await frappe.db.insert({
            ...data,
            doctype: 'Nota Fiscal Recebida',
            status: undefined,
        });
        return new_nf_recebida;
    },
    xml_file_to_obj_formatted: async (file) => {
        const xml = await nota_fiscal.read_nf_xml(file);

        const { Id = null, versao = null } =
            xml['{http://www.portalfiscal.inf.br/nfe}nfeProc']['@children'][0][
                '{http://www.portalfiscal.inf.br/nfe}NFe'
            ]['@children'][0]['{http://www.portalfiscal.inf.br/nfe}infNFe'];
        const cstat =
            xml['{http://www.portalfiscal.inf.br/nfe}nfeProc']['@children'][1][
                '{http://www.portalfiscal.inf.br/nfe}protNFe'
            ]['@children'][0]['{http://www.portalfiscal.inf.br/nfe}infProt'][
                '@children'
            ][6]['{http://www.portalfiscal.inf.br/nfe}cStat']['@text'];
        const infNFE =
            xml['{http://www.portalfiscal.inf.br/nfe}nfeProc']['@children'][0][
                '{http://www.portalfiscal.inf.br/nfe}NFe'
            ]['@children'][0]['{http://www.portalfiscal.inf.br/nfe}infNFe'][
                '@children'
            ];

        if (versao != '4.00') return;

        const nfe_obj = infNFE.filter(
            (e) =>
                !Object.keys(e).includes(
                    '{http://www.portalfiscal.inf.br/nfe}det',
                ),
        );
        const det_obj = infNFE.filter((e) =>
            Object.keys(e).includes('{http://www.portalfiscal.inf.br/nfe}det'),
        );
        const obj = nota_fiscal.convert_arr_to_main_obj(nfe_obj);
        const main_obj = nota_fiscal.create_det_property(det_obj, obj);
        main_obj.id = Id;
        main_obj.versao = versao;
        main_obj.codigo_status_sefaz = cstat;
        return main_obj;
    },
    read_nf_xml: async (file) => {
        let file_name = file
            .split('/')
            [file.split('/').length - 1].replace(/ /g, '')
            .replace(/ *\([^)]*\) */g, '');

        // Verificar se o arquivo começa com '/private/files/' ou '/files/'
        if (file.startsWith('/private/files/')) {
            file_name = `/private/files/${file_name}`;
        } else if (file.startsWith('/files/')) {
            file_name = `/files/${file_name}`;
        }

        const xml = (
            await frappe.call(
                'nota_fiscal.nota_fiscal.xml_utils.xml_convert.main',
                {
                    args: {
                        file_name: file_name,
                        site_name: frappe.boot.sitename,
                    },
                },
            )
        ).message;
        return xml;
    },
    format_property: (property) => {
        return property
            .replace('{http://www.portalfiscal.inf.br/nfe}', '')
            .toLowerCase();
    },
    process_children: (children, acc = {}, prefix = 'det') => {
        if (children == undefined) return;
        children.map((child) => {
            const property = Object.keys(child)[0];
            const property_formatted = nota_fiscal.format_property(property);
            const key = `${prefix}_${property_formatted}`;

            if (child[property].hasOwnProperty('@children')) {
                nota_fiscal.process_children(
                    child[property]['@children'],
                    acc,
                    key,
                );
            } else {
                acc[key] = child[property]['@text'];
            }
        });
    },
    convert_arr_to_main_obj: (arr) => {
        return arr.reduce((acc, curr) => {
            const main_property = Object.keys(curr)[0];
            let main_property_formatted =
                nota_fiscal.format_property(main_property);
            if (['imposto', 'prod'].includes(main_property_formatted)) {
                main_property_formatted = `det_${main_property_formatted}`;
            }
            const children = curr[main_property]['@children'];
            nota_fiscal.process_children(
                children,
                acc,
                main_property_formatted,
            );
            return acc;
        }, {});
    },
    create_det_property: (det_arr, obj) => {
        obj.det = det_arr.map((det) =>
            nota_fiscal.convert_arr_to_main_obj(
                det[Object.keys(det)[0]]['@children'],
            ),
        );
        return obj;
    },
    ICMS: {
        vICMSTot: (frm) => {
            const itens = frm.doc.itens;
            const total = itens.reduce((acc, cur) => acc + cur.valor_icms, 0);
            return total;
        },
        vICMSDeson(
            vBCICMS = 0,
            pICMS = 0,
            pRedBC = 0,
            cICMS = 0,
            vICMS = 0,
            vProd = 0,
            pBCOp = 0,
            vICMSOutros = 0,
            vICMSNTrib = 0,
        ) {
            switch (cICMS) {
                case '20':
                case '70':
                    return (
                        ((vBCICMS / (1 - pRedBC / 100)) *
                            (1 - (pICMS / 100) * (1 - pRedBC / 100))) /
                            (1 - pICMS / 100) -
                            vICMS || 0
                    );
                // Base com ICMS por dentro ((Base de ICMS/(1 - Percentual de Redução/100)) * (1-( Percentual de ICMS do movimento /100*(1- Percentual de Redução/100)))/(1- Percentual de ICMS do movimento /100) - base ICMS sem redução
                // ((vBCICMS / (1 - pRedBC / 100)) * pICMS / 100) - vICMS || 0; //base simples ((Base de ICMS/(1 - Percentual de Redução/100))*Percentual de ICMS do movimento/100) – Valor de ICMS
                case '40':
                case '41':
                    return (
                        ((vProd / (1 - pRedBC / 100) / (1 - pBCOp / 100)) *
                            pBCOp) /
                            100 || 0
                    );
                // Base com ICMS por dentro - ((Total do item/(1 - Percentual de Redução/100))/(1- Percentual de ICMS do Tipo de Operação /100) *Percentual de ICMS do Tipo de Operação/100
                //(vProd / (1 - pRedBC / 100)) * pBCOp / 100 || 0;  // Base Simples  ((Total do item/(1 - Percentual de Redução/100))*Percentual de ICMS do Tipo de Operação/100)
                case '30':
                    return (vICMSNTrib / (1 - pBCOp / 100)) * pBCOp || 0;
                // Base com ICMS por dentro - Valor ICMS não tributado/(1- Percentual de ICMS do Tipo de Operação /100) * Percentual ICMS Tipo de Operação
                //vICMSNTrib * pBCOp || 0;  // Base Simples -  Valor ICMS não tributado * Percentual ICMS Tipo de Operação
                case '50':
                    return (vICMSOutros / (1 - pBCOp / 100)) * pBCOp || 0;
                // Base com ICMS por dentro - Valor ICMS Outros/(1- Percentual de ICMS do Tipo de Operação /100) * Percentual ICMS Tipo de Operação
                //vICMSOutros * pBCOp || 0; // Base Simples - Valor ICMS Outros * Percentual ICMS Tipo de Operação
                default:
                    return 0;
            }
        }, // corrigir essas contas
        vBCST(vProd, pMVAST, vIPI, vFrete, vSeguro, vOutrasDesp, vDesc, cICMS) {
            switch (cICMS) {
                case '10':
                case '30':
                case '70':
                case '90':
                case 'ICMS Partilhado':
                    return (
                        (vProd +
                            vIPI +
                            vFrete +
                            vSeguro +
                            vOutrasDesp -
                            vDesc) *
                            (1 + pMVAST / 100) || 0
                    );
                default:
                    return 0;
            }
        },
        vICMS: (vBCICMS, pICMS) => vBCICMS * (pICMS / 100),
        vBCICMS: (vProd, vFRETE, vOutrasDesp, vDesc) =>
            vProd + vFRETE + vOutrasDesp - vDesc,
        vICMSST: (vBCST = 0, pICMSST = 0) => vBCST * (pICMSST / 100),
        vICMSOp: (vBCICMS = 0, pICMS = 0) => vBCICMS * (pICMS / 100),
        vICMSNTrib: (vBCICMS = 0, pICMS = 0) =>
            (vBCICMS * (1 - pICMS / 100)) / (1 + pICMS / 100),
        vICMSDif(vProd, pICMS, pICMSDif) {
            const vICMS = vProd * (pICMS / 100);
            const vICMSDif = vICMS - vProd * (pICMSDif / 100);
            return vICMSDif;
        },
        VFCPDif(vProd, pFCPST, pFCPDif) {
            const vFCPST = vProd * (pFCPST / 100);
            const vFCPDif = vFCPST - vProd * (pFCPDif / 100);
            return vFCPDif;
        },
        vFCP: (cICMS, vBC = 0, vBCFCP = 0, pFCP = 0) =>
            cICMS == '00'
                ? vBC * (pFCP / 100) || 0
                : vBCFCP * (pFCP / 100) || 0, // calculo FCP 10, 20,70, 90 ou 51 deve ser realizado o seguinte cálculo
        vBCFCP: (vBCICMS = 0, pFCP = 0) => vBCICMS * (pFCP / 100),
        vFCPEfet: (vBCEfet, pFCP) => vBCEfet * (pFCP / 100),
        vBCFCPUFDest: (vTotalVen, pFCP) => vTotalVen * (pFCP / 100),
        vBCFCPST: (vBCST = 0, pFCP = 0) => vBCST * (pFCP / 100),
        vFCPUFDest: (vBCUFDest = 0, pFCPUFDest = 0) =>
            vBCUFDest * (pFCPUFDest / 100),
        vBCUFDest: (vVENDATotal = 0, pICMSUFDest = 0) =>
            vVENDATotal * (pICMSUFDest / 100),
        vFCPST: (vBCST = 0, pFCPST = 0) => vBCST * (pFCPST / 100),
        vBCICMSUFDest: (vBCICMS = 0, pICMS = 0, vICMS = 0) =>
            (vBCICMS - vICMS) / (1 - pICMS / 100),
        vFCPSTRet: (vICMSST = 0, vFCP = 0) => vICMSST * (vFCP / 100),
        vICMSUFRemet(
            vBCUFDest = 0,
            pICMSUFDest = 0,
            vBCICMS = 0,
            pICMSInter = 0,
            vICMSUFDest = 0,
            cICMS = 0,
        ) {
            switch (cICMS) {
                case '00':
                case '90':
                    return (
                        vBCUFDest * pICMSUFDest -
                        vBCICMS * pICMSInter -
                        vICMSUFDest
                    );
                default:
                    return 0;
            }
        },
        vICMSUFDest(
            vBCUFDest = 0,
            pICMSUFDest = 0,
            vBCICMS = 0,
            pICMSInter = 0,
            pICMSInterPart = 0,
            cICMS = 0,
        ) {
            switch (cICMS) {
                case '00':
                case '90':
                    return (
                        (vBCUFDest * pICMSUFDest - vBCICMS * pICMSInter) *
                        pICMSInterPart
                    );
                default:
                    return 0;
            }
        },
        vBCFCPSTRet: (vBCST = 0, pFCPRet = 0) => vBCST * (pFCPRet / 100),
        vBCSTRet: (vTotalVen, vDesc, impostos) => vTotalVen - vDesc - impostos,
        vICMSSTRet: (vBCSTRet, pICMSST) => vBCSTRet * (pICMSST / 100),
        vBCEfet: (vProd, pRedBCEfet) => vProd * (1 - pRedBCEfet / 100),
        vICMSEfet: (pICMSEfet, vBCEfet) => pICMSEfet * vBCEfet,
    },
    PIS: {
        vAliqProd: (qBCProd = 0, pPis = 0) => qBCProd * (pPis / 100),
        vPISOutr: (vBCPISOutr = 0, pPis = 0) => vBCPISOutr * (pPis / 100),
        vPIS: (vBCPIS = 0, pPIS = 0) => vBCPIS * (pPIS / 100),
        vBCPIS: (vVENDATotal = 0, pPis = 0) => vVENDATotal * (pPis / 100),
        vBCPISDif: (vBCPIS = 0, pPISDif = 0) => vBCPIS * (pPISDif / 100),
    },
    COFINS: {
        vCOFINS: (vBCCOFINS = 0, pCOFINS = 0) => vBCCOFINS * (pCOFINS / 100),
        vCOFINSST: (vCOFINSST = 0, pCOFINSST = 0) =>
            vCOFINSST * (pCOFINSST / 100),
        vBCCOFINS: (qProd = 0, pBCST = 0) => qProd * (pBCST / 100),
        vBCCOFINSDif: (vBCCOFINS = 0, pCOFINSDif = 0) =>
            vBCCOFINS * (pCOFINSDif / 100),
        vCOFINSSTRet: (vBCSTRet = 0, pCOFINSST = 0) =>
            vBCSTRet * (pCOFINSST / 100),
    },
    IPI: {
        vIPI: (vBC = 0, vIPIAliq = 0) => vBC * (vIPIAliq / 100),
        vBCIPI: (vVENDATotal = 0, vIPIAliq = 0) =>
            vVENDATotal * (vIPIAliq / 100),
        vIPIDevol: (vBC = 0, vIPIAliq = 0) => vBC * (vIPIAliq / 100),
        vIPITrib: (vBCIPIrib = 0, pIPITrib = 0) => vBCIPIrib * (pIPITrib / 100),
        pDevol: (vVENDATotal = 0, IMPPag = 0, pIPI = 0) => {
            const vBCIPI = vVENDATotal * (pIPI / 100);
            const impDevol = IMPPag - vBCIPI;
            const pDevol = (impDevol / IMPPag) * 100;
            return pDevol;
        },
    },
    PRODUTO: {
        qBCProd: (qProd = 0, vProd = 0) => qProd * vProd,
        vProd: (pUnit = 0, qtde = 0) => pUnit * qtde,
    },
    NFS: {
        VLiq: (
            vServ,
            vDescIncond,
            vDescCond,
            vRetCP,
            vRetIRRF,
            vRetCSLL,
            vISSQN,
            vPIS,
            vCOFINS,
        ) =>
            vServ -
            vDescIncond -
            vDescCond -
            (vRetCP + vRetIRRF + vRetCSLL) -
            (vISSQN - vPIS + vCOFINS), //*Para o resultado do Valor Líquido o CP, IRRF e CSLL serão sempre subtraídos, se constarem na DPS, pois sempre são retidos. **Para o resultado do Valor Líquido o ISSQN, PIS e COFINS somente serão subtraídos quando forem retidos.
        vBC: (vServ, descIncond, vDR, vRedBCBM) =>
            vServ - descIncond - vDR - vRedBCBM,
        // Calculando o total de tributos federais
        // vTotalRet: (vRetCP, vRetIRRF, vRetCSLL, vISSQN, vPIS, vCOFINS) => vRetCP + vRetIRRF + vRetCSLL + vISSQN +  vPIS + vCOFINS, //ISSQN pode não sofrer retenção. Para o resultado do valor total de retenções o ISSQN somente será somado quando for retido. **Pis/Cofins podem não sofrer retenção. Para o resultado do valor total de retenções Pis/Cofins somente serão somados quando forem retidos.
        vRetIRRF: (vServ, pIRRF) => vServ * (pIRRF / 100),
        vRetPIS: (vServ, pPIS) => vServ * (pPIS / 100),
        vRetCOFINS: (vServ, pCOFINS) => vServ * (pCOFINS / 100),
        vRetCSLL: (vServ, pCSLL) => vServ * (pCSLL / 100),
        vRetCP: (vServ, pCPP) => vServ * (pCPP / 100),
        vRetISS: (vServ, pISSQN) => vServ * (pISSQN / 100),
        vRetICMS: (vServ, pICMS) => vServ * (pICMS / 100),
        vTotTribFed: (vRetIRRF, vRetPIS, vRetCOFINS, vRetCSLL, vRetCP) =>
            vRetIRRF + vRetPIS + vRetCOFINS + vRetCSLL + vRetCP,
        pTotTribFed: (VBC, vTotTribFed) => VBC / vTotTribFed,
        pTotTribEst: (VBC, vTotTribEst) => VBC / vTotTribEst,
        pTotTribMun: (VBC, vTotTribMun) => VBC / vTotTribMun,
    },
};

frappe.nxlite.utils.nota_fiscal = nota_fiscal;
