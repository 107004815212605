<template>

 
<!-- Modal -->  
<v-dialog
  v-model="appStore.Modal_motivo_de_parada_body"
  :width="computed_display" 
  :height="display == 'xs' ? '30%' : display == 'sm' ? '50%' :display == 'md' ? '30%' : '20%'"
  :key="appStore.Modal_motivo_de_parada_body"
  overlay-color="rgba(0, 0, 0, 0.8)" 
  overlay-opacity="1"  
  style="box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); background-color: black;"
>
  <v-card class="pa-4">
    <!-- Ícone de Fechar -->
    <div class="d-flex justify-end">
      <v-icon @click="appStore.Modal_motivo_de_parada_body = false">mdi-close</v-icon>
    </div>

    <!-- Título do Modal -->
    <div class="text-center">
      <span 
      style="font-weight: 600" 
      color="#333" 
      class="text-h5 text-md-h4">O que gostaria de fazer com o motivo de parada?</span>
      <hr>
    </div>

    <!-- Botões do Modal -->
    <div class="d-flex justify-center mt-5" style="flex-wrap: nowrap; gap: 10px; justify-content: center;">
      <v-btn
        @click="handleButtonClick('iniciar')"
        large
        class="mr-4"
        color="green"
        :style="display === 'xs' || display === 'sm' ? 'width: 45% font-size: 12px;'  : ''"
      >
        <span v-if="display === 'xs'">Iniciar<br>apontamento</span>
        <span v-else>Iniciar apontamento</span>
      </v-btn>

      <v-btn
        @click="handleButtonClick('finalizar')"
        large
        color="red"
        :style="display === 'xs' || display === 'sm' ? 'width: 45%' : ''"
      >
        Finalizar MP
      </v-btn>
    </div>
  </v-card>
</v-dialog>

</template>

<style scoped>
</style>

<script>
import { useAppStore } from '../../../stores/app';
import {useDisplay} from 'vuetify'


export default {
    // components: {
    //     ModalFinalizarOP,
    //     ModalFecharApontamentoAberto,
    //     ModalMotivoDeParada
    // },
    name: "ModalMotivoDeParadaBody",
    data() {
        return {
            display: useDisplay().name
        };
    },
    computed: {
        appStore() {
            return useAppStore();
        },

        computed_display() {
            switch (this.display) {
                case 'sm':
                    return '80%'
                case 'xs':
                    return '90%'
                default:
                    return '60%'
            }
        },
        computed_display_height() {
            switch (this.display) {
                case 'sm':
                    return '50%'
                case 'xs':
                    return '90%'
                default:
                    return '30%'
            }
        }
    },
    methods: {
        openModalParadaBody() {
            this.appStore.openModalOPBody();
        },
        closeModalApontamentoAbertoBody() {
            this.appStore.selectItem('apontamento', null)
            this.appStore.closeModalApontamentoAbertoBody();
        },

        handleButtonClick(action) {

            if (action === 'iniciar') {
                this.break_selected_apt();
            } else if (action === 'finalizar') {
                this.fecharBtn();
            }

            this.appStore.Modal_motivo_de_parada_body = false;
        },

        async fecharBtn() {
            await this.appStore.close_apontamento(this.appStore.selected_apontamento.name, 0, 0)
            this.closeModalApontamentoAbertoBody()
        },
        
        async break_selected_apt() {
            await this.appStore.break_selected_apt()
            this.closeModalApontamentoAbertoBody()
        }
    },
};
</script>
