class CargaMaquina {
    constructor({ wrapper }) {
        if (!wrapper) {
            throw new Error("Wrapper is required to initialize CargaMaquina.");
        }
        this.wrapper = wrapper;
        this.elementId = 'progress-bar-container';
        this.selectedPeriod = parseInt(localStorage.getItem('selectedPeriod')) || 30;
        this.updateInterval = null;
        this.selectedButton = null; 
    }

    async initSyncfusionCharts() {
        const syncfusionScript = document.createElement('script');
        syncfusionScript.src = 'https://cdn.syncfusion.com/ej2/20.2.36/dist/ej2.min.js';
        document.head.appendChild(syncfusionScript);

        const syncfusionStyles = document.createElement('link');
        syncfusionStyles.rel = 'stylesheet';
        syncfusionStyles.href = 'https://cdn.syncfusion.com/ej2/20.2.36/material.css';
        document.head.appendChild(syncfusionStyles);

        syncfusionScript.onload = async () => {
            ej.base.registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXd0dgWX9WdXJXQGFUWEM=');

            const machineLoadPanelId = 'panel-' + Math.random().toString(36).substr(2, 9);

            this.wrapper.innerHTML = `
            <div class="chart-header">CARGA MÁQUINA</div>
                <div class="button-container" style="display: flex; justify-content: center; margin-bottom: 0px;">
                    <div class="dias-text" style="font-weight: bold; margin-right: 10px; background-color: white;"></div>
                </div>
                <div class="chart-container" style="display: flex; align-items: flex-start; width: 100%; height: 100%;">
                    <!-- Gráfico -->
                    <div id="${machineLoadPanelId}-content" class="chart-box" style="flex: 1; height: calc(100% - 40px);"></div>
                </div>
            `;

            const buttonContainer = this.wrapper.querySelector('.button-container');

            const periods = [7, 15, 30, 60, 90, 180];  
            periods.forEach(period => {
                const button = document.createElement('div');
                button.innerText = `${period}`;
                button.style.display = 'flex';
                button.style.alignItems = 'center';
                button.style.justifyContent = 'center';
                button.style.width = '80px';
                button.style.height = '30px';
                button.style.marginBottom = '10px';
                button.style.borderRadius = '0px 0px 0px 0px'; 
                button.style.boxShadow = '0px 2px 5px rgba(0, 0, 0, 0.1)';
                button.style.backgroundColor = '#f5f2f2';
                button.style.fontSize = '18px';
                button.style.fontWeight = 'bold';
                button.style.color = '#999999';
                button.style.cursor = 'pointer';
                button.style.transition = 'all 0.4s ease';

                button.onclick = () => {
                    this.selectedPeriod = period;
                    localStorage.setItem('selectedPeriod', period);

                    Array.from(buttonContainer.children).forEach(child => {
                        if (child.tagName === 'DIV' && !child.classList.contains('dias-text')) {
                            child.style.color = '#999999';
                            child.style.backgroundColor = '#f5f2f2';
                            child.style.boxShadow = '0px 2px 5px rgba(0, 0, 0, 0.1)';
                        }
                    });

                    button.style.color = '#397bbe';
                    button.style.backgroundColor = '#f4f8fc';
                    button.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)';
                    this.selectedButton = button;

                    this.renderMachineLoadChart(period, true);
                };

                if (period === this.selectedPeriod) {
                    button.style.color = '#397bbe';
                    button.style.backgroundColor = '#f4f8fc';
                    button.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)';
                    this.selectedButton = button;
                }

                buttonContainer.appendChild(button);
            });

            await this.renderMachineLoadChart(this.selectedPeriod);
        };
    }

    async renderMachineLoadChart(days, includeDelayed = true) {
        const machineLoadPanelId = this.wrapper.querySelector('.chart-box').id;
        const availableHoursByCentroCusto = await this.calculateAvailableHours();
        const machineLoadChartData = await this.fetchMachineLoadData(days, availableHoursByCentroCusto, includeDelayed);
        // console.log('Horas por centro_custo:', availableHoursByCentroCusto);
        // console.log('CARGA MÁQUINA:', machineLoadChartData);

        const chartData = machineLoadChartData.map(data => ({
            x: data.centro_custo,
            y: data.horas_vendidas,
            Rate: data.horas_disponiveis,
            text: `${data.horas_vendidas}`
        }));

        const machineLoadChart = new ej.charts.Chart({
            primaryXAxis: {
                valueType: 'Category',
                interval: 1,
                majorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
                minorTickLines: { width: 0 },
                labelPosition: 'Outside',
                labelRotation: ej.base.Browser.isDevice ? -45 : 0,
                labelIntersectAction: ej.base.Browser.isDevice ? 'None' : 'Rotate45',
            },
            chartArea: { border: { width: 0 } },
            enableSideBySidePlacement: false,
            primaryYAxis: {
                minimum: 0,
                // maximum: 720,
                labelFormat: '{value}',
                majorTickLines: { width: 0 },
                lineStyle: { width: 0 }
            },
            tooltip: { enable: true },
            legendSettings: {
                visible: true,
                position: 'Bottom',
                alignment: 'Center',
                height: '10%'
            },
            series: [
                {
                    type: 'Column',
                    xName: 'x',
                    width: 2,
                    yName: 'Rate',
                    dataSource: chartData,
                    name: 'Disponível',
                    enableTooltip: true,
                    columnWidth: 0.8,
                    opacity: 0.5,
                    cornerRadius: { bottomLeft: 12, bottomRight: 12, topLeft: 12, topRight: 12 },
                    fill: '#bfbfbf',
                },
                {
                    type: 'Column',
                    xName: 'x',
                    width: 2,
                    yName: 'y',
                    dataSource: chartData,
                    name: 'Vendidas',
                    columnWidth: 0.8,
                    cornerRadius: { bottomLeft: 12, bottomRight: 12, topLeft: 12, topRight: 12 },
                    fill: '#397bbe',
                    marker: {
                        dataLabel: {
                            visible: true,
                            name: 'text',
                            position: 'Top',
                            font: { fontWeight: '800', color: '#f0f0f0', size: '13px' }
                        }
                    }
                }
            ],
            // highlightColor: 'transparent',
            load: (args) => {
                let selectedTheme = location.hash.split('/')[1];
                selectedTheme = selectedTheme ? selectedTheme : 'Fluent2';
                args.chart.theme = selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast').replace(/-highContrast/i, 'HighContrast');
            },
            width: '100%',
        });

        machineLoadChart.appendTo(`#${machineLoadPanelId}`);

        this.startRealTimeUpdates(machineLoadChart, days);
    }

    startRealTimeUpdates(chart, days) {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
        // this.updateInterval = setInterval(async () => {
        //     const availableHoursByCentroCusto = await this.calculateAvailableHours();
        //     const machineLoadChartData = await this.fetchMachineLoadData(days, availableHoursByCentroCusto, true);

        //     chart.series[0].dataSource = machineLoadChartData;
        //     chart.series[1].dataSource = machineLoadChartData;
        //     chart.refresh();
        // }, 500); 
    }

    onPanelResize(args) {
        if (args.element && args.element.querySelector('.chart-box')) {
            const chart = args.element.querySelector('.chart-box').ej2_instances[0];
            if (chart) {
                chart.width = '100%';
                chart.height = '100%';
                chart.refresh();
            }
        }
    }

    async calculateAvailableHours() {
        const resources = await frappe.call({
            method: "nxlite.nx_producao.page.minha_producao.controller.carga_maquina",
            args: { request_type: "fetchFrappe" }
        }).then(response => response.message);

        // console.log('disp_rec:', resources);

        const recursos = await frappe.call({
            method: "nxlite.nx_producao.page.minha_producao.controller.carga_maquina",
            args: { request_type: "fetchRecurso" }
        }).then(response => response.message);

        // console.log('Recursos:', recursos);

        let availableHoursByCentroCusto = {};
        let parentDurations = {};

        resources.forEach(resource => {
            if (resource.tipo === "Disponível" && typeof resource.duracao === 'string') {
                const horas_disponiveis = resource.duracao.split(':').reverse().reduce((acc, time, i) => acc + parseInt(time) * Math.pow(60, i), 0) / 3600;
                if (!parentDurations[resource.parent]) {
                    parentDurations[resource.parent] = 0;
                }
                parentDurations[resource.parent] += horas_disponiveis;
            }
        });

        for (const recurso of recursos) {
            const parent = recurso.disponibilidade;
            const centro_de_custo = recurso.centro_de_custo;
            if (centro_de_custo) {
                if (!availableHoursByCentroCusto[centro_de_custo]) {
                    availableHoursByCentroCusto[centro_de_custo] = 0;
                }
                if (parent && parentDurations[parent]) {
                    availableHoursByCentroCusto[centro_de_custo] += parentDurations[parent] * this.selectedPeriod;
                } else {
                    availableHoursByCentroCusto[centro_de_custo] += 24 * this.selectedPeriod;
                }
            }
        }

        return availableHoursByCentroCusto;
    }

    async fetchMachineLoadData(days, available_hours, includeDelayed) {
        const machineLoadData = await frappe.call({
            method: "nxlite.nx_producao.page.minha_producao.controller.carga_maquina",
            args: { request_type: "get_machine_load_data", days: days, available_hours: JSON.stringify(available_hours), include_delayed: includeDelayed }
        });

        // console.log('Machine Load Data:', machineLoadData.message);

        return machineLoadData.message;
    }
}

frappe.provide("frappe.producao");
frappe.producao.CargaMaquina = CargaMaquina;

export default CargaMaquina;
